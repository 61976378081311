import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_ENDPOINT } from '../../../config/config';
import axiosInstance from '../../../service/axiosInstance';

export const myPlayersPost = createAsyncThunk('myPlayers/post', async (data, { rejectWithValue }) => {
	try {
		const res = await axiosInstance.post(`${API_ENDPOINT}players/`, data);
		return res.data;
	} catch (error) {
		if (error.response && error.response.data.message) {
			return rejectWithValue(error.response.data.message);
		} else {
			return rejectWithValue(error.message);
		}
	}
});

export const myPlayersGet = createAsyncThunk('myPlayers/get', async (_, { rejectWithValue }) => {
	try {
		const res = await axiosInstance.get(`${API_ENDPOINT}players/my/`);
		return res.data;
	} catch (error) {
		if (error.response && error.response.data.message) {
			return rejectWithValue(error.response.data.message);
		} else {
			return rejectWithValue(error.message);
		}
	}
});

export const myPlayersUpdate = createAsyncThunk('myPlayers/update', async (data, { rejectWithValue }) => {
	try {
		const { id } = data;
		const res = await axiosInstance.patch(`${API_ENDPOINT}players/${id}/`, data);
		return res.data;
	} catch (error) {
		if (error.response && error.response.data.message) {
			return rejectWithValue(error.response.data.message);
		} else {
			return rejectWithValue(error.message);
		}
	}
});

export const myPlayersDelete = createAsyncThunk('myPlayers/delete', async (data, { rejectWithValue }) => {
	try {
		const { id } = data;
		const res = await axiosInstance.delete(`${API_ENDPOINT}players/${id}/`);
		return res.data;
	} catch (error) {
		if (error.response && error.response.data.message) {
			return rejectWithValue(error.response.data.message);
		} else {
			return rejectWithValue(error.message);
		}
	}
});
