import styled from 'styled-components';

export const PlayerCard = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	padding: 0px;
	padding-left: 20px;
	position: relative;
	flex-wrap: nowrap;
	gap: 25px;
	border-radius: 4px 4px 0px 0px;
	border-top: 1px solid #989ea4;
	border-right: 1px solid #989ea4;
	border-left: 1px solid #989ea4;
	/* background: #fff; */
	&:last-child {
		border-bottom: 1px solid #989ea4;
	}
`;

export const PlayerInfo = styled.div`
	flex: 1;
	/* min-width: 100px; */
	padding: 8px;
	/* &.college {
		max-width: 300px;
		min-width: fit-content;
		max-width: fit-content;
	}
	&.position {
		max-width: 65px;
		min-width: 65px;
		text-align: center;
	}
	&.playerName {
		min-width: fit-content;
		max-width: fit-content;
	}
	&.playerIndex {
		max-width: 40px;
		min-width: 40px;
		width: 100%;
	} */
	&.playerName {
		min-width: 230px;
		/* max-width: fit-content; */
	}
	&.college {
		min-width: 170px;
		word-wrap: break-word;
	}
`;

export const PlayerActions = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 105px;
	max-width: 140px;
	width: 100%;
`;

export const PlayerName = styled.span`
	font-family: 'Saira Semi Condensed', sans-serif;
	color: #00438b;
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 32px;
`;

export const PlayerPosition = styled.span`
	color: #000;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
`;

export const PlayerRank = styled.span`
	color: #000;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 30px;
`;

export const PLayerIndex = styled.div`
	color: #989ea4;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 32px;
`;

export const PlayerCollege = styled.span`
	color: #000;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 30px;
`;

export const ActionButton = styled.button`
	cursor: pointer;
	background: none;
	height: 100%;
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 8px;
	padding: 8px;
	font-weight: 500;
	border: none;
    background: ${({ isAddedGrade }) => (isAddedGrade ? '#D9F1CC' : 'none')};
	&:hover {
		background: rgba(0, 0, 0, 0.1);
	}

	&.delete {
		cursor: pointer;
		color: #e74c3c;
		background: rgba(231, 76, 60, 0.2);
	}

	&.edit {
		color: #022142;
		background: rgba(2, 33, 66, 0.2);
		border-radius: 0 6px 6px 0;
	}
	&.save {
		color: green;
		background: rgba(2, 66, 12, 0.2);
		border-radius: 0 6px 6px 0;
	}
`;

export const ActionText = styled.span`
	font-family: 'Saira Semi Condensed', sans-serif;
	font-size: 16px;
	color: inherit;
`;

export const Label = styled.div`
	font-family: 'Saira Semi Condensed', sans-serif;
	font-size: 16px;
	color: #46484a;
	margin-bottom: 4px;
`;

export const SelectCount = styled.div`
	position: absolute;
	top: -16px;
	right: -15px;
	width: 30px;
	height: 30px;
	padding: 5px;
	background-color: rgb(0, 67, 139);
	color: rgb(255, 255, 255);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
`;
