import React, {memo, useCallback, useEffect, useState} from 'react';
import { MdDeleteOutline, MdOutlineDragHandle, MdOutlineEdit } from 'react-icons/md';
import {useDispatch, useSelector} from 'react-redux';
import { getRanking, updateRankingPosition } from '../../../app/features/ranking/rankingActions';
import {
	addGrades,
	selectRanking,
	// setMaxGrade,
	setRemoveRankingPlayerLocal
} from '../../../app/features/ranking/rankingSlice';
import { NumItem } from '../../../components/DraftPlayerChoose/DraftPlayerChoose.styles';
import { POSITIONS_COLOR, RANKIGS } from '../../../utils/constants';
import Dropdown from './Dropdown/Dropdown';
import {
	ActionButton,
	PlayerActions,
	PlayerCard,
	PlayerCollege,
	PLayerIndex,
	PlayerInfo,
	PlayerName,
	PlayerPosition,
	PlayerRank,
	SelectCount,
} from './PlayerItemRanking.styles';
console.log('🚀 ~ SelectCount:', SelectCount);

const RankeEdit = memo(({ selectedOption, isOpen }) => {
	return <MdOutlineEdit size={24} color="#767676" className="editIcon" />;
});

const PlayerItemRanking = memo(
	({ player, index, dragHandleProps, mode, rankingDataDB,
		maxGrade,
		 // maxGrade = null,
		 selectCount = [], isDragging = false }) => {
		const dispatch = useDispatch();
		const dragHandle = mode ? null : dragHandleProps;
		const [newMaxGrade, setNewMaxGrade]=useState(null);

		const addRankingGrades = useCallback(
			item => {
				dispatch(addGrades({ playerId: player.id, grade: item }));
			},
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[player.id]);


		const setGrade=(item)=>{
			setNewMaxGrade(item)
		}

		const handleRemoveOnePlayer = useCallback(
			async (e, playerId, selectedPosition) => {
				e.stopPropagation();
				const checkBackendData = rankingDataDB.find(group => group.name === selectedPosition);
				if (!checkBackendData) {
					await dispatch(setRemoveRankingPlayerLocal({ playerId, selectedPosition }));
				} else {
					await dispatch(updateRankingPosition({ playerId: player.id }));
					await dispatch(getRanking());
				}
			},
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[player.id, rankingDataDB]
		);

		return (
			<PlayerCard>
				<PlayerInfo className="playerIndex">
					<PLayerIndex>{index}</PLayerIndex>
				</PlayerInfo>
				<PlayerInfo className="playerName">
					<PlayerName>{player.player}</PlayerName>
				</PlayerInfo>
				<PlayerInfo className="college">
					<PlayerCollege>{player.school}</PlayerCollege>
				</PlayerInfo>
				<PlayerInfo className="position">
					<PlayerPosition>
						<NumItem backColor={POSITIONS_COLOR[player.position]}>{player.position}</NumItem>
					</PlayerPosition>
				</PlayerInfo>
				<PlayerInfo className="rank">
					<PlayerRank>{player.ranking}</PlayerRank>
				</PlayerInfo>
				<PlayerActions>
					<ActionButton
						onClick={e => handleRemoveOnePlayer(e, player.id, player.position)}
						className="delete">
						<MdDeleteOutline size={24} color="#E74C3C" />
					</ActionButton>
					<ActionButton
						className={`${mode ? '' : 'handle'}`}
						isAddedGrade={!!player?.grade?.label && mode}
						{...dragHandle}
						null
						onClick={e => {
							e.stopPropagation();
						}}
					>
						{mode ? (
							<Dropdown
								button={<RankeEdit />}
								data={RANKIGS}
								title="Apply round grades to rankings"
								defaultSelection={RANKIGS[0].label}
								rankings={true}
								gradeStatus={player}
								// value={player?.grade?.value}
								onSelect={value => addRankingGrades(value)}
								onSetNewMaxGrade={setGrade}
								playerIndex={index}
								// maxGrade={maxGrade}
								maxGrade={newMaxGrade}
								playerGradeValue={player?.grade?.value}
							/>
						) : (
							<MdOutlineDragHandle size={24} />
						)}
					</ActionButton>
				</PlayerActions>
				{!mode && selectCount > 1 && isDragging && index === 1 && (
					<SelectCount>
						<span>{selectCount}</span>
					</SelectCount>
				)}
			</PlayerCard>
		);
	}
);

export default PlayerItemRanking;
