//before
import axios from "axios";
import { API_ENDPOINT } from "../config/config";
import TokenService from "./token.service";

const axiosInstance = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    let authTokens = TokenService.getUser() || "";
    if (authTokens) {
      config.headers["Authorization"] = `Bearer ${authTokens?.tokens?.access}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (
      originalConfig.url !== `${API_ENDPOINT}Account/Authorize` &&
      err.response
    ) {
      // Access Token was expired
      if
        (err.response.status === 403 &&
        !originalConfig._retry
      ) {

        originalConfig._retry = true;
        let authTokens = TokenService.getUser() || "";



          const response = await axiosInstance.post(
            `${API_ENDPOINT}token/refresh/`,
            {
              refresh: authTokens.tokens.refresh,
            }
          );

          TokenService.updateLocalAccessToken(response.data?.access);

          return axiosInstance(originalConfig);


        }
        if (err.response.status === 401) {
    //check
           TokenService.removeUser();
           window.location("/");
           window.location.reload();
           return Promise.reject(err.response.data);

        }
      }

    // TokenService.removeUser();
    // window.location.reload();
    // window.location("/");
    return Promise.reject(err);
  }
);

export default axiosInstance;


//new
// import axios from "axios";
// import { API_ENDPOINT } from "../config/config";
// import TokenService from "./token.service";
//
// // Create axios instance with default settings
// const axiosInstance = axios.create({
//     baseURL: API_ENDPOINT,
//     headers: {
//         "Content-Type": "application/json",
//     },
// });
//
// // Request interceptor for adding authorization token
// axiosInstance.interceptors.request.use(
//     (config) => {
//         const authTokens = TokenService.getUser() || "";
//         if (authTokens && authTokens.tokens?.access) {
//             config.headers["Authorization"] = `Bearer ${authTokens.tokens.access}`;
//         }
//         return config;
//     },
//     (error) => Promise.reject(error)
// );
//
// // Response interceptor for handling expired tokens and refreshing them
// axiosInstance.interceptors.response.use(
//     (res) => res,
//     async (err) => {
//         const originalConfig = err.config;
//
//         if (originalConfig.url !== `${API_ENDPOINT}Account/Authorize` && err.response) {
//             // Access token expired (403)
//             if (err.response.status === 403 && !originalConfig._retry) {
//                 originalConfig._retry = true;
//                 const authTokens = TokenService.getUser() || "";
//
//                 try {
//                     // Attempt to refresh the access token using the refresh token
//                     const response = await axiosInstance.post(
//                         `${API_ENDPOINT}token/refresh/`,
//                         { refresh: authTokens.tokens?.refresh }
//                     );
//
//                     // Update the access token in local storage
//                     TokenService.updateLocalAccessToken(response.data?.access);
//                     return axiosInstance(originalConfig);
//                 } catch (refreshError) {
//                     // If refresh token fails (e.g., expired), log out user and redirect to login page
//                     TokenService.removeUser();
//                     window.location.href = "/sign-in"; // Navigate to sign-in page
//                     return Promise.reject(refreshError.response?.data || refreshError);
//                 }
//             }
//
//             // Unauthorized (401) - Log out user and redirect to sign-in page
//             if (err.response.status === 401) {
//                 TokenService.removeUser();
//                 window.location.href = "/sign-in"; // Navigate to sign-in page
//                 return Promise.reject(err.response.data);
//             }
//         }
//
//         return Promise.reject(err.response?.data || err);
//     }
// );
//
// export default axiosInstance;


