import { createSlice } from '@reduxjs/toolkit';
import { getPodcasters } from './podcastersActions';


const initialState = {
	loading: false,
	error: null,
	message: '',
	podcastersInfo: null,
};




export const podcastersSlice = createSlice({
	name: 'podcasters',
	initialState,
	reducers: {},
	extraReducers: {
		[getPodcasters.pending]: state => {
			state.loading = true;
			state.error = null;
		},
		[getPodcasters.fulfilled]: (state, action) => {
			state.loading = false;
			state.success = true;
			state.podcastersInfo = action.payload;
		},
		[getPodcasters.rejected]: (state, action) => {
			state.loading = false;
			state.success = false;
			state.error = action.payload;
		},
	},
});

// export const {} = podcastersSlice.actions

export const selectPodcasters = state => state.podcasters;

export default podcastersSlice.reducer;
