import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { API_ENDPOINT } from '../../../config/config';
import axiosInstance from '../../../service/axiosInstance';

const initialState = {
	loading: false,
	event_name: '',
	count: null,
	id: '',
	data: [],
	finished: false,
	isError: false,
};

export const postDraftEventTeamData = createAsyncThunk(
	'draftConfigurationData/postDraftEventTeamData',
	async (data, { dispatch, rejectWithValue }) => {
		try {
			const res = await axiosInstance.post(`${API_ENDPOINT}event_team/`, data);
			dispatch(setDraftConfigurationData(res.data));
			return res.data; // for extra reducer work
		} catch (error) {
			console.log(error, "error");
			// console.log(error.response, "errorresponse");

			if (error.response && error.response.status === 403) {
				console.log('Access Forbidden: ', error.response.data);
			// }
			// if (error.response && error.response.data.error && error.response.data.message) {
			// 	console.log(error.response, "errorresponse");
			// 	return rejectWithValue(error.response.data.error);
			} else {
				// return rejectWithValue(error.message);
				return rejectWithValue("Something went wrong")
			}
		}
	}
);

export const updateDraftEventTeamData = createAsyncThunk(
	'draftConfigurationData/updateDraftEventTeamData',
	async ({ event_name, data, id, finish }, { dispatch, getState, rejectWithValue }) => {
		//     async (data, { dispatch,getState, rejectWithValue }) => {
		//         const state = getState().draftConfigurationData;
		const updateData = { event_name, data, finish };
		try {
			const res = await axiosInstance.put(`${API_ENDPOINT}event_team/${id}/`, updateData);
			dispatch(setDraftConfigurationData(res.data));
			return res.data; // for extra reducer work
		} catch (error) {
			if (error.response && error.response.data.error) {
				return rejectWithValue(error.response.data.error);
			} else {
				return rejectWithValue(error.message);
			}
		}
	}
);

export const getDraftEventTeamData = createAsyncThunk(
	'draftConfigurationData/getDraftEventTeamData',
	async (id, { dispatch, getState, rejectWithValue }) => {
		try {
			const res = await axiosInstance.get(`${API_ENDPOINT}event_team/${id}/`);
			dispatch(setDraftConfigurationData(res.data));
			return res.data;
		} catch (error) {
			if (error.response && error.response.data.error) {
				return rejectWithValue(error.response.data.error);
			} else {
				return rejectWithValue(error.message);
			}
		}
	}
);


export const deleteDraftEventData = createAsyncThunk(
	'draftConfigurationData/deleteDraftEventData',
	async (id, { dispatch, rejectWithValue }) => {
		try {
			const res =await axiosInstance.delete(`${API_ENDPOINT}event_team/${id}/`);
			dispatch(setInitialDraftConfigurationData({
				loading: false,
				event_name: '',
				count: null,
				id: '',
				data: [],
				finished: false,
				isError: false,
			}));
			return res.data;
		} catch (error) {
			if (error.response && error.response.data.error) {
				return rejectWithValue(error.response.data.error);
			} else {
				return rejectWithValue(error.message);
			}
		}
	}
);


export const draftConfigurationDataSlice = createSlice({
	name: 'draftConfigurationData',
	initialState,
	reducers: {
		setDraftConfigurationData: (state, action) => {
			state.event_name = action.payload?.event_name || state.event_name;
			state.id = action.payload?.id || state.id;
			state.count = action.payload?.count || state.count;
			state.data = action.payload?.data || state.data;
			// state.finish = action.payload?.finish || state.finish;
		},
		updateDraftData: (state, action) => {
			state.data = [...state.data, ...action.payload];
		},
		setInitialDraftConfigurationData:(state, action)=>{
			state.loading =action.payload.loading;
			state.event_name=action.payload.event_name;
			state.count = action.payload.count;
			state.id = action.payload.id;
			state.data=action.payload.data;
			state.finished=action.payload.finished;
			state.isError=action.payload.isError;
		}
	},
	extraReducers: {
		[postDraftEventTeamData.fulfilled]: (state, action) => {
			state.loading = false;
			state.isError = false;
		},
		[postDraftEventTeamData.pending]: (state, action) => {
			state.loading = true;
			state.isError = false;
		},
		[postDraftEventTeamData.rejected]: (state, action) => {
			state.loading = false;
			state.isError = true;
			if (action.payload) {
				toast.error(action.payload);
			}
		},
		[updateDraftEventTeamData.fulfilled]: (state, action) => {
			state.loading = false;
			state.isError = false;
		},
		[updateDraftEventTeamData.pending]: (state, action) => {
			state.loading = true;
			state.isError = false;
		},
		[updateDraftEventTeamData.rejected]: (state, action) => {
			state.loading = false;
			state.isError = true;
		},
		[getDraftEventTeamData.fulfilled]: (state, action) => {
			state.loading = false;
			state.isError = false;
		},
		[getDraftEventTeamData.pending]: (state, action) => {
			state.loading = true;
			state.isError = false;
		},
		[getDraftEventTeamData.rejected]: (state, action) => {
			state.loading = false;
			state.isError = true;
		},
		[deleteDraftEventData.fulfilled]: (state, action) => {
			state.loading = false;
			state.isError = false;
		},
		[deleteDraftEventData.pending]: (state, action) => {
			state.loading = true;
			state.isError = false;
		},
		[deleteDraftEventData.rejected]: (state, action) => {
			state.loading = false;
			state.isError = true;
		},
	},
});

export const selectDraftConfigurationData = state => state.draftConfigurationData;
export const { setDraftConfigurationData, updateDraftData, setInitialDraftConfigurationData } = draftConfigurationDataSlice.actions;
export default draftConfigurationDataSlice.reducer;
