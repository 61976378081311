import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BiSolidEdit } from 'react-icons/bi';
import { FaSave } from 'react-icons/fa';
import { MdDeleteOutline } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { getColleges, getPositions, selectGroup, setResetGroup } from '../../../../app/features/group/groupSlice';
import { myPlayersDelete, myPlayersGet, myPlayersUpdate } from '../../../../app/features/myPlayers/myPlayersActions';
import { selectMyPlayers, updateLocal, resetPlayersState } from '../../../../app/features/myPlayers/myPlayersSlice';
import { resPlayersDraft } from '../../../../app/features/playersDraft/playersDraftSlice';
import { CustomSelect } from '../../../../components/AddPLayer/AddPLayer';
import {
	ActionButton,
	ActionText,
	Label,
	MainContainer,
	PageNumber,
	Pagination,
	PlayerActions,
	PlayerCard,
	PlayerCollege,
	PlayerInfo,
	PlayerName,
	PlayerPosition,
} from './PlayerList.styles';

const PlayerList = () => {
	const [isEditing, setIsEditing] = useState(null);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [selectedPlayerId, setSelectedPlayerId] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const { myPlayers, loading } = useSelector(selectMyPlayers);
	const playersPerPage = 5;
	const groups = useSelector(selectGroup);
	const dispatch = useDispatch();

	useEffect(() => {
		if (myPlayers.length === 0) {
			dispatch(myPlayersGet());
		}
	}, [myPlayers.length, dispatch]);

	useEffect(() => {
		if (isEditing !== null) {
			dispatch(getPositions());
			dispatch(getColleges());
		}
		return () => {
			dispatch(setResetGroup());
			dispatch(resPlayersDraft());
		};
	}, [isEditing, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(resetPlayersState());
		};
	}, [dispatch]);

	const handleEdit = useCallback(id => {
		setIsEditing(id);
	}, []);

	const handleDelete = useCallback(id => {
		setSelectedPlayerId(id);
		setDialogOpen(true);
	}, []);

	const confirmDelete = useCallback(() => {
		dispatch(myPlayersDelete({ id: selectedPlayerId }))
			.unwrap()
			.then(() => {
				setDialogOpen(false);
				dispatch(myPlayersGet()); // Refresh the players list after deletion
			})
			.catch(error => console.error('Error deleting player:', error));
	}, [dispatch, selectedPlayerId]);

	const handleChange = useCallback(
		(id, field, value) => {
			const updatedPlayers = myPlayers.map(player => (player.id === id ? { ...player, [field]: value } : player));
			dispatch(updateLocal(updatedPlayers));
		},
		[dispatch, myPlayers]
	);

	const handleSave = useCallback(
		id => {
			const player = myPlayers.find(player => player.id === id);
			dispatch(myPlayersUpdate(player))
				.unwrap()
				.then(() => {
					console.log('Player updated successfully');
					setIsEditing(null);
				})
				.catch(error => console.error('Error updating player:', error));
		},
		[dispatch, myPlayers]
	);

	const filteredPositions = useMemo(() => groups.positions.slice(1), [groups.positions]);
	const filteredColleges = useMemo(() => groups.colleges.slice(1), [groups.colleges]);

	const indexOfLastPlayer = currentPage * playersPerPage;
	const indexOfFirstPlayer = indexOfLastPlayer - playersPerPage;
	const currentPlayers = useMemo(
		() => myPlayers.slice(indexOfFirstPlayer, indexOfLastPlayer),
		[myPlayers, indexOfFirstPlayer, indexOfLastPlayer]
	);

	const paginate = useCallback(pageNumber => setCurrentPage(pageNumber), []);

	return (
		<MainContainer>
			{currentPlayers.map(player => (
				<PlayerCard key={player.id}>
					<PlayerInfo>
						<Label>Name</Label>
						{isEditing === player.id ? (
							<TextField
								value={player.player}
								onChange={e => handleChange(player.id, 'player', e.target.value)}
								variant="outlined"
								fullWidth
							/>
						) : (
							<PlayerName>{player.player}</PlayerName>
						)}
					</PlayerInfo>
					<PlayerInfo className="position">
						<Label>Position</Label>
						{isEditing === player.id ? (
							<CustomSelect
								value={player.position}
								dataValue={filteredPositions}
								onChange={value => handleChange(player.id, 'position', value)}
								customWidth="100px"
							/>
						) : (
							<PlayerPosition>{player.position}</PlayerPosition>
						)}
					</PlayerInfo>
					<PlayerInfo className="college">
						<Label>College</Label>
						{isEditing === player.id ? (
							<CustomSelect
								value={player.school}
								dataValue={filteredColleges}
								onChange={value => handleChange(player.id, 'school', value)}
								customWidth="170px"
							/>
						) : (
							<PlayerCollege>{player.school}</PlayerCollege>
						)}
					</PlayerInfo>
					<PlayerActions>
						<ActionButton onClick={() => handleDelete(player.id)} className="delete">
							<MdDeleteOutline size={24} />
							<ActionText>Delete</ActionText>
						</ActionButton>
						<ActionButton
							onClick={() => {
								if (isEditing === player.id) {
									handleSave(player.id);
								} else {
									handleEdit(player.id);
								}
							}}
							className={isEditing === player.id ? 'save' : 'edit'}
						>
							{isEditing === player.id ? <FaSave size={24} color="green" /> : <BiSolidEdit size={24} />}
							<ActionText>{isEditing === player.id ? 'Save' : 'Edit'}</ActionText>
						</ActionButton>
					</PlayerActions>
				</PlayerCard>
			))}
			<Pagination>
				{[...Array(Math.ceil(myPlayers.length / playersPerPage)).keys()].map(number => (
					<PageNumber
						key={number + 1}
						onClick={() => paginate(number + 1)}
						className={currentPage === number + 1 ? 'active' : ''}
					>
						{number + 1}
					</PageNumber>
				))}
			</Pagination>
			<Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
				<DialogTitle>Confirm Delete</DialogTitle>
				<DialogContent>Are you sure you want to delete this player?</DialogContent>
				<DialogActions>
					<Button onClick={() => setDialogOpen(false)} color="primary">
						No
					</Button>
					<Button onClick={confirmDelete} color="primary">
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		</MainContainer>
	);
};

export default React.memo(PlayerList);
