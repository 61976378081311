import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'; //working latest
import axios from 'axios';
import { API_ENDPOINT } from '../../../config/config';
import { setExcludePlayerId } from '../nfldraftfanaticsSlice/nfldraftfanaticsSlice';

const initialState = {
	loading: false,
	count: 0,
	next: null,
	previous: null,
	currentPage: 1,
	limit: 18,
	offset: 0,
	results: [],
	search: '',
	draftPlayerId: '',
	position: [''],
	/*  colleage:"",*/
	playerIds: [],
};

export const getPlayersDraftTeam = createAsyncThunk(
	'playersDraftTeam/getPlayersDraftTeam',
	async (setLimit, { dispatch, getState, rejectWithValue }) => {
		try {
			const {
				playersDraftTeam: { limit, playerIds, search },
			} = getState();
			const playerLimit = setLimit ? setLimit : limit;
			const updatedPlayerIds = [...playerIds];
			const excludeIdsParam = updatedPlayerIds.map(id => `exclude_ids=${id}`).join('&');
			const res = await axios.get(
				`${API_ENDPOINT}players/?${excludeIdsParam}&limit=${playerLimit}&offset=${0}&search=${search}&position=&school`
			);
			const resData = { ...res.data, limit: playerLimit, currentPage: 1, playerIds, search };
			dispatch(setPlayersDraftTeam(resData));
			// dispatch(setNFLBotDataPlayers(res.data.results ));
			// dispatch(setNFLBotDataExcludePlayers(playerIds));
		} catch (error) {
			if (error.response && error.response.data.message) {
				return rejectWithValue(error.response.data.message);
			} else {
				return rejectWithValue(error.message);
			}
		}
	}
);

export const getRefreshedPlayersDraftTeam = createAsyncThunk(
	'playersDraftTeam/getRefreshedPlayersDraftTeam',
	async (ids, { dispatch, getState, rejectWithValue }) => {
		try {
			const {
				playersDraftTeam: { limit, playerIds, search },
			} = getState();
			const updatedPlayerIds = [...playerIds, ...ids];
			const excludeIdsParam = updatedPlayerIds.map(id => `exclude_ids=${id}`).join('&');
			const res = await axios.get(
				`${API_ENDPOINT}players/?${excludeIdsParam}&limit=${limit}&offset=${0}&search=${search}&position=&school`
			);
			const resData = { ...res.data, currentPage: 1, playerIds: updatedPlayerIds, search };
			dispatch(setPlayersDraftTeam(resData));
		} catch (error) {
			if (error.response && error.response.data.message) {
				return rejectWithValue(error.response.data.message);
			} else {
				return rejectWithValue(error.message);
			}
		}
	}
);

export const positionPlayersDraftTeam = createAsyncThunk(
	'playersDraftTeam/positionPlayersDraftTeam',
	async (setPosition, { dispatch, getState, rejectWithValue }) => {
		try {
			const {
				playersDraftTeam: { limit, playerIds, search },
			} = getState();
			const playerPosition = setPosition.length ? setPosition.map(item => `position=${item}`).join('&') : '';
			const excludeIdsParam = playerIds.map(id => `exclude_ids=${id}`).join('&');
			const res = await axios.get(
				`${API_ENDPOINT}players/?${excludeIdsParam}&limit=${limit}&offset=${0}&search=${search}&${playerPosition}`
			);
			const resData = { ...initialState, ...res.data, limit, position: setPosition, playerIds, search };
			dispatch(setPlayersDraftTeam(resData));
		} catch (error) {
			if (error.response && error.response.data.message) {
				return rejectWithValue(error.response.data.message);
			} else {
				return rejectWithValue(error.message);
			}
		}
	}
);

export const searchPlayersDraftTeam = createAsyncThunk(
	'playersDraftTeam/searchPlayersDraftTeam',
	async (setSearch, { dispatch, getState, rejectWithValue }) => {
		try {
			const {
				playersDraftTeam: { position, playerIds },
			} = getState();
			const updatedPlayerIds = [...playerIds];
			const excludeIdsParam = updatedPlayerIds.map(id => `exclude_ids=${id}`).join('&');
			const positionCopy = [...position];
			const updatedPosition = positionCopy.length ? positionCopy.map(item => `position=${item}`).join('&') : '';
			const res = await axios.get(
				`${API_ENDPOINT}players/?${excludeIdsParam}&limit=${18}&offset=${0}&search=${setSearch}&${updatedPosition}&school=`
			);
			dispatch(setSearchDraftTeam(setSearch));
			const resData = { ...res.data, search: setSearch, playerIds, position };
			dispatch(setPlayersDraftTeam(resData));
		} catch (error) {
			if (error.response && error.response.data.message) {
				return rejectWithValue(error.response.data.message);
			} else {
				return rejectWithValue(error.message);
			}
		}
	}
);

export const pageNavDraftTeam = createAsyncThunk(
	'playersDraftTeam/pageNavDraftTeam',
	async (currentPage, { dispatch, getState, rejectWithValue }) => {
		try {
			const {
				playersDraftTeam: { limit, search, position, playerIds },
			} = getState();
			const offset = (currentPage - 1) * limit;
			const positionCopy = [...position];
			const updatedPosition = positionCopy.length ? positionCopy.map(item => `position=${item}`).join('&') : '';
			const updatedPlayerIds = [...playerIds];
			const excludeIdsParam = updatedPlayerIds.map(id => `exclude_ids=${id}`).join('&');
			const res = await axios.get(
				`${API_ENDPOINT}players/?${excludeIdsParam}&limit=${limit}&offset=${offset}&search=${search}&${updatedPosition}`
			);
			const resData = { ...res.data, currentPage, offset, position, playerIds, search };
			dispatch(setPlayersDraftTeam(resData));
		} catch (error) {
			if (error.response && error.response.data.message) {
				return rejectWithValue(error.response.data.message);
			} else {
				return rejectWithValue(error.message);
			}
		}
	}
);

export const draftTeamPlayer = createAsyncThunk(
	'playersDraftTeam/draftTeamPlayer',
	async (setPlayerId, { dispatch, getState, rejectWithValue }) => {
		try {
			const {
				playersDraftTeam: { playerIds, limit, search, position },
			} = getState();
			const positionCopy = [...position];
			const updatedPosition = positionCopy.length ? positionCopy.map(item => `position=${item}`).join('&') : '';
			const updatedPlayerIds = [...playerIds, setPlayerId];
			const excludeIdsParam = updatedPlayerIds.map(id => `exclude_ids=${id}`).join('&');
			const res = await axios.get(
				`${API_ENDPOINT}players/?${excludeIdsParam}&limit=${limit}&offset=${0}&search=${search}&${updatedPosition}`
			);
			const resData = { ...initialState, ...res.data, limit, position, playerIds: updatedPlayerIds, search };
			dispatch(setPlayersDraftTeam(resData));
			dispatch(setExcludePlayerId(updatedPlayerIds));
		} catch (error) {
			if (error.response && error.response.data.message) {
				return rejectWithValue(error.response.data.message);
			} else {
				return rejectWithValue(error.message);
			}
		}
	}
);

export const playersDraftTeamSlice = createSlice({
	name: 'playersDraftTeam',
	initialState,
	reducers: {
		setPlayersDraftTeam: (state, action) => {
			state.limit = action.payload?.limit || state.limit;
			state.offset = action.payload?.offset || state.offset;
			state.currentPage = action.payload?.currentPage || state.currentPage;
			state.search = action.payload?.search || state.search;
			state.position = action.payload?.position || state.position;
			state.colleage = action.payload?.colleage || state.colleage;
			state.count = action.payload.count;
			state.next = action.payload.next;
			state.previous = action.payload.previous;
			state.results = action.payload.results;
			state.playerIds = action.payload.playerIds || state.playerIds;
			// state.draftPlayerId= action.payload?.draftPlayerId; //check
		},
		setInitialDraftTeam: (state, action) => {
			state.count = action.payload.count;
			state.next = action.payload.next;
			state.previous = action.payload.previous;
			state.currentPage = action.payload?.currentPage;
			state.limit = action.payload?.limit;
			state.offset = action.payload?.offset;
			state.results = action.payload.results;
			state.search = action.payload?.search;
			state.draftPlayerId = action.payload?.draftPlayerId;
			state.position = action.payload?.position;
			state.colleage = action.payload?.colleage;
			state.playerIds = action.payload.playerIds;
		},
		setPositionDraftTeam: (state, action) => {
			state.position = action.payload;
		},
		setSearchDraftTeam: (state, action) => {
			state.search = action.payload;
		},
	},
	extraReducers: {
		[getPlayersDraftTeam.fulfilled]: (state, action) => {
			state.loading = false;
		},
		[getPlayersDraftTeam.pending]: (state, action) => {
			state.loading = true;
		},
		[getPlayersDraftTeam.rejected]: (state, action) => {
			state.loading = false;
		},
		[getRefreshedPlayersDraftTeam.fulfilled]: (state, action) => {
			state.loading = false;
		},
		[getRefreshedPlayersDraftTeam.pending]: (state, action) => {
			state.loading = true;
		},
		[getRefreshedPlayersDraftTeam.rejected]: (state, action) => {
			state.loading = false;
		},
		[searchPlayersDraftTeam.fulfilled]: (state, action) => {
			state.loading = false;
		},
		[searchPlayersDraftTeam.pending]: (state, action) => {
			state.loading = true;
		},
		[searchPlayersDraftTeam.rejected]: (state, action) => {
			state.loading = false;
		},
		[positionPlayersDraftTeam.fulfilled]: (state, action) => {
			state.loading = false;
		},
		[positionPlayersDraftTeam.pending]: (state, action) => {
			state.loading = true;
		},
		[positionPlayersDraftTeam.rejected]: (state, action) => {
			state.loading = false;
		},
		[pageNavDraftTeam.fulfilled]: (state, action) => {
			state.loading = false;
		},
		[pageNavDraftTeam.pending]: (state, action) => {
			state.loading = true;
		},
		[pageNavDraftTeam.rejected]: (state, action) => {
			state.loading = false;
		},
		[draftTeamPlayer.fulfilled]: (state, action) => {
			state.loading = false;
		},
		[draftTeamPlayer.pending]: (state, action) => {
			state.loading = true;
		},
		[draftTeamPlayer.rejected]: (state, action) => {
			state.loading = false;
		},
	},
});

export const selectPlayersDraftTeam = state => state.playersDraftTeam;
export const { setPlayersDraftTeam, setPositionDraftTeam, setSearchDraftTeam, setInitialDraftTeam } =
	playersDraftTeamSlice.actions;

export default playersDraftTeamSlice.reducer;

export const positionActionDraftTeam = positionValue => dispatch => {
	if (positionValue === '') {
		dispatch(setPositionDraftTeam(''));
		dispatch(getPlayersDraftTeam());
	} else {
		dispatch(setPositionDraftTeam(positionValue));
		dispatch(positionPlayersDraftTeam(positionValue));
	}
};
