//npm run // https:api.nfldraftfanatics.com/
export const SOCKET_URL = "https://api.nfldraftfanatics.com/";
export const API_ENDPOINT = "https://api.nfldraftfanatics.com/api/v1/";
export const API_PLAN = "https://api.nfldraftfanatics.com/";
export const PLAYER_MAX = 100000;
export const CLIENT_ID_PAYPAL =
  "AUkB2v4g9nd2UednHFriARyvctw68K3PoEguaS-rS7KJpzBPqou_8iYOg5TV2REOTtN82jyL0b9XhCzA";
export const CURRENCY = "USD";
// export const baseURL = 'http://192.241.154.248:8000'

export const TIME_CONFIG = {
  1: 120000,
  2: 90000,
  3: 45000,
};
