import { Box, Grid, Pagination } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useState } from 'react';
import { MdOutlineFavorite } from 'react-icons/md';
import { RiTwitterXFill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { getPodcasters, subscribePodcaster } from '../../../app/features/podcasters/podcastersActions';
import { selectPodcasters } from '../../../app/features/podcasters/podcastersSlice';
import { PodcastsWrapper } from './Podcasts.styles';
import {API_ENDPOINT} from "../../../config/config";
import {selectUser} from "../../../app/features/user/userSlice";

const Podcasts = () => {
	const {
		loading,
		podcastersInfo } = useSelector(selectPodcasters);
	const dispatch = useDispatch();
	const [page, setPage] = useState(1);
	const itemsPerPage = 10;
	const { userInfo } = useSelector(selectUser);

	const fetchPodcaters = async () => {
		await dispatch(getPodcasters());
	};


	useEffect(() => {
		if (!podcastersInfo)
			fetchPodcaters();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	const handlePageChange = (event, value) => {
		setPage(value);
	};

	// const paginatedPodcasters = podcastersInfo?.slice((page - 1) * itemsPerPage, page * itemsPerPage);

	const handleSubscribe = async podcaster_id => {
		await dispatch(subscribePodcaster(podcaster_id));
		fetchPodcaters();
	};



	return (
		<PodcastsWrapper>
			<h3>Podcasters</h3>
			{ userInfo?.role === "Free" && <p>For subscribing podcasters you should buy Basic plan!</p>}
			{userInfo?.role === "Basic"  &&
			<Grid container spacing={2}>
				{podcastersInfo?.results?.map(podcaster => (
					<Grid item xs={12} sm={6} md={4} key={podcaster.id}>
						<Card sx={{maxWidth: 345, backgroundColor: 'transparent', border: '1px solid #cad2da'}}>
							<CardHeader
								avatar={
									<Avatar
										alt={`${podcaster.first_name} ${podcaster.last_name}`}
										sx={{bgcolor: '#01448A'}}
										// src={`https://api.nfldraftfanatics.com${podcaster?.profile_picture}`}
										src={podcaster?.profile_picture?.original} //check
									/>
								}
								action={
									<IconButton aria-label="settings" onClick={() => handleSubscribe(podcaster.id)}>
										<MdOutlineFavorite color={podcaster.is_subscribed ? 'red' : 'grey'}/>
									</IconButton>
								}
								title={`${podcaster.first_name} ${podcaster.last_name}`}
								subheader={
									<Box
										sx={{
											marginTop: '10px',
											display: 'flex',
											alignItems: 'center',
											gap: '10px',
											color: '#000000a2',
											fontWeight: '400',
										}}
									>
										<RiTwitterXFill/>
										{`${podcaster.twitter_link}`}
									</Box>
								}
							/>
						</Card>
					</Grid>
				))}
			</Grid>
			}
			{/* {podcastersInfo?.length > 10 && (
				<Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
					<Pagination
						count={Math.ceil(podcastersInfo?.length / itemsPerPage)}
						page={page}
						onChange={handlePageChange}
						color="primary"
					/>
				</Box>
			)} */}
			{podcastersInfo?.limit > 18 && (  //check
				<Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
					<Pagination
						totalCount={podcastersInfo.count}
						pageSize={podcastersInfo.limit || 18}
						currentPage={podcastersInfo.currentPage}
						previous={podcastersInfo.previous}
						next={podcastersInfo.next}
						// onPageChange={page => {
						// 	dispatch(pageNav(page));
						// }}
					/>
				</Box>
			)}
		</PodcastsWrapper>
	);
};

export default Podcasts;
