export const HOME = '/';
export const SELECT_DRAFT = '/select-draft';
export const DRAFT_CONFIG = '/draft-configuration';
export const DRAFT_PLAYER = '/draft-player';
export const DRAFT_RESULT = '/draft-result';
export const PLAYERS = '/players';
export const DRAFT_VALUE_CHART = '/draft-value-chart';
export const TEAM_NEEDS = '/team-needs';
export const TEAM_LIST = '/team-list';
export const SIGH_UP = '/sign-up';
export const SIGN_IN = '/sign-in';
export const FORGOT_PASS = '/forgot-password';
export const RESET_PASS = '/reset-password';

export const NOT_FOUND = '*';
// Profile
export const PROFILE = '/profile/info';
export const PROFILE_BADGES = '/profile/badges';
export const PROFILE_MY_PLAYERS = '/profile/my-players';
export const PROFILE_DRAFT_EVENTS = '/profile/draft-events';
export const PROFILE_DRAFT_EVENTS_CREATE = '/profile/draft-events/create';
export const PROFILE_DRAFT_EVENTS_MY = '/profile/draft-events/my-draft';
export const PROFILE_DRAFT_EVENTS_MY_EDIT = '/profile/draft-events/my-draft/edit';
export const PROFILE_DRAFT_EVENTS_MY_VIEW = '/profile/draft-events/my-draft/view';
export const PROFILE_PODCASTS = '/profile/podcasts';
export const PROFILE_PAYMENT = '/profile/payment';
export const PROFILE_SUBSCRIPTION = '/profile/subscription';
export const PROFILE_SUBSCRIPTION_RETURN = '/profile/subscription/execute';
export const PROFILE_PASSWORD = '/profile/password';
export const PROFILE_LOGOUT = '/profile/logout';

// Multi-Player Draft

export const MULTI_PLAYER_FIND = '/multi-player-find';
export const MULTI_PLAYER_TEAM = '/multi-player-team';
export const MULTI_PLAYER_JOIN_TEAM_ID = '/multi-player-team/:id';
export const MULTI_PLAYER_JOIN_TEAM = '/multi-player-team';
export const LIVE_DRAFT = '/live-draft';
export const LIVE_DRAFT_ID = '/live-draft/:id';

export const LIVE_RESULT = '/live-result';

//Draft a team
export const DRAFT_TEAM_CONFIGURATION = '/draft-team-configuration';
export const SELECT_DRAFT_MODE_ID = '/select-draft-mode/:id';
export const PLAYERS_POSITIONS_ID = '/players-positions/:id';

// Rankings
export const PLAYER_RANKING = '/players-rankings';
export const BIG_BOARD = '/big-board';
export const RANKING_GRAPHICS = '/ranking-graphics'