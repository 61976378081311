import styled from 'styled-components';

export const Wrapper = styled.div`
	&.main-container {
		margin-top: 44px;
	}
	& .draftResultTitle {
		margin-bottom: 0;
		font-style: normal;
		font-weight: 400;
		font-size: 36px;
		line-height: 57px;
		color: #000000;
	}
	& .share-draft-wrap {
		margin: 24px 0;
	}
	& .share-draft {
		display: flex;
		align-items: center;
		gap: 20px;
		p {
			font-weight: 400;
			font-size: 20px;
			line-height: 30px;
			color: #000000;
		}
	}
`;

export const DraftResultShare = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	& .enter-draft-btn {
		margin: 0;
	}
`;

export const ActionWrap = styled.div`
	display: flex;
	align-items: center;
	gap: 23px;
	margin-bottom: 23px;
	.downland-btn {
		display: flex;
		align-items: center;
		color: #004ea3;
		gap: 6px;
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		text-align: center;
		cursor: pointer;
	}
	.downland-copy {
		display: flex;
		align-items: center;
		gap: 6px;
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		text-align: center;
		color: #004ea3;
		cursor: pointer;
	}
`;

export const DraftConfigPlayersPositions = styled.section`
	margin-top: 25px;
	margin-bottom: 25px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 25px;
	height: 100%;
	overflow-x: auto;
`;

export const PlayersBoardContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const PlayersBoard = styled.div`
	background-image: url(${props => props.backImg || ''});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom;
	height: auto;
	width: 700px;
	padding: 60px 20px;
	display: flex;
	flex-direction: column;
	.titleDiv {
		display: flex;
		gap: 20px;
	}
	.logoDiv {
		//width: 100px;
		//height: 160px;
	}
	.logoDiv img {
		//width: 129px;
		//height: 160px;
	}
	.title {
		width: 100%;
		display: flex;
		justify-content: center;
		color: #bfb881;
		font-size: 44px;
		font-style: normal;
		font-weight: 700;
		margin-bottom: 80px;
		white-space: nowrap;
	}
	.player-name {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: 21px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		text-transform: uppercase;
		flex-wrap: wrap;
	}
	.row {
		display: flex;
		justify-content: space-between;
		width: 100%;
		gap: 30px;
	}
	table {
		width: 100%;
		border-collapse: separate;
	}
	td {
		padding-bottom: 40px;
	}
	.wrx-div {
		color: #ffce07;
	}
	.cb1-div {
		color: #fff;
	}
	.wrs-div {
		color: #ffce07;
	}
	.dflex1-div {
		color: #fff;
	}
	.lt-div {
		color: #ffce07;
	}
	.edge-div {
		color: #fff;
	}
	.lg-div {
		color: #ffce07;
	}
	.lb-div {
		color: #fff;
	}
	.rb-div {
		color: #ffce07;
	}
	.qb-div {
		color: #ffce07;
	}
	.c-div {
		color: #ffce07;
	}
	.dl1-div {
		color: #fff;
	}
	.s1-div {
		color: #fff;
	}
	.rg-div {
		color: #ffce07;
	}
	.dl2-div {
		color: #fff;
	}
	.rt-div {
		color: #ffce07;
	}
	.te-div {
		color: #ffce07;
	}
	.dflex3-div {
		color: #fff;
	}
	.dflex2-div {
		color: #fff;
	}
	.s2-div {
		color: #fff;
	}
	.wrz-div {
		color: #ffce07;
	}
	.cb2-div {
		color: #fff;
	}
`;
