import styled from 'styled-components';

export const DraftSearchData = styled.section`
	display: flex;
	flex-direction: column;
	width: 40%;
	padding-left: 24px;
	padding-right: 24px;

	.pagination-div {
		display: flex;
		justify-content: flex-end;
	}
`;

export const DraftMyPlayersSlider = styled.div`
	margin-top: 22px;
	max-width: 538px;
	width: 100%;
  .swiper-players-part{
    border-radius: 8px;
    border: 1px solid #d4d4d4;
    background: rgba(212, 212, 212, 0.2);
    padding: 12px;
    margin-bottom: 40px;
    overflow-x: auto;
    white-space: nowrap;
  }
	&::-webkit-scrollbar {
		height: 6px;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background: #888;
		border-radius: 3px;
		width: 20%;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
	.swiper {
		max-width: 500px;
		width: 100%;
		height: 100%;
		overflow: visible;
	}

	.swiper-slide {
		display: inline-block;
		justify-content: center;
		align-items: center;
	}
`;
export const SingleAdded = styled.div`
	padding: 0 12px 12px 0px;
	border-radius: 8px;
	background: #fff;
	max-width: 260px;
	width: 100%;
	height: auto;

	.single-added-position {
		background-color: ${props => props.backColor};
		width: 45px;
		height: 44px;
		border-radius: 8px 0px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #000;
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: 36px;
		margin-bottom: 12px;
		text-transform: uppercase;
	}
	.single-added-name {
		margin-left: 12px;
		color: #00438b;
		margin-bottom: 15px;
		font-size: 24px;
		font-style: normal;
		font-weight: 500;
		line-height: 32px;
	}
	.single-added-surname {
		margin-left: 12px;
		color: #00438b;
		margin-bottom: 15px;
		font-size: 24px;
		font-style: normal;
		font-weight: 500;
		line-height: 12px;
	}
	.single-added-school {
		margin-left: 12px;
		margin-right: 4px;
		color: #767676;
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		line-height: 30px;
		white-space: pre-wrap;
	}
	.single-added-schoolname {
		color: #000;
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		line-height: 30px;
	}
`;

export const DraftSearch = styled.div`
	max-width: 538px;
	width: 100%;
	padding: 9px 12px;
	background: #ffffff;
	border-radius: 3px;
	border: 1px solid #d4d4d4;
	display: flex;
	justify-content: space-between;
	margin-bottom: 24px;
	.searchIcon {
		width: 24px;
		height: 24px;
		cursor: pointer;
	}
	.searchInput {
		width: 100%;
		border: none;
		color: #3e464f;
		font-family: 'Saira Semi Condensed', sans-serif;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
	}
	.searchInput:focus {
		border: none;
		outline: none;
	}
`;

export const DraftPlayers = styled.div`
	border-top: 1px solid #d4d4d4;
	padding-top: 40px;
	margin-top: 26px;
	margin-bottom: 40px;
	width: 100%;
	display: flex;
	flex-direction: column;
`;
export const DraftPlayerItem = styled.div`
	max-width: 538px;
	width: 100%;
	padding: 8px 24px;
	background-color: ${props => (props.backColor ? `${props.backColor}` : '#fff')};
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 12px;
	margin-bottom: 16px;

	.single-inside {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 4px;
	}
	.first {
		width: 10%;
	}
	.second {
		width: 10%;
	}
	.third {
		width: 35%;
	}
	.fourth {
		width: 15%;
	}
	.name {
		color: #767676;
		font-family: 'Saira Semi Condensed', sans-serif;
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		line-height: 30px;
	}
	.value {
		color: #000;
		font-family: 'Saira Semi Condensed', sans-serif;
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		line-height: 30px;
	}
	.name-blue {
		color: #00438b;
		font-family: 'Saira Semi Condensed', sans-serif;
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		line-height: 30px;
	}
	.value-blue {
		color: #00438b;
		font-family: 'Saira Semi Condensed', sans-serif;
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		line-height: 30px;
		width: 30%;
	}
	.single-position {
		border-radius: 8px;
		border: 2px solid #767676;
		background: rgba(255, 255, 255, 0.2);
		display: flex;
		justify-content: center;
		align-items: center;
		color: #000;
		font-family: 'Saira Semi Condensed', sans-serif;
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: 36px;
		text-transform: uppercase;
		padding: 4px 10px;
	}
	button {
		padding: 6px 24px;
		border-radius: 3px;
		background: #004ea3;
		color: #fff;
		font-family: 'Saira Semi Condensed', sans-serif;
		font-size: 19px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
		cursor: pointer;
	}
	button:disabled {
		cursor: not-allowed;
	}
`;
export const NumWrapper = styled.div`
	display: flex;
	gap: 18px;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 24px;
	.trades-btn button {
		background: #004ea3;
		border-radius: 4px;
		padding: 12px 24px;
		font-weight: 400;
		border: none;
		color: #fff;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		align-items: center;
		margin: 0 auto;
		cursor: pointer;
		justify-content: center;
	}
`;
