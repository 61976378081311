import styled from 'styled-components';
import { device } from '../../themes/Breakpoints';

export const Wrapper = styled.section`
	padding: 0 20px;
	width: 100%;
	height: 100%;
`;

export const RankingsWrapper = styled.div`
	width: 100%;
	height: 100%;
	margin-top: 4%;
	background: #fff;
	border-radius: 10px;
	padding: 0 24px;
	.rankingTitle {
		margin-bottom: 0px;
	}
`;

export const SubTitle = styled.div`
	margin-top: 16px;
	p {
		color: #3e464f;
		font-size: 24px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
`;

export const UserInfo = styled.div`
	p,
	span {
		color: #9ba1a7;
		font-family: 'Saira Semi Condensed', sans-serif;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		margin-top: 40px;
	}
`;

export const DividerLine = styled.div`
	width: 100%;
	background-color: #d4d4d4;
	height: 1px;
	margin-top: ${props => (props.$mt ? `${props.$mt}` : '0px')};
	margin-bottom: ${props => (props.$mb ? `${props.$mb}` : '0px')};
`;

export const RankingsHeader = styled.div`

`;

export const RankPlayersWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 40px;
	.ranking-action {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 24px;
		margin-top: 80px;
		.disable {
			opacity: 0.5;
			pointer-events: none;
		}
		.remove {
			display: flex;
			align-items: center;
			color: #e74c3c;
			font-size: 19px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px;
			gap: 10px;
			cursor: pointer;
		}
		a,
		.save {
			display: inline-flex;
			padding: 9px 24px;
			align-items: center;
			gap: 10px;
			flex-shrink: 0;
			border-radius: 3px;
			background: #004ea3;
			width: fit-content;
			color: #fff;
			font-size: 19px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px;
			cursor: pointer;
		}
	}
	.ranking-players {
		width: 100%;
		margin-top: 50px;
	}
	.ghost {
		background: lightblue;
	}
	.handle {
		cursor: grab;
	}
	.player-item.selected {
		background-color: rgba(0, 123, 255, 0.3); /* Add a background to indicate selection */
	}
	@media ${device.tablet} {
		flex-wrap: wrap;
		justify-content: center;
	}
	.editIcon {
		cursor: pointer;
		&:hover {
			opacity: 0.8;
		}
	}
	.ranking {
		&-setting {
			display: flex;
			gap: 32px;
		}
	}
	.flexColumn {
		display: flex;
		flex-direction: column;
		gap: 4px;
		input#numberPlayers {
			padding: 4px 16px;
			flex: 1 0 0;
			color: #49454f;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
			letter-spacing: 0.5px;
			border-radius: 4px;
			&:focus-visible {
				outline-offset: 0px;
				outline-color: #00438b;
			}
		}
		.switchMode {
			margin-top: 8px;
		}
	}
`;

export const ArrowIcon = styled.span`
	transform: ${props => (props.open ? 'rotate(180deg)' : 'rotate(0deg)')};
	transition: transform 0.3s ease;
	svg {
		width: 24px;
		height: 24px;
	}
`;

export const DropdownButton = styled.button`
	display: flex;
	height: 56px;
	justify-content: space-between;
	border-radius: 4px;
	border: 2px solid #00438b;
	background: #e7e9eb;
	padding: 2px 12px;
	justify-content: center;
	align-items: center;
	gap: 20px;
	align-self: stretch;
	color: #000;
	font-size: 36px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	cursor: pointer;
`;
export const Column = styled.div``;

export const RankPlayersChooseWrapper = styled.div`
	max-width: 590px;
	padding-left: 24px;
	border-left: 1px solid #e8ebef;
	ul {
		li {
			&:not(:last-child) {
				margin-bottom: 16px;
			}
		}
	}
	.numWrapper {
		padding-bottom: 40px;
		border-bottom: 1px solid #d4d4d4;
	}
	.searchPlayer {
		margin: 40px 0;
	}
	.paginationRank {
		margin-top: 25px;
		margin-bottom: 50px;
		ul li {
			padding: inherit;
			margin: inherit;
		}
		.MuiPaginationItem-page {
			font-weight: 400;
			font-size: 14px;
			line-height: 16px;
			color: rgb(14, 17, 24);
			padding: 6px 10px;
			border: 1px solid rgb(152, 158, 164);
			border-radius: 4px;
			margin-right: 6px;
			cursor: pointer;
			&.Mui-selected {
				border: 1px solid rgb(0, 67, 139);
				color: rgb(0, 67, 139);
			}
		}
	}
`;
