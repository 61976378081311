import { createSlice } from '@reduxjs/toolkit';
import { getPlayersForRankings, getRanking, saveRankingPosition, updateRankingPosition, updateRankingMode } from './rankingActions';
const initialState = {
	loading: false,
	error: null,
	rankId: null,
	message: '',
	rankingDataDB: [],
	rankedPlayers: [],
	players: [],
	maxPlayer: 50,
	myMaxPlayer: 50,
	// maxPlayer: 5,
	// myMaxPlayer: 5,
	mode: false,
	limit: 10,
	positionRanked: 'QB',
	positionChoose: 'QB',
	offset: 0,
	rankingInfo: null,
	currentRanking: null,
	currentRankingPageSize: null,
	currentRankingPage: 1,
	currentRankingPerPage: 10,
	maxGrade: null,
};



const rankingSlice = createSlice({
	name: 'ranking',
	initialState,
	reducers: {
		setCurrentRankingPage: (state, action) => {
			state.currentRanking = action.payload;
		},
		setCurrentPosition: (state, action) => {
			state.positionRanked = action.payload;
		},
		setPosition: (state, action) => {
			state.positionChoose = action.payload;
		},
		setRankedPlayers: (state, action) => {
			state.rankedPlayers = action.payload;
		},
		changeMode: state => {
			state.mode = !state.mode;
		},
		setMaxPlayer: (state, action) => {
			state.myMaxPlayer = action.payload;
		},
		//new
		setMaxGrade:(state, action)=>{
			state.maxGrade =action.payload
		},
		rankPlayer: (state, action) => {
			const { player, selectedPosition } = action.payload;
			const existingPositionGroup = state.rankedPlayers.find(group => group.name === selectedPosition);
			if (existingPositionGroup) {
				existingPositionGroup.players.push(player);
			} else {
				state.rankedPlayers.push({ name: selectedPosition, players: [player] });
			}
		},
		setRankedPlayersDrag: (state, action) => {
			const { players, selectedPosition } = action.payload;
			const existingPositionGroup = state.rankedPlayers.find(group => group.name === selectedPosition);
			existingPositionGroup.players = players;
		},


		addGrades: (state, action) => { //this one leave
			const { playerId, grade } = action.payload;
			const existingPositionGroup = state.rankedPlayers.find(group => group.name === state.positionRanked);
			if (existingPositionGroup) {
				const playerIndex = existingPositionGroup.players.findIndex(player => player.id === playerId);
				if (playerIndex !== -1) {
					existingPositionGroup.players[playerIndex].grade = grade;

					// if (state.maxGrade === null || grade.privilege > state.maxGrade.privilege){
					// 	state.maxGrade = {
					// 		indexPlayer: playerIndex,
					// 		privilege: grade.privilege,
					// 	};
					// }
				}
			}
		},
		// addGrades: (state, action) => { //this one leave
		// 	console.log(action.payload, 11111)
		// 	const { playerId, grade } = action.payload;
		// 	const existingPositionGroup = state.rankedPlayers.find(group => group.name === state.positionRanked);
		// 	if (existingPositionGroup) {
		// 		const playerIndex = existingPositionGroup.players.findIndex(player => player.id === playerId);
		// 		if (playerIndex !== -1) {
		// 			existingPositionGroup.players[playerIndex].grade = grade;
		//
		// 			if (state.maxGrade === null || grade.privilege > state.maxGrade.privilege){
		// 				state.maxGrade = {
		// 					indexPlayer: playerIndex,
		// 					privilege: grade.privilege,
		// 				};
		// 			}
		// 		}
		// 	}
		// },
		setRemoveAllRankingLocal: (state, action) => {
			const { selectedPosition } = action.payload;
			const existingPositionGroup = state.rankedPlayers.find(group => group.name === selectedPosition);
			existingPositionGroup.players = [];
		},
		setRemoveRankingPlayerLocal: (state, action) => {
			const { playerId, selectedPosition } = action.payload;
			const existingPositionGroup = state.rankedPlayers.find(group => group.name === selectedPosition);

			if (existingPositionGroup) {
				existingPositionGroup.players = existingPositionGroup.players.filter(player => player.id !== playerId);
			}
		},
	},
	extraReducers: {
		[getPlayersForRankings.pending]: state => {
			state.loading = true;
		},
		[getPlayersForRankings.fulfilled]: (state, action) => {
			state.loading = false;
			state.players = action?.payload?.results;
			state.limit = action?.payload?.limit;
			state.positionChoose = action?.payload?.position;
			state.currentRankingPageSize = action?.payload?.count;
		},
		[getPlayersForRankings.rejected]: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		[getRanking.pending]: state => {
			state.loading = true;
		},
		[getRanking.fulfilled]: (state, action) => {
			state.loading = false;
			state.maxPlayer = action.payload.max_limit;
			state.myMaxPlayer = action.payload.max_limit;
			//can be changed mode
			state.mode = action.payload.mode;
			state.rankedPlayers = action.payload.positions;
			state.rankId = action.payload.id;
			state.rankingDataDB = action.payload.positions;
		},
		[getRanking.rejected]: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		[saveRankingPosition.pending]: state => {
			state.loading = true;
		},
		[saveRankingPosition.fulfilled]: (state, action) => {
			state.loading = false;
		},
		[saveRankingPosition.rejected]: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		[updateRankingPosition.pending]: state => {
			state.loading = true;
		},
		[updateRankingPosition.fulfilled]: (state, action) => {
			state.loading = false;
			const existingPositionGroup = state.rankedPlayers.find(group => group.name === state.positionRanked);
			if (existingPositionGroup) {
				existingPositionGroup.players = action.payload.players;
			}
		},
		[updateRankingPosition.rejected]: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		[updateRankingMode.pending]:(state, action)  => {
			state.loading = true;
		},
		[updateRankingMode.fulfilled]: (state, action)  => {
			state.mode = action.payload;
			state.loading = false;
		},
		[updateRankingMode.rejected]: (state, action)  => {
			state.loading = false;
		},
	},
});

export const {
	setRemoveAllRankingLocal,
	setCurrentRankingPage,
	setPosition,
	setRankedPlayers,
	rankPlayer,
	setRankedPlayersDrag,
	setRemoveRankingPlayerLocal,
	changeMode,
	setCurrentPosition,
	setMaxPlayer,
	addGrades,
	setMaxGrade
} = rankingSlice.actions;

export const selectRanking = state => state.ranking;

export default rankingSlice.reducer;
