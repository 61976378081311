import * as yup from 'yup';

export const addPlayerSchema = yup.object().shape({
	players: yup.array().of(
		yup.object().shape({
			player: yup.string().required('Player name is required'),
			position: yup.string().required('Position is required'),
			school: yup.string().required('College is required'),
		})
	),
});
