import { Pagination, Switch } from '@mui/material';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import { IoMdSave } from 'react-icons/io';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { selectGroup } from '../../app/features/group/groupSlice';
import {
	getPlayersForRankings, getRanking, saveRankingPosition, updateRankingMode, updateRankingPosition
} from '../../app/features/ranking/rankingActions.js';
import { Link } from 'react-router-dom';
import {
	changeMode,
	rankPlayer,
	selectRanking,
	setCurrentPosition,
	setCurrentRankingPage,
	setMaxPlayer,
	setPosition,
	setRankedPlayersDrag,
	setRemoveAllRankingLocal,
	// setMaxGrade
} from '../../app/features/ranking/rankingSlice.js';
import {
	DraftPlayerItem, DraftPlayerItems, DraftPlayerWrapper, NumItem, NumWrapper,
} from '../../components/DraftPlayerChoose/DraftPlayerChoose.styles.jsx';
import Search from '../../components/Search/Search.jsx';
import Spinner from '../../components/Spinner/Spinner.jsx';
import Title from '../../components/Title/Title';
import { RANKING_GRAPHICS } from '../../router/route-path.jsx';
import { POSITIONS_COLOR, RANKIGS } from '../../utils/constants.js';
import {
	ArrowIcon,
	Column,
	DividerLine,
	DropdownButton,
	RankingsHeader,
	RankingsWrapper,
	RankPlayersChooseWrapper,
	RankPlayersWrapper,
	SubTitle,
	UserInfo,
	Wrapper,
} from './PlayerRanking.styles';
import Dropdown from './component/Dropdown/Dropdown';
import PlayerItemRanking from './component/PlayerItemRanking.jsx';

export const SelectPosition = memo(({ selectedOption, isOpen }) => {
	return (
		<DropdownButton>
			{typeof selectedOption === 'object' ? selectedOption.label : selectedOption}
			<ArrowIcon open={isOpen}>
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
					<path d="M6 9L12 15L18 9H6Z" fill="black" />
				</svg>
			</ArrowIcon>
		</DropdownButton>
	);
});

const PlayerRanking = () => {
	const groups = useSelector(selectGroup);
	const {
		players,
		rankingDataDB,
		currentRankingPageSize,
		currentRankingPerPage,
		currentRankingPage,
		loading,
		rankedPlayers,
		maxPlayer,
		myMaxPlayer,
		mode,
		positionRanked,
		maxGrade,
		positionChoose,
		rankId
	} = useSelector(selectRanking);

	const dispatch = useDispatch();
	const initial = useRef(true);
	const [searchValue, setSearchValue] = useState('');
	const [selectedPlayerIds, setSelectedPlayerIds] = useState([]);
	const [page, setPage] = useState(currentRankingPage);

	const positions = useMemo(() => groups?.positions?.slice(1, -1), [groups.positions]);
	const positionGroup = useMemo(
		() => rankedPlayers?.find(group => group.name === positionRanked)?.players,
		[rankedPlayers, positionRanked]
	);
	const positionData = useMemo(
		() => rankedPlayers?.find(group => group.name === positionRanked),
		[rankedPlayers, positionRanked]
	);

	const [selectedRanks, setSelectedRanks] = useState(Array(positionGroup?.length).fill(null));

	useEffect(() => {
		dispatch(getPlayersForRankings({}));
		dispatch(getRanking());
		//new
		// dispatch(setMaxGrade(null))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (initial.current) {
			initial.current = false;
			return;
		}
		const timer = setTimeout(() => {
			dispatch(getPlayersForRankings({ setSearch: searchValue }));
		}, 500);
		return () => clearTimeout(timer);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setSearchValue, searchValue]);

	const handleRankPlayer = useCallback(
		player => {
			if (player.position !== positionRanked) {
				toast.warning(
					`Warning: Player position (${player.position}) doesn't match selected position (${positionRanked}).`
				);
			} else if (positionGroup?.length >= myMaxPlayer) {
				toast.warning(`Warning: Max Players (${myMaxPlayer})`);
			} else if (positionGroup?.length >= maxPlayer) {
				toast.warning(`Warning: Max Players (${maxPlayer})`);
			} else {
				dispatch(rankPlayer({ player, selectedPosition: positionRanked }));
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[positionRanked, positionGroup, myMaxPlayer, maxPlayer]
	);

	const handleOnClickSave = useCallback(
		async name => {
			const checkBackendData = rankingDataDB.find(group => group.name === name);
			if (!checkBackendData) {
				await dispatch(saveRankingPosition({ name: positionRanked, players: positionGroup }));
				await dispatch(getRanking());
			} else {
				await dispatch(updateRankingPosition({ checkDBSave: positionData }));
				await dispatch(getRanking());
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[positionRanked, positionGroup, positionData, rankingDataDB]
	);

	const handleRemoveAll = useCallback(
		async name => {
			const checkBackendData = rankingDataDB.find(group => group.name === name);
			if (!checkBackendData) {
				await dispatch(setRemoveAllRankingLocal({ selectedPosition: name }));
			} else {
				await dispatch(updateRankingPosition({}));
				await dispatch(getRanking());
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[rankingDataDB]
	);

	const toggleSelection = useCallback(
		playerId => {
			if (!mode) {
				setSelectedPlayerIds(prevSelectedPlayerIds =>
					prevSelectedPlayerIds.includes(playerId)
						? prevSelectedPlayerIds.filter(id => id !== playerId)
						: [...prevSelectedPlayerIds, playerId]
				);
			}
		},
		[mode]
	);

	const handleOnDragEnd = useCallback(
		result => {
			const { destination, source, draggableId } = result;
			if (!destination) return;

			const isDraggingSelected = selectedPlayerIds.includes(Number(draggableId));
			let newPlayerState = Array.from(positionGroup);

			if (isDraggingSelected) {
				const selectedPlayers = positionGroup.filter(player => selectedPlayerIds.includes(player.id));
				const unselectedPlayers = positionGroup.filter(player => !selectedPlayerIds.includes(player.id));

				const insertionIndex = destination.index;
				const beforeInsert = unselectedPlayers.slice(0, insertionIndex);
				const afterInsert = unselectedPlayers.slice(insertionIndex);

				newPlayerState = [...beforeInsert, ...selectedPlayers, ...afterInsert];
			} else {
				const [movedPlayer] = newPlayerState.splice(source.index, 1);
				newPlayerState.splice(destination.index, 0, movedPlayer);
			}

			dispatch(setRankedPlayersDrag({ players: newPlayerState, selectedPosition: positionRanked }));
			setSelectedPlayerIds([]);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[positionGroup, selectedPlayerIds, positionRanked]
	);

	const handlePageChange = async (event, value) => {
		await setPage(value);
		await dispatch(setCurrentRankingPage(value));
		await dispatch(getPlayersForRankings({ setLimit: currentRankingPageSize }));
	};

	const handleRankSelect = useCallback((index, value) => {
		setSelectedRanks(prev => {
			const newSelectedRanks = [...prev];
			newSelectedRanks[index] = value;
			return newSelectedRanks;
		});
	}, []);

	const currentPlayers = useMemo(
		() => players.slice((page - 1) * currentRankingPerPage, page * currentRankingPerPage),
		[page, currentRankingPerPage, players]
	);

	return (
		<Wrapper className="main-container">
			 <RankingsWrapper>
				<RankingsHeader>
					<Title titleText="Ranking" titleClassName="rankingTitle" />
					<SubTitle>
						<p>This section allows a user to rank players and generate a graphic of the rankings.
							Optionally add tiers to rankings to show where your grades suggest the players are valued in the draft.</p>
					</SubTitle>
					<UserInfo>
						<p>
							<span>ID - </span>123456789
						</p>
					</UserInfo>
					<DividerLine $mt="24px" $mb="24px" />
				</RankingsHeader>
				<RankPlayersWrapper>
					<Column style={{ width: '55%' }}>
						<div className="ranking-setting">
							<div className="ranking-position flexColumn">
								<span>Position</span>
								<Dropdown
									button={<SelectPosition selectedOption={positionRanked} />}
									data={positions}
									title="Choose a position to rank"
									colors={POSITIONS_COLOR}
									defaultSelection={positionRanked}
									onSelect={value => dispatch(setCurrentPosition(value))}
								/>
							</div>
							<div className="flexColumn">
								{/*<span>Number of players</span>*/}
								<span>Maximum number of players</span>
								<input
									type="number"
									disabled={true}
									name="numberPlayers"
									id="numberPlayers"
									aria-label="number-player-count"
									placeholder={maxPlayer}
									max={maxPlayer}
									min={1}
									onChange={e => {
										dispatch(setMaxPlayer(+e.target.value));
									}}
									// style={{
									// 	cursor: 'none',
									// 	pointerEvents: 'none',
									// }}
								/>
							</div>
							<div className="flexColumn">
								<span>Switch to tier mode</span>
								<Switch checked={mode} className="switchMode"
										// onChange={() => dispatch(changeMode())}
										onChange={() => dispatch(updateRankingMode())}
								/>
							</div>
						</div>
						<div className="ranking-players">
							<DragDropContext onDragEnd={handleOnDragEnd}>
								<Droppable droppableId="players">
									{(provided, snapshot) => {
										const isDragging = snapshot.isDraggingOver;
										return (
											<div {...provided.droppableProps} ref={provided.innerRef} className="ranking-players">
												{positionGroup?.map((player, index) => {
													const currentRank = selectedRanks[index];
													const currentPrivilege = currentRank
														? RANKIGS.find(r => r.value === currentRank)?.privilege
														: -1;
													return (
														<Draggable key={player.id.toString()} draggableId={player.id.toString()} index={index}>
															{provided => {
																return (
																	<div
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		className={`player-item ${selectedPlayerIds.includes(player.id) ? 'selected' : ''}`}
																		onClick={() => toggleSelection(player.id)}
																	>
																		<PlayerItemRanking
																			player={player}
																			index={index + 1}
																			dragHandleProps={provided.dragHandleProps}
																			mode={mode}
																			onSelect={value => handleRankSelect(index, value)}
																			selectCount={selectedPlayerIds.length}
																			isDragging={isDragging}
																			currentPrivilege={currentPrivilege}
																			selectedRanks={selectedRanks}
																			maxGrade={maxGrade}
																			rankingDataDB={rankingDataDB}
																		/>
																	</div>
																);
															}}
														</Draggable>
													);
												})}
												{provided.placeholder}
											</div>
										);
									}}
								</Droppable>
							</DragDropContext>
						</div>
						{positionGroup?.length > 0 && (
							<div className="ranking-action">
								<button className="remove"
										onClick={() => handleRemoveAll(positionRanked)}
								>
									<RiDeleteBin6Line size={24} />
									Remove all players
								</button>
								{/*{!mode &&*/}
								{/*	<button*/}
								{/*		// className={`${mode && positionGroup.length !== maxPlayer ? 'save disable' : 'save'}`}*/}
								{/*		className={'save'}*/}
								{/*		onClick={() => {*/}
								{/*			handleOnClickSave(positionRanked);*/}
								{/*		}}*/}
								{/*	>*/}
								{/*		<IoMdSave size={24}/>*/}
								{/*		Save*/}
								{/*	</button>*/}
								{/*}*/}
								<button
									// className={`${mode && positionGroup.length !== maxPlayer ? 'save disable' : 'save'}`} //E
									// className={`${positionGroup.length !== maxPlayer ? 'save disable' : 'save'}`} //check this one
									className={'save'}
									onClick={() => {
										handleOnClickSave(positionRanked);
									}}
								>
									<IoMdSave size={24} />
									Save
								</button>
								<Link
									to={RANKING_GRAPHICS} 
									state={{ mode: mode, data: positionData }}>
									Generate Graphic
								</Link>
							</div>
						)}
					</Column>
					<Column style={{ width: '45%' }}>
						<RankPlayersChooseWrapper>
							<h3>Choose position</h3>
							<NumWrapper className="numWrapper">
								{groups?.positions &&
									positions.map((item, idx) => {
										const id = idx + 1;
										return (
											<NumItem
												key={id}
												backColor={POSITIONS_COLOR[item.split(' ')[0]]}
												onClick={() => {
													dispatch(setCurrentRankingPage(1));
													dispatch(setPosition(item.split(' ')[0]));
													dispatch(getPlayersForRankings({ setPosition: item.split(' ')[0] }));
												}}
												className={
													positionChoose.toLowerCase().trim() === item.split(' ')[0].toLowerCase().trim()
														? 'active'
														: null
												}
											>
												<span>{item.split(' ')[0]}</span>
											</NumItem>
										);
									})}
							</NumWrapper>
							<Search
								className="searchPlayer"
								value={searchValue}
								handleChange={e => {
									setSearchValue(e.target.value);
								}}
							/>
							<DraftPlayerWrapper>
								<DraftPlayerItems>
									{loading ? (
										<Spinner />
									) : (
										<>
											{currentPlayers.length > 0 &&
												currentPlayers.map((item, idx) => {
													const isPlayerRanked = positionGroup?.some(player => player.id === item.id);
													return (
														<DraftPlayerItem
															$disabled={isPlayerRanked}
															key={idx}
															backColor={POSITIONS_COLOR[item.position]}
														>
															<div className="player-draft">
																<div className="player-td player-rank">
																	<p>Rank</p>
																	<span>{item?.ranking}</span>
																</div>
																<div className="player-td player-rank">
																	<p>BPA</p>
																	<span>{item?.bpa ? item.bpa : item?.ranking}</span>
																</div>
																<div className="player-td player-adp">
																	<p>School</p>
																	<span>{item.school}</span>
																	<span>{item?.adp}</span>
																</div>
																<h4 className="player-td player-name">{item.player}</h4>
																<h4 className="player-td player-position">{item.position}</h4>
																<button
																	className="player-td player-draft-btn"
																	onClick={() => handleRankPlayer(item, positionRanked)}
																>
																	Rank
																</button>
															</div>
														</DraftPlayerItem>
													);
												})}
											<Pagination
												count={Math.ceil(currentRankingPageSize / currentRankingPerPage)}
												page={page}
												previous={null}
												next={null}
												onChange={handlePageChange}
												className="paginationRank"
											/>
										</>
									)}
								</DraftPlayerItems>
							</DraftPlayerWrapper>
						</RankPlayersChooseWrapper>
					</Column>
				</RankPlayersWrapper>
			</RankingsWrapper>
		</Wrapper>
	);
};

export default PlayerRanking;
