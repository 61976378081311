import styled from 'styled-components';

export const MainContainer = styled.div`
	overflow: hidden;
	width: 100%;
	max-width: 1000px;
	margin: 0 auto;
	margin-top: 20px;
`;

export const PlayerCard = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 0px;
	padding-left: 20px;
	margin: 12px 0;
	background: #ffffff;
	border-radius: 6px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	flex-wrap: nowrap;
`;

export const PlayerInfo = styled.div`
	flex: 1;
	min-width: 100px;
	padding: 8px;
	&.college {
		max-width: 300px;
	}
	&.position {
		max-width: 120px;
	}
`;

export const PlayerActions = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 105px;
	max-width: 140px;
	width: 100%;
`;

export const PlayerName = styled.span`
	font-family: 'Saira Semi Condensed', sans-serif;
	color: #022142;
	font-size: 28px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

export const PlayerPosition = styled.span`
	font-family: 'Saira Semi Condensed', sans-serif;
	color: #022142;
	font-size: 28px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

export const PlayerCollege = styled.span`
	font-family: 'Saira Semi Condensed', sans-serif;
	color: #022142;
	font-size: 28px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

export const ActionButton = styled.button`
	cursor: pointer;
	background: none;
	height: 100%;
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 8px;
	padding: 8px;
	font-weight: 500;
	border: none;
	&:hover {
		background: rgba(0, 0, 0, 0.1);
	}

	&.delete {
		color: #e74c3c;
		background: rgba(231, 76, 60, 0.2);
	}

	&.edit {
		color: #022142;
		background: rgba(2, 33, 66, 0.2);
		border-radius: 0 6px 6px 0;
	}
	&.save {
		color: green;
		background: rgba(2, 66, 12, 0.2);
		border-radius: 0 6px 6px 0;
	}
`;

export const ActionText = styled.span`
	font-family: 'Saira Semi Condensed', sans-serif;
	font-size: 16px;
	color: inherit;
`;

export const Label = styled.div`
	font-family: 'Saira Semi Condensed', sans-serif;
	font-size: 16px;
	color: #46484a;
	margin-bottom: 4px;
`;

export const Pagination = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
`;

export const PageNumber = styled.div`
	cursor: pointer;
	margin: 0 5px;
	padding: 10px 15px;
	border: 1px solid #ddd;
	border-radius: 5px;
	&:hover {
		background-color: #f0f0f0;
	}

	&.active {
		background-color: #022142;
		color: white;
		font-weight: bold;
	}
`;
