import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PLAYER_MAX } from '../../../config/config';

const initialState = {
	botsData: {},
	loadingBots: false,
	status: false,
	//error: null,
};

export const getTeamListBots = createAsyncThunk(
	'teamListBots/getTeamListBots',
	async (ordName = '', { dispatch, getState, rejectWithValue }) => {
		try {
			const { botsData } = getState().teamListBots;
			const existingBotData = botsData[ordName] || { playerIds: [] };
			// const excludeIdsParam = existingBotData.playerIds.map(id => `exclude_ids=${id}`).join('&')
			// let teamName = ordName === '49ers' ? 'ers' : ordName
			// const res = await axios.get(
			// 	`${API_ENDPOINT}players/?${excludeIdsParam}&limit=${PLAYER_MAX}&offset=${0}&search=&position=&school&ordering=-${teamName}`
			// )
			// const resData = { ...res.data, playerIds: existingBotData.playerIds }
			const resData = { playerIds: existingBotData.playerIds };
			dispatch(setTeamListBots({ bot_name: ordName, bot_data: resData }));
			return { bot_name: ordName, bot_data: resData };
		} catch (error) {
			if (error.response && error.response.data.message) {
				return rejectWithValue(error.response.data.message);
			} else {
				return rejectWithValue(error.message);
			}
		}
	}
);

export const draftBotTeamPlayer = createAsyncThunk(
	'teamListBots/draftBotTeamPlayer',
	async ({ ordName, setPlayerId }, { dispatch, getState, rejectWithValue }) => {
		try {
			// let teamName = ordName === '49ers' ? 'ers' : ordName
			const { botsData } = getState().teamListBots;
			const existingBotData = botsData[ordName] || { playerIds: [] };
			const updatedPlayerIds = [...existingBotData.playerIds, setPlayerId];
			// const excludeIdsParam = updatedPlayerIds.map(id => `exclude_ids=${id}`).join('&')
			// const res = await axios.get(
			// 	`${API_ENDPOINT}players/?${excludeIdsParam}&limit=${PLAYER_MAX}&offset=${0}&ordering=-${teamName}`
			// )
			// const resData = { ...res.data, playerIds: updatedPlayerIds }
			const resData = { playerIds: updatedPlayerIds };
			dispatch(setTeamListBots({ bot_name: ordName, bot_data: resData }));
			return { bot_name: ordName, bot_data: resData };
		} catch (error) {
			if (error.response && error.response.data.message) {
				return rejectWithValue(error.response.data.message);
			} else {
				return rejectWithValue(error.message);
			}
		}
	}
);

export const teamListBotsSlice = createSlice({
	name: 'teamListBots',
	initialState,
	reducers: {
		setTeamListBots: (state, action) => {
			const { bot_name, bot_data } = action.payload;
			state.botsData[bot_name] = {
				...state.botsData[bot_name],
				bot_name: bot_name || state.botsData[bot_name]?.bot_name || '',
				limit: bot_data?.limit || state.botsData[bot_name]?.limit || PLAYER_MAX,
				count: bot_data?.count || state.botsData[bot_name]?.count || 0,
				playerIds: bot_data?.playerIds || state.botsData[bot_name]?.playerIds || [],
				results: bot_data?.results || state.botsData[bot_name]?.results || {},
				status: true,
				// currentPage: bot_data?.currentPage || state.botsData[bot_name]?.currentPage || 1,
				// pageSize: bot_data?.pageSize || state.botsData[bot_name]?.pageSize || 6,
				// next: bot_data?.next,
				// previous: bot_data?.previous,
				// search: bot_data?.search || state.botsData[bot_name]?.search || '',
				// position: bot_data?.position || state.botsData[bot_name]?.position || '',
				// colleage: bot_data?.colleage || state.botsData[bot_name]?.colleage || '',
				// offset: bot_data?.offset || state.botsData[bot_name]?.offset || 0,
				// teamsPlayersData: bot_data?.teamsPlayersData || state.botsData[bot_name]?.teamsPlayersData || [],
			};
		},
	},
	extraReducers: {
		[getTeamListBots.fulfilled]: (state, action) => {
			state.loadingBots = false;
			// state.teamNeeds = action.payload?.results  //check
			// state.results = action.payload?.results    //check
		},
		[getTeamListBots.pending]: (state, action) => {
			state.loadingBots = true;
		},
		[getTeamListBots.rejected]: (state, action) => {
			state.loadingBots = false;
		},
		[draftBotTeamPlayer.fulfilled]: (state, action) => {
			state.loadingBots = false;
			// state.results = action.payload?.results //check
		},
		[draftBotTeamPlayer.pending]: (state, action) => {
			state.loadingBots = true;
		},
		[draftBotTeamPlayer.rejected]: (state, action) => {
			state.loadingBots = false;
		},
	},
});

export const selectTeamListBots = state => state.teamListBots;

export const { setTeamListBots } = teamListBotsSlice.actions;

export default teamListBotsSlice.reducer;
