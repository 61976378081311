import { createSlice } from '@reduxjs/toolkit';
import {getDraftTotalCount, postDraftCountForTeam} from './draftCountActions';


const initialState = {
    loading: false,
    error: null,
    totalCount: null,
    draftedCount:null,
    teamDraftResult:{}
}


const draftCountSlice = createSlice({
    name: 'draftCount',
    initialState,
    reducers: {
        setDraftedCount:(state, action) => {
            console.log(action.payload)
            // state.draftedCount = action.payload;
        },
    },
    extraReducers: {
        [getDraftTotalCount.pending]: state => {
            state.loading = true;
        },
        [getDraftTotalCount.fulfilled]: (state, action) => {
            state.loading = false;
            state.totalCount = action?.payload?.count;
        },
        [getDraftTotalCount.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [postDraftCountForTeam.pending]: state => {
            state.loading = true;
        },
        [postDraftCountForTeam.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = false;
            state.teamDraftResult=action.payload
            // state.totalCount = action?.payload?.count;
        },
        [postDraftCountForTeam.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    }
})


export const selectDraftCount = state => state.draftCount;
export const {setDraftedCount} = draftCountSlice.actions;

export default draftCountSlice.reducer;