import React from 'react';
import { useDrag } from 'react-dnd';
import { DraggableDiv } from './DraggableComponent.styles';

// const DraggableComponent = ({ playerName, playerCollege, id, sourceTeamIndex  }) => { //before opt
const DraggableComponent = React.memo(({ playerName, playerCollege, id, sourceTeamIndex }) => {
	const [{ isDragging }, drag] = useDrag({
		type: 'ITEM',
		item: { playerName, id, sourceTeamIndex },
		collect: monitor => ({
			isDragging: !!monitor.isDragging(),
		}),
	});

	return (
		<DraggableDiv ref={drag} style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }}>
			{playerName && <div className={'playerName'}>{playerName}</div>}
			{playerCollege && <div className={'playerCollege'}>{playerCollege}</div>}
		</DraggableDiv>
	);
	// };
});

export default DraggableComponent;
