import styled from 'styled-components';

export const DraftConfigWrap = styled.section`
	margin-top: 25px;
	margin-bottom: 200px;
	.line {
		margin-top: 0px;
	}
`;
export const DraftContainer = styled.div``;
export const DraftHeading = styled.div`
	margin-bottom: 23px;
	display: flex;
	flex-direction: column;
	align-items: center;
	.title {
		color: #000000;
		font-size: 36px;
		font-family: 'Saira Semi Condensed', sans-serif;
		font-weight: 400;
		margin-bottom: 0;
	}
	p {
		color: #3e464f;
		font-size: 20px;
		font-family: 'Saira Semi Condensed', sans-serif;
		font-weight: 400;
	}
`;

export const DraftSettings = styled.div`
	border-radius: 12px;
	background: #fff;
	padding: 40px 24px;
	display: flex;
`;

export const DraftInputs = styled.div`
	height: 100%;
	padding-top: 40px;
	padding-right: 36px;
	border-right: 1px solid #e8ebef;
	max-width: 416px;
	width: 100%;
	.draft-input-div {
		max-width: 416px;
		width: 100%;
		padding-bottom: 24px;
		border-bottom: 1px solid #e8ebef;
		display: flex;
		flex-direction: column;
		margin-bottom: 24px;
	}
	.draft-label-name {
		color: #000;
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		line-height: 30px;
		font-family: 'Saira Semi Condensed', sans-serif;
		text-transform: capitalize;
		margin-bottom: 16px;
	}
	.draft-label-title {
		color: #000;
		font-family: 'Saira Semi Condensed', sans-serif;
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		line-height: 30px;
		text-transform: capitalize;
		margin-bottom: 24px;
	}
	.draft-label {
		color: #000;
		font-family: 'Saira Semi Condensed', sans-serif;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 30px;
		text-transform: capitalize;
		margin-bottom: 8px;
	}
	input {
		padding: 9px 12px;
		width: 100%;
		color: #000;
		font-family: 'Saira Semi Condensed', sans-serif;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		border-radius: 3px;
		border: 1px solid #e8ebef;
	}
	input::placeholder {
		color: #000;
		font-family: 'Saira Semi Condensed', sans-serif;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
	}
`;

export const DraftPositions = styled.div`
	height: 100%;
	padding-left: 22px;
	padding-right: 36px;
	border-right: 1px solid #e8ebef;
	.row-position {
		display: flex;
		align-items: center;
		gap: 6px;
		margin-bottom: 20px;
	}
	.position-name {
		color: #000;
		font-family: 'Saira Semi Condensed', sans-serif;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 30px;
		text-transform: capitalize;
		white-space: nowrap;
	}
`;
export const ColorDiv = styled.div`
	border-radius: 1px;
	width: 6px;
	height: 36px;
	background-color: ${props => props.color || 'defaultColor'};
`;
export const DraftBots = styled.div`
	display: flex;
	flex-direction: column;
	padding-left: 36px;

	.bot-section {
		max-width: 230px;
		width: 100%;
	}
	.bot-section:first-child {
		max-width: 800px;
		width: 100%;
	}
	.bots {
		display: flex;
		flex-wrap: wrap;
		gap: 18px;
		width: 100%;
	}
	.bot-row {
		display: flex;
		gap: 18px;
	}
	.bot-part {
		margin-bottom: 16px;
		display: flex;
		align-items: center;
		gap: 8px;
	}
	.bot-part input {
		width: 100%;
		height: 20px;
		max-width: 20px;
	}
	.label {
		color: #000;
		font-family: 'Saira Semi Condensed', sans-serif;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
		text-transform: capitalize;
		white-space: nowrap;
	}
	.slider-box {
		width: 100%;
		margin-left: 10px;
	}
	.main-label {
		white-space: nowrap;
		color: #000;
		font-family: 'Saira Semi Condensed', sans-serif;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 30px;
		text-transform: capitalize;
	}
	.m-btn {
		margin-bottom: 40px;
	}
	a,
	button {
		display: flex;
		align-items: center;
		gap: 2px;
		cursor: pointer;
		padding: 5.5px 24px;
		background-color: #004ea3;
		font-family: 'Saira Semi Condensed', sans-serif;
		color: #ffffff;
		text-decoration: none;
		font-weight: 400;
		font-size: 20px;
		line-height: 31px;
		border-radius: 3px;
		opacity: 0.9;
		transition: all 0.3s ease;
		margin-top: 40px;
		width: fit-content;
		align-self: flex-end;
		margin-right: 40px;
		:hover {
			opacity: 1;
		}
	}
`;

export const DraftNamesContainer = styled.div`
	border-radius: 12px;
	background: #fff;
	padding: 40px 24px;
	display: flex;
	flex-direction: column;
	a,
	button {
		display: flex;
		align-items: center;
		gap: 2px;
		cursor: pointer;
		padding: 5.5px 24px;
		background-color: #004ea3;
		font-family: 'Saira Semi Condensed', sans-serif;
		color: #ffffff;
		text-decoration: none;
		font-weight: 400;
		font-size: 20px;
		line-height: 31px;
		border-radius: 3px;
		opacity: 0.9;
		transition: all 0.3s ease;
		width: fit-content;
		align-self: flex-end;
		margin-right: 40px;
		:hover {
			opacity: 1;
		}
		:disabled {
			cursor: not-allowed;
		}
	}
	.title {
		color: #000;
		font-family: 'Saira Semi Condensed', sans-serif;
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		line-height: 30px;
		margin-bottom: 16px;
	}
`;
export const DraftNames = styled.div`
	display: flex;
	width: 100%;
	gap: 24px;
	margin-bottom: 80px;
	overflow-x: auto;
	.name-input-div {
		max-width: 342px;
		width: 100%;
		min-width: 220px;
		padding-right: 24px;
		display: flex;
		flex-direction: column;
		border-right: 1px solid #e8ebef;
	}
	.name-input-div:last-child {
		border-right: none;
	}
	.name-label {
		color: #000;
		font-family: 'Saira Semi Condensed', sans-serif;
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		line-height: 30px;
		margin-bottom: 16px;
	}
	input {
		padding: 9px 12px;
		width: 100%;
		color: #000;
		font-family: 'Saira Semi Condensed', sans-serif;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		border-radius: 3px;
		border: 1px solid #e8ebef;
	}
	input::placeholder {
		color: #000;
		font-family: 'Saira Semi Condensed', sans-serif;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
	}
	button.disabled {
		cursor: not-allowed;
	}
	.botName {
		color: #000;
		font-family: 'Saira Semi Condensed', sans-serif;
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		line-height: 30px;
		margin-bottom: 16px;
		max-width: 342px;
		width: 100%;
		min-width: 220px;
		padding-right: 24px;
		border-right: 1px solid #e8ebef;
	}
`;
