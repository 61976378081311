import styled from 'styled-components';

export const DroppableDiv = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: auto;
	width: 100%;
	position: relative;
	.playerName {
		color: #00438b;
		font-family: 'Saira Semi Condensed', sans-serif;
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 30px;
	}
	.playerCollege {
		color: #0e1118;
		font-family: 'Saira Semi Condensed', sans-serif;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
`;
