import React, { useRef } from 'react';
import { DroppableDiv } from './DroppableComponent.styles';
import { useDrop } from 'react-dnd';
import DraggableComponent from '../DraggableComponent/DraggableComponent';

// function DroppableComponent({ item, moveItem}) {// before opt
const DroppableComponent = React.memo(({ item, moveItem }) => {
	const dropRef = useRef(null);
	const [{ canDrop, isOver }, drop] = useDrop({
		accept: 'ITEM',
		drop: draggedItem => {
			moveItem(draggedItem.id, dropRef.current);
		},
		collect: monitor => ({
			isOver: !!monitor.isOver(),
			canDrop: !!monitor.canDrop(),
		}),
	});

	return (
		<DroppableDiv
			className={`box-up ${isOver && canDrop ? 'drop-hover' : ''}`}
			ref={node => {
				drop(node);
				dropRef.current = node;
			}}
		>
			<div className="position" data-item={item.position.map_name}>
				{item.position.name}
			</div>
			<DraggableComponent playerName={item.playerName} playerCollege={item.playerCollege} id={item.playerId} />
		</DroppableDiv>
	);
	// };
});

export default DroppableComponent;
