import React, { cloneElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NumItem } from '../../../../components/DraftPlayerChoose/DraftPlayerChoose.styles';
import { DropdownContainer, DropdownContent, RadioButton, RadioLabel } from './Dropdown.styles';
import {useSelector} from "react-redux";
import {selectRanking} from "../../../../app/features/ranking/rankingSlice";
import {toast} from "react-toastify";

const Dropdown = React.memo(
	({
		button,
		data,
		title,
		colors,
		defaultSelection,
		onSelect,
		rankings = false,
		maxGrade,
		playerIndex,
		 playerGradeValue,
		gradeStatus,
		 onSetNewMaxGrade
	}) => {
		const [selectedOption, setSelectedOption] = useState(defaultSelection || data[0]?.value || data[0]);
		const [isOpen, setIsOpen] = useState(false);
		const [openDropdownForGrade, setOpenDropdownForGrade]= useState(false);

		const dropdownRef = useRef(null);
		const toggleDropdown = useCallback(() => setIsOpen(prevIsOpen => !prevIsOpen), []);
		const {rankedPlayers, positionRanked} = useSelector(selectRanking);


		useEffect(() => {
			if (isOpen) {
				const existingPositionGroup = rankedPlayers?.find(group => group.name === positionRanked);
				if (existingPositionGroup) {
					const playerIndex = existingPositionGroup.players?.findIndex(one => one.id === gradeStatus?.id);
					if (playerIndex !== -1) {
						let errorShown = false;
						if (playerIndex !== 0) {
							for (let i = 0; i < playerIndex; i++) {
								if (!existingPositionGroup?.players[i]?.grade && !errorShown) {
									setOpenDropdownForGrade(false);
									toast.error('The tier mode should be changed sequentially!!!');
									errorShown = true;
									break;
								}
							}
						}
						if (playerIndex === 0 || !errorShown) {
								setOpenDropdownForGrade(true);
								// const playersWithGrades = existingPositionGroup?.players?.filter(item =>
								// 	typeof item.grade === 'object' && item.grade !== null
								// );
								let maxPrivilegePlayer = null;
								for (let i = 0; i < playerIndex; i++) {
									const currentPlayer = existingPositionGroup.players[i];
									if (currentPlayer.grade && currentPlayer.grade.privilege > (maxPrivilegePlayer?.privilege || -Infinity)) {
										maxPrivilegePlayer = {
											indexPlayer: i,
											privilege: currentPlayer.grade.privilege,
										};
									}
								}
								if (maxPrivilegePlayer) {
									onSetNewMaxGrade({
										indexPlayer: maxPrivilegePlayer.indexPlayer,
										privilege: maxPrivilegePlayer.privilege,
									});
								}
							}
						}
					}
				}

		// }, [isOpen, rankedPlayers, positionRanked, gradeStatus, onSetNewMaxGrade]);
		}, [isOpen]);







		//end
		const handleOptionChange = useCallback((event, item) => {
			const selectedValue = item;
			setSelectedOption(selectedValue);
			onSelect(selectedValue);
			setIsOpen(false);
		}, []);

		const memoizedButton = useMemo(
			() => cloneElement(button, { selectedOption, isOpen }),
			[button, selectedOption, isOpen]
		);

		useEffect(() => {
			const handleClickOutside = event => {
				if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
					setIsOpen(false);
				}
			};
			document.addEventListener('mousedown', handleClickOutside);
			return () => {
				document.removeEventListener('mousedown', handleClickOutside);
			};
		}, [dropdownRef]);

		return (
			<DropdownContainer ref={dropdownRef}>
				<div onClick={toggleDropdown}>{memoizedButton}</div>

				<DropdownContent  open={title === "Apply round grades to rankings" ? (isOpen && openDropdownForGrade) :isOpen}>
					<h4 className="title">{title}</h4>
					{data.map(item => {
						let disabled = false;
						if (rankings) {
							disabled = maxGrade?.indexPlayer < playerIndex - 1 && maxGrade.privilege > item.privilege ? true : false;
						}

						return (
							<RadioLabel
								key={typeof item === 'object' ? item.value : item}
								className={`${disabled ? 'disabled' : ''}`}
							>
								<RadioButton
									type="radio"
									name="dropdown"
									value={typeof item === 'object' ? item.value : item}
									// checked={
									// 	typeof item === 'object'
									// 		? selectedOption.value === (typeof item === 'object' ? item.value : item)
									// 		: selectedOption === (typeof item === 'object' ? item.value : item)
									// } //before
									checked={
										playerGradeValue
											? playerGradeValue === item.value
											: typeof item === 'object'
											? selectedOption?.value === item.value //today
											: selectedOption === item
									}
									onChange={e => handleOptionChange(e, item)}
									disabled={disabled}
								/>
								<NumItem backColor={typeof item === 'object' ? item.color : colors[item]}>
									{typeof item === 'object' ? item.label : item}
								</NumItem>
							</RadioLabel>
						);
					})}
				</DropdownContent>
			</DropdownContainer>
		);
	}
);

export default Dropdown;
