import styled from 'styled-components';

export const AddPLayerWrapper = styled.div``;

export const AddPLayerModal = styled.div`
	width: 100%;
	max-width: 710px;
	margin: 0 auto;
`;

export const AddPLayerModalWrapper = styled.div`
	max-width: 950px;
	width: 100%;
	border-radius: 10px;
	background: #fff;
	padding: 32px;
	text-align: left;
	position: relative;
	max-height: 600px;
	overflow-y: auto;

	.closeModal {
		position: absolute;
		top: 27px;
		right: 30px;
		font-size: 18px;
		color: rgb(14, 17, 24);
		font-family: 'Saira Semi Condensed';
		font-weight: 700;
		cursor: pointer;
	}
`;

export const AddPlayerTitle = styled.h2`
	font-size: 36px;
	font-weight: 500;
	color: #0e1118;
	font-family: 'Saira Semi Condensed';
	line-height: 41.6px;
	margin-bottom: 20px;
`;

export const AddPLayerForm = styled.div`
	width: 100%;
	margin-top: 20px;
`;

export const InputWrap = styled.div`
	width: 100%;
	margin-bottom: 20px;

	input {
		font-family: 'Saira Semi Condensed', sans-serif;
		font-weight: 400;
		width: 100%;
		padding: 9px 16px;
		background: #ffffff;
		border: 1px solid #afb4b9;
		border-radius: 4px;
		font-size: 16px;
		line-height: 27px;
		min-height: 56px;

		::placeholder {
			color: #3e464f;
			font-weight: 400;
			font-style: normal;
			font-size: 16px;
			line-height: 27px;
		}

		:focus-visible {
			outline-color: #1976d2;
		}
	}
`;

export const AddPlayerBox = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	position: relative;
`;

export const RemoveButton = styled.div`
	position: absolute;
	top: -10px;
	right: -40px;
	cursor: pointer;
	color: #ff1717;
	font-size: 20px;
`;

export const SelectWrapContainer = styled.div`
	display: flex;
	gap: 20px;
	margin-bottom: 20px;
`;

export const SelectWrap = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 5px;
`;

export const StyledButton = styled.button`
	background-color: #004ea3;
	color: white;
	border: none;
	padding: 10px 20px;
	border-radius: 4px;
	cursor: pointer;
	font-size: 16px;
	font-family: 'Saira Semi Condensed', sans-serif;
	font-weight: 500;
	height: 46px;
	&:hover {
		background-color: #115293;
	}

	&:not(:last-child) {
		margin-right: 10px;
	}
	&.svBtn {
		margin-top: 60px;
	}
`;

export const ButtonsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
`;
