import { Switch } from '@mui/material';
import Search from '../../assets/icons/search.svg';
import { useDispatch, useSelector } from 'react-redux';
import { POSITIONS_COLOR } from '../../utils/constants';
import React, { useEffect, useRef, useState } from 'react';

import Pagination from '../../components/Pagination/Pagination';
import { selectGroup } from '../../app/features/group/groupSlice';
import {
	getPlayersDraftTeam,
	pageNavDraftTeam,
	positionActionDraftTeam,
	searchPlayersDraftTeam,
	selectPlayersDraftTeam,
} from '../../app/features/draftTeam/playersDraftTeamSlice';
import { NumItem, NumWrapper } from '../DraftPlayerChoose/DraftPlayerChoose.styles';
import {
	DraftPlayerItem,
	DraftPlayers,
	DraftMyPlayersSlider,
	DraftSearch,
	DraftSearchData,
	SingleAdded,
} from './DraftPlayersData.styles';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import AddPLayer from '../AddPLayer';
import { Swiper, SwiperSlide } from 'swiper/react';
import { selectMyPlayers } from '../../app/features/myPlayers/myPlayersSlice';
import { myPlayersGet } from '../../app/features/myPlayers/myPlayersActions';
import { selectDraftConfigurationData } from '../../app/features/draftConfigurationDataSlice/draftConfigurationDataSlice';
import { selectTeamListBots } from '../../app/features/teamListBots/teamListBotsSlice';
import { selectNFLDraftFanaticsTeamListBots } from '../../app/features/nfldraftfanaticsSlice/nfldraftfanaticsSlice';
import {selectUser} from "../../app/features/user/userSlice";

const positionsData = [
	'All Positions',
	'QB',
	'WR',
	'TE',
	'RB',
	'OT',
	'CB',
	'S',
	'DL',
	'EDGE',
	'LB',
	'C',
	'G',
	// "P",
	// "K"
];

const DraftPlayersData = ({ onAddPlayer }) => {
	const loadingPlayersDraftTeam = useSelector(selectPlayersDraftTeam)?.loading;
	const { loadingBots, isErrorBots } = useSelector(selectTeamListBots);
	const { loadingNFLBots } = useSelector(selectNFLDraftFanaticsTeamListBots);
	const { loading, isError } = useSelector(selectDraftConfigurationData);
	const [colorShow, setColorShow] = useState(true);
	const [selectedPositions, setSelectedPositions] = useState(['All Positions']);
	const groups = useSelector(selectGroup);
	const { myPlayers } = useSelector(selectMyPlayers);
	const dispatch = useDispatch();
	const players = useSelector(selectPlayersDraftTeam);
	const initial = useRef(true);
	const [searchValue, setSearchValue] = useState('');
	const { userInfo } = useSelector(selectUser);

	useEffect(() => {
		dispatch(getPlayersDraftTeam());
	}, []);

	useEffect(() => {
		if (myPlayers.length === 0) {
			dispatch(myPlayersGet());
		}
	}, [myPlayers.length, dispatch]);

	useEffect(() => {
		if (initial.current) {
			initial.current = false;
			return;
		}
		const timer = setTimeout(() => {
			dispatch(searchPlayersDraftTeam(searchValue));
		}, 500);
		return () => {
			clearTimeout(timer);
		};
		// eslint-disable-next-line
	}, [setSearchValue, searchValue]);

	let renderData = null;
	if (players.results.length > 0) {
		renderData = players.results.map((player, idx) => {
			return (
				<DraftPlayerItem key={idx} backColor={colorShow ? POSITIONS_COLOR[player.position] : ''}>
					<div className={'single-inside first'}>
						<div className={'name'}>Rank</div>
						<div className={'value'}>{player.ranking}</div>
					</div>
					<div className={'single-inside second'}>
						<div className={'name'}>BPA</div>
						<div className={'value'}>{idx + 1}</div>
					</div>
					<div className={'single-inside third'}>
						<div className={'name'}>School</div>
						<div className={'value'}>{player.school}</div>
					</div>
					<div className={'value-blue'}>{player.player}</div>
					<div className={'single-position fourth'}>{player.position}</div>
					{/*<button onClick={()=>onAddPlayer(player.player, player.position)}>Draft</button>*/}
					<button
						onClick={() => {
							onAddPlayer({
								id: player.id,
								player: player.player,
								position: player.position,
								college: player.school,
							});
							// dispatch(draftTeamPlayer(player.id))
						}}
						disabled={loading || isError || loadingNFLBots || loadingBots || isErrorBots || loadingPlayersDraftTeam}
					>
						Draft
					</button>
				</DraftPlayerItem>
			);
		});
	}

	const handlePositionClick = position => {
		setSelectedPositions(prev => {
			let updatedPositions;
			if (position === 'All Positions') {
				updatedPositions = ['All Positions'];
				dispatch(positionActionDraftTeam([]));
			} else {
				if (prev.includes('All Positions')) {
					updatedPositions = [position];
				} else if (prev.includes(position)) {
					updatedPositions = prev.filter(pos => pos !== position);
				} else {
					updatedPositions = [...prev, position];
				}
				const positionsToDispatch = updatedPositions.includes('All Positions')
					? []
					: updatedPositions.map(pos => pos.split(' ')[0]);
				dispatch(positionActionDraftTeam(positionsToDispatch));
			}
			return updatedPositions;
		});
	};

	const renderMyPlayers = myPlayers?.map((item, index) => {
		const [name, surname] = item.player.split(' ');
		return (
			<SwiperSlide key={index}>
				<SingleAdded backColor={POSITIONS_COLOR[item.position]}>
					<div className={'single-added-position'}>{item.position}</div>
					<div className={'single-added-name'}>{name}</div>
					<div className={'single-added-surname'}>{surname}</div>
					<div className={'single-added-school'}>
						School
						<span className={'single-added-schoolname'}> {item.school}</span>
					</div>
				</SingleAdded>
			</SwiperSlide>
		);
	});

	return (
		<DraftSearchData>
			{userInfo.role === "Premium" && (
				<>
					<AddPLayer/>
					<DraftMyPlayersSlider>
						{myPlayers.length !== 0 &&
						<div className={'swiper-players-part'}>
							<Swiper spaceBetween={12} slidesPerView={3}>
								{renderMyPlayers}
							</Swiper>
						</div>
						}
					</DraftMyPlayersSlider>
				</>
			)
			}
			<DraftSearch>
				<input
					placeholder="Search"
					className="searchInput"
					value={searchValue}
					onChange={e => {
						setSearchValue(e.target.value);
					}}
				/>
				<img src={Search} className="searchIcon" />
			</DraftSearch>
			<NumWrapper>
				{positionsData.map((item, idx) => {
					const id = idx + 1;
					const isSelected = selectedPositions.includes(item);
					return (
						<NumItem
							key={id}
							backColor={POSITIONS_COLOR[item.split(' ')[0]]}
							onClick={() => handlePositionClick(item)}
							className={isSelected ? 'active' : null}
						>
							<span>{item.split(' ')[0]}</span>
						</NumItem>
					);
				})}
				<div className={'switch-label'}>
					Show positions colors in list
					<Switch defaultChecked onChange={() => setColorShow(prev => !prev)} />
				</div>
			</NumWrapper>
			<DraftPlayers>{renderData}</DraftPlayers>
			<div className={'pagination-div'}>
				<Pagination
					totalCount={players.count}
					pageSize={players.limit}
					currentPage={players.currentPage}
					previous={players.previous}
					next={players.next}
					onPageChange={page => {
						dispatch(pageNavDraftTeam(page));
					}}
				/>
			</div>
		</DraftSearchData>
	);
};

export default DraftPlayersData;
