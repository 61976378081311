import React, { useState, useEffect } from 'react';
import classes from './NumberInput.module.css';

const NumberInput = ({ minQuantity, maxQuantity, value, onQuantityChange }) => {
    const [quantity, setQuantity] = useState(value);

    useEffect(() => {
        setQuantity(value);
    }, [value]);

    const updateButtonStates = (newQuantity) => {
        return {
            disableMinus: newQuantity <= minQuantity,
            disablePlus: newQuantity >= maxQuantity
        };
    };

    const handleDecrease = () => {
        const newQuantity = Math.max(quantity - 1, minQuantity);
        setQuantity(newQuantity);
        onQuantityChange(newQuantity);
    };

    const handleIncrease = () => {
        const newQuantity = Math.min(quantity + 1, maxQuantity);
        setQuantity(newQuantity);
        onQuantityChange(newQuantity);
    };

    const handleInputChange = (event) => {
        let newQuantity = parseInt(event.target.value);
        newQuantity = isNaN(newQuantity) ? minQuantity : newQuantity;
        setQuantity(newQuantity);
        onQuantityChange(newQuantity);
    };

    const { disableMinus, disablePlus } = updateButtonStates(quantity);

    return (
        <div className={classes.quantity}>
            <button
                className={`${classes.minus} ${disableMinus ? classes.disabled : ''}`}
                aria-label="Decrease"
                onClick={handleDecrease}
                disabled={disableMinus}
            >
                -
            </button>
            <input
                type="number"
                className={classes.inputBox}
                value={quantity}
                min={minQuantity}
                max={maxQuantity}
                onChange={handleInputChange}
            />
            <button
                className={`${classes.plus} ${disablePlus ? classes.disabled : ''}`}
                aria-label="Increase"
                onClick={handleIncrease}
                disabled={disablePlus}
            >
                +
            </button>
        </div>
    );
};

export default NumberInput;

// import React, { useState, useEffect } from 'react';
// import classes from './NumberInput.module.css';
//
// const NumberInput = ({ minQuantity, maxQuantity, value, onQuantityChange }) => {
//     const [quantity, setQuantity] = useState(value);
//
//     useEffect(() => {
//         setQuantity(value);
//     }, [value]);
//
//     const updateButtonStates = (newQuantity) => {
//         return {
//             disableMinus: newQuantity <= minQuantity,
//             disablePlus: newQuantity >= maxQuantity
//         };
//     };
//
//     const handleDecrease = () => {
//         const newQuantity = Math.max(quantity - 1, minQuantity);
//         setQuantity(newQuantity);
//         onQuantityChange(newQuantity);
//     };
//
//     const handleIncrease = () => {
//         const newQuantity = Math.min(quantity + 1, maxQuantity);
//         setQuantity(newQuantity);
//         onQuantityChange(newQuantity);
//     };
//
//     const handleInputChange = (event) => {
//         let newQuantity = parseInt(event.target.value);
//         newQuantity = isNaN(newQuantity) ? minQuantity : newQuantity;
//         setQuantity(newQuantity);
//         onQuantityChange(newQuantity);
//     };
//
//     const { disableMinus, disablePlus } = updateButtonStates(quantity);
//
//     return (
//         <div className={classes.quantity}>
//             <button
//                 className={`${classes.minus} ${disableMinus ? classes.disabled : ''}`}
//                 aria-label="Decrease"
//                 onClick={handleDecrease}
//                 disabled={disableMinus}
//             >
//                 -
//             </button>
//             <input
//                 type="number"
//                 className={classes.inputBox}
//                 value={quantity}
//                 min={minQuantity}
//                 max={maxQuantity}
//                 onChange={handleInputChange}
//             />
//             <button
//                 className={`${classes.plus} ${disablePlus ? classes.disabled : ''}`}
//                 aria-label="Increase"
//                 onClick={handleIncrease}
//                 disabled={disablePlus}
//             >
//                 +
//             </button>
//         </div>
//     );
// };
//
// export default NumberInput;


// import React, { useState } from 'react';
// import classes from './NumberInput.module.css';
//
// const NumberInput = ({ minQuantity, maxQuantity, value }) => {
//     const [quantity, setQuantity] = useState(value);
//
//     const updateButtonStates = (newQuantity) => {
//         return {
//             disableMinus: newQuantity <= minQuantity,
//             disablePlus: newQuantity >= maxQuantity
//         };
//     };
//
//     const handleDecrease = () => {
//         setQuantity((prevQuantity) => Math.max(prevQuantity - 1, minQuantity));
//     };
//
//     const handleIncrease = () => {
//         setQuantity((prevQuantity) => Math.min(prevQuantity + 1, maxQuantity));
//     };
//
//     const handleInputChange = (event) => {
//         let value = parseInt(event.target.value);
//         value = isNaN(value) ? minQuantity : value;
//         setQuantity(value);
//     };
//
//     const { disableMinus, disablePlus } = updateButtonStates(quantity);
//
//     return (
//         <div className={classes.quantity}>
//             <button
//                 className={`${classes.minus} ${disableMinus ? classes.disabled : ''}`}
//                 aria-label="Decrease"
//                 onClick={handleDecrease}
//                 disabled={disableMinus}
//             >
//                 -
//             </button>
//             <input
//                 type="number"
//                 className={classes.inputBox}
//                 value={quantity}
//                 min={minQuantity}
//                 max={maxQuantity}
//                 onChange={handleInputChange}
//             />
//             <button
//                 className={`${classes.plus} ${disablePlus ? classes.disabled : ''}`}
//                 aria-label="Increase"
//                 onClick={handleIncrease}
//                 disabled={disablePlus}
//             >
//                 +
//             </button>
//         </div>
//     );
// };
//
// export default NumberInput;
