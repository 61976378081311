import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
	&.main-container {
		margin-top: 44px;
	}
	& .draftResultTitle {
		margin-bottom: 0;
		font-style: normal;
		font-weight: 400;
		font-size: 36px;
		line-height: 57px;
		color: #000000;
	}
	& .share-draft-wrap {
		margin: 24px 0;
	}
	& .share-draft {
		display: flex;
		align-items: center;
		gap: 20px;
		p {
			font-weight: 400;
			font-size: 20px;
			line-height: 30px;
			color: #000000;
		}
	}
`;

export const DraftResultShare = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 850px;
	& .enter-draft-btn {
		margin: 0;
	}
`;

export const DraftResultWrap = styled.div`
	background: #ffffff;
	width: 100%;
	max-width: 480px;
	background: #ffffff;
	margin: 0 auto;
	border-radius: 10px;
	margin-bottom: 100px;
	min-height: 963px;
`;

export const DraftResultHead = styled.div`
	display: flex;
	align-items: center;
	gap: 32px;
	background: #fff;
	/* border-radius: 10px 10px 0px 0px; */
	padding-right: 16px;
	flex-wrap: wrap;
`;
export const DraftTeam = styled.div`
	display: flex;
	align-items: center;
	gap: 24px;
`;
export const DraftTeamName = styled.div`
	h3 {
		color: #000;
		font-size: 27px;
		font-style: normal;
		font-weight: 700;
		line-height: 64px;
		text-transform: uppercase;
	}
	p {
		color: #000;
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 24px;
		text-transform: uppercase;
	}
`;
export const DraftLogoTeam = styled.div`
	padding: 12px 20px;
	/* background-color: ${props => props.backColor || 'transparent'}; */
	/* border-radius: 4px 0px 0px 0px; */
	img {
		width: 100%;
		max-width: 80px;
		object-fit: cover;
	}
`;

export const DraftResultRound = styled.div`
	display: flex;
	align-items: center;
	border-bottom: 1px solid #cad2da;
	overflow: hidden;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	flex-wrap: wrap;
`;

export const DraftResultRoundItem = styled.div`
	font-weight: 600;
	font-size: 20px;
	line-height: 30px;
	color: #0e1118;
	padding: 15px 16px;
	cursor: pointer;

	${props =>
		props.active &&
		css`
			background: #004ea3;
			color: #fff;
		`}
`;
export const DraftResultFull = styled.div`
	display: flex;
	gap: 21px;
	align-items: flex-start;
`;
export const DraftResultTeam = styled.div`
	background-image: url(${props => props.backImg || ''});
	background-position: center;
	background-repeat: repeat-y;
	height: 992px;
	overflow-x: auto;
	background-size: 70% 46%;
	::-webkit-scrollbar {
		width: 5px;
	}

	::-webkit-scrollbar-track {
		background: #f1f1f1;
		border-radius: 8px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 8px;
		background: #205a98;
	}
	::-webkit-scrollbar-thumb:hover {
	}
`;

export const DraftResultTeamItem = styled.div`
	display: flex;
	/* align-items: center; */
	flex-direction: column;
	border-bottom: 1px solid #e8ebef;
	padding: 16px;

	/* & div {
    width: calc(100% / 6);
    text-align: center;
  } */
`;
export const DraftResultTeamCol = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	flex-wrap: wrap;
	:last-child {
		margin-top: 28px;
	}
	.draft-result-team {
		&-round {
			font-weight: 600;
			font-size: 20px;
			line-height: 26px;
			color: #3e464f;
		}
		&-adp {
			color: #50647b;
			font-weight: 400;
			font-size: 18px;
			line-height: 27px;
		}
		&-log {
			display: flex;
			align-items: center;
			gap: 20px;
			img {
				width: 50px;
				height: auto;
			}
			p {
				font-weight: 600;
				font-size: 20px;
				line-height: 30px;
				color: #004ea3;
			}
		}
		&-rating {
			display: flex;
			align-items: center;
			gap: 5px;
			padding-right: 22px;
			justify-content: flex-end;
		}
		&-college {
			font-style: normal;
			font-weight: 400;
			font-size: 20px;
			line-height: 31px;
			color: #46484a;
			margin-right: 50px;
		}
		&-pos {
			padding: 4px 16px;
			background-color: ${props => props.posColor || ''};
			border-radius: 4px;
		}
	}
`;
export const GradeBox = styled.p`
	width: 14px;
	height: 14px;
	background: ${({ color }) => (color ? `${color}` : '#3adf00')};
`;
export const DraftResultPick = styled.div`
	width: 100%;
	max-width: 953px;
	& > div {
		margin-bottom: 30px;
	}
`;
export const DraftResultPickWrap = styled.div`
	img.topPlayerImg {
		position: absolute;
		right: 0;
		bottom: 0;
		max-width: 280px;
		height: auto;
	}
	width: 100%;
	background-image: url(${props => props.backImg || ''});
	background-size: 60%;
	background-position: center;
	background-repeat: repeat-y;
	background-color: #fff;
	background-size: cover;
	/* ${props => props.mode === 1 && 'background-size: cover;  background-repeat: no-repeat;'} */
	overflow: hidden;
	.draft-result-title {
		color: #fff;
		font-size: 40px;
		font-style: normal;
		font-weight: 900;
		text-transform: uppercase;
		max-width: 620px;
		margin-left: 33px;
		margin-top: 40px;
		margin-bottom: 55px;
	}
	.draft-result-logo {
		position: absolute;
		top: 170px;
		right: 50px;
	}
	& .draft-result-pick {
		&-logo {
			display: flex;
			align-items: center;
			color: #fff;
			gap: 20px;
			& img {
				display: block;
				width: 50px;
				height: auto;
			}
		}
		&-item {
			display: flex;
			/* justify-content: center; */
			align-items: center;
			flex-wrap: wrap;
			/* background: #ffffff; */
			padding: 30px 16px;
			border: 1px solid #e8ebef;

			&-info {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				margin-top: 27px;
				margin-bottom: 26px;
			}
			&-text {
				display: flex;
				align-items: center;
				gap: 35px;
				margin-top: 20px;
				margin-bottom: 16px;
			}
		}
		&-round {
			font-weight: 600;
			font-size: 20px;
			line-height: 30px;
			color: #3e464f;
			max-width: 60px;
			width: 100%;
		}
		&-adp {
			font-weight: 600;
			font-size: 20px;
			line-height: 30px;
			color: #004ea3;
			max-width: 60px;
			width: 100%;
		}
		&-name {
			font-weight: 600;
			font-size: 20px;
			line-height: 30px;
			color: #0e1118;
			max-width: 200px;
			width: 100%;
			p:nth-child(2) {
				font-weight: 400;
				font-size: 16px;
				line-height: 24px;
				color: #989ea4;
			}
		}
		&-pos {
			border-radius: 4px;
			padding: 4px 16px;
			p {
				font-weight: 400;
				font-size: 14px;
				line-height: 20px;
				color: #0e1118;
			}
		}
		&-rating {
			color: #46484a;
			> p {
				font-weight: 600;
				font-size: 14px;
				line-height: 20px;
				text-transform: uppercase;
				margin-bottom: 10px;
			}
		}
		&-rating-item {
			display: flex;
			align-items: center;
			font-weight: 600;
			font-size: 16px;
			line-height: 20px;
			color: #46484a;
			gap: 5px;
			max-width: 60px;
			width: 100%;
			justify-content: flex-end;
			&-block {
				width: 14px;
				height: 14px;
				background: #3adf00;
			}
			p {
				font-weight: 600;
				font-size: 20px;
				line-height: 30px;
				color: #46484a;
			}
		}
	}
	/* &.mode1 { */
	.draft-result-item-wrapper {
		border-radius: 30px;
		border: 8px solid #fff;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		max-width: 620px;
		overflow: hidden;
		margin-top: 40px;
		margin-bottom: 40px;
		margin-left: 30px;
	}
	.draft-result-pick {
		&-logo {
			margin-left: 18px;
			margin-right: 20px;

			& img {
				display: block;
				width: 50px;
				height: auto;
			}
		}

		&-item {
			width: 100%;
			background: rgba(0, 0, 0, 0.4);
			padding: 0;
			position: relative;
			&-info {
				display: flex;
				width: 100%;
				margin-top: 27px;
				margin-bottom: 26px;
				margin: 0;
				justify-content: flex-start;
				height: 100%;
				align-items: stretch;
				min-height: 105px;
			}

			&-text {
				display: flex;
				align-items: center;
				gap: 35px;
				margin-top: 20px;
				margin-bottom: 16px;
			}
		}

		&-round {
			max-width: 64px;
			width: 100%;
			display: flex;
			height: 84px;
			justify-content: center;
			align-items: center;
			gap: 10px;
			flex-shrink: 0;
			background: #fff;
			color: rgba(0, 0, 0, 0.8);
			font-size: 18px;
			font-style: normal;
			font-weight: 700;
			line-height: 24px;
			text-transform: uppercase;
			height: auto;
			p {
				margin: 20px 0;
			}
		}

		&-adp {
			font-weight: 600;
			font-size: 20px;
			line-height: 30px;
			color: #004ea3;
			max-width: 60px;
			width: 100%;
		}

		&-name {
			margin-left: 10px;
			max-width: 260px;
			align-self: center;
			p:first-child {
				color: #fff;
				font-size: 24px;
				font-style: normal;
				font-weight: 700;
				line-height: 36px; /* 150% */
				text-transform: uppercase;
			}
			p:last-child {
				color: #fff;
				font-size: 16px;
				font-style: normal;
				font-weight: 700;
				line-height: 24px; /* 150% */
				text-transform: uppercase;
			}
		}

		&-pos {
			border-radius: 8px;
			border: 4px solid #fff;
			background: rgba(255, 255, 255, 0.2);
			padding: 4px 8px;
			margin-left: 20px;
			align-self: center;
			p {
				color: #fff;
				font-size: 18px;
				font-style: normal;
				font-weight: 700;
				line-height: 36px;
				text-transform: uppercase;
			}
		}

		&-rating {
			margin-left: auto;
			padding: 0px 20px;
			align-self: center;
			> p {
				color: #fff;
				text-align: center;
				font-size: 16px;
				font-style: normal;
				font-weight: 700;
				line-height: 36px; /* 225% */
				text-transform: uppercase;
				margin-bottom: 0px;
			}
		}

		&-rating-item {
			display: flex;
			align-items: center;
			font-weight: 600;
			font-size: 16px;
			line-height: 20px;
			gap: 5px;
			max-width: 60px;
			width: 100%;
			justify-content: flex-end;

			&-block {
				width: 14px;
				height: 14px;
				background: #3adf00;
			}

			p {
				color: #fff;
				font-size: 21px;
				font-style: normal;
				font-weight: 700;
				line-height: 36px;
				text-transform: uppercase;
				&:first-child {
					width: 20px;
					height: 20px;
				}
			}
		}
	}
	/* } */
`;
export const DraftResultPickFooter = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	justify-content: space-between;
	background-color: #fff;
	.draft-now {
		display: flex;
		background: #0e1118;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: #ffffff;
		flex: 50%;
		padding: 8px 0px 8px 32px;
		gap: 5px;
		align-items: center;
		a {
			color: inherit;
			text-decoration: underline;
		}
	}
	.draft-overall {
		display: flex;
		gap: 20px;
		flex: 50%;
		align-items: center;
		justify-content: flex-end;
		padding-right: 16px;
		> p {
			font-weight: 600;
			font-size: 14px;
			line-height: 20px;
			color: #989ea4;
		}
		&-grade {
			display: flex;
			align-items: center;
			gap: 6px;
			font-weight: 600;
			font-size: 20px;
			line-height: 30px;
			color: #46484a;
		}
	}
`;
export const DraftResultPos = styled.div`
	background-color: ${props => props.posColor || ''};
`;
export const DraftResultFooter = styled.div`
	background: #cad2da;
	border-radius: 0px 0px 10px 10px;
	font-weight: 600;
	font-size: 20px;
	line-height: 30px;
	color: #022142;
	text-align: center;
	padding: 12px 0;
`;

export const DraftResultsPickWrapper = styled.div`
	position: relative;
	box-sizing: border-box;
	color: #fff;
	background: #fff;
	background-clip: padding-box;
	border: solid 15px transparent;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		margin: -15px;
		border-radius: inherit;
		background: linear-gradient(127deg, #0099d7 0%, #000 100%);
		background: ${props =>
			props.$gradient
				? props.$gradient
				: 'linear-gradient(90deg, #09C73F 0%, #165874 49.37%, #205997 78.83%, #3769A0 100%)'};
	}
`;

export const BadgesItems = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	column-gap: 30px;
	flex-wrap: wrap;
	row-gap: 10px;
	background: #16181a;
	border-radius: 10px 10px 0px 0px;
	margin-top: 24px;
	padding: 8px 25px;
`;
export const MySelectWrap = styled.div`
	/* width: 100%;
  max-width: 432px; */
`;

export const TradesWrap = styled.div`
	background-color: #fff;
`;
export const TradesItems = styled.div`
	display: flex;
	align-items: stretch;
	justify-content: space-around;
	padding: 40px 32px;
	.line {
		width: 2px;
		background-color: #cad2da;
	}
`;
export const TradesItem = styled.div`
	font-size: 14px;
	flex: 1;
	:last-child {
		margin-left: 40px;
	}
	img {
		width: 70px;
		height: auto;
	}
	.trades {
		&-player {
			margin-bottom: 8px;
			h6 {
				font-weight: 600;
				font-size: 20px;
				line-height: 30px;
				color: #0e1118;
			}
			p {
				font-weight: 400;
				font-size: 18px;
				line-height: 27px;
				color: #0e1118;
			}
		}
		&-pick {
			margin-bottom: 24px;
			h6 {
				font-weight: 600;
				font-size: 20px;
				line-height: 30px;
				color: #0e1118;
			}
			span {
				font-weight: 400;
				font-size: 18px;
				line-height: 27px;
				color: #0e1118;
			}
		}
		&-years {
			h6 {
				font-weight: 600;
				font-size: 20px;
				line-height: 30px;
				color: #0e1118;
			}
			span {
				font-weight: 400;
				font-size: 18px;
				line-height: 27px;
				color: #0e1118;
			}
		}
	}
	& > div:not(:first-child, :nth-child(2)) {
		margin-top: 16px;
	}
`;

export const MockDraftWrap = styled.div`
	display: flex;
	justify-content: space-between;
	flex: 1;
	align-items: center;
`;

export const ActionWrap = styled.div`
	display: flex;
	align-items: center;
	gap: 23px;
	margin-bottom: 23px;
	.downland-btn {
		display: flex;
		align-items: center;
		color: #004ea3;
		gap: 6px;
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		text-align: center;
		color: #004ea3;
		cursor: pointer;
	}
	.downland-copy {
		display: flex;
		align-items: center;
		gap: 6px;
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		text-align: center;
		color: #004ea3;
		cursor: pointer;
	}
`;

export const TeamChange = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	gap: 20px;
	justify-content: space-between;
	margin-bottom: 50px;
`;
export const ImgWrap = styled.div`
	width: 100%;
	max-width: 60px;
	& img {
		display: block;
		width: 100%;
		height: auto;
		object-fit: cover;
	}
`;
export const PlayerInfo = styled.div`
	text-align: center;
	font-size: 20px;
	font-weight: bold;
	color: #195fac;
	& p:first-child {
		margin-bottom: 5px;
	}
`;

export const SimSimResult = styled.div`
	display: flex;
	gap: 38px;
	.arrow-wrap {
		display: flex;
		flex-direction: column;
	}
	.sim-pick {
		display: flex;
		gap: 14px;
		p {
			display: flex;
			gap: 14px;
			font-size: 18px;
			font-weight: bold;
			color: #195fac;
		}
	}
`;

export const DraftReslutUserInfo = styled.div`
	display: flex;
	column-gap: 12px;
	margin: 25px;
	margin-top: 40px;
	margin-bottom: 40px;
	align-items: center;
	img {
		width: 64px;
		height: 64px;
		border-radius: 32px;
		border: 3px solid #fff;
	}
	p {
		color: #fff;
		font-size: 24px;
		font-style: italic;
		font-weight: 600;
		line-height: 32px;
		mix-blend-mode: difference;
	}
`;
