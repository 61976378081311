import React, { lazy, Suspense, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { selectDraftConfig } from '../app/features/draftConfig/draftConfigSlice';
import { selectDraftResult } from '../app/features/draftResult/draftResultSlice';
import Layout from '../layout/Layout';
import ProtectRouter from './ProtectRouter';
// Pages
// import Home from "../pages/Home/Home"
// import SelectDraft from "../pages/SelectDraft/SelectDraft"
import ForgotPass from '../pages/auth/ForgotPass/ForgotPass';
import ResetPass from '../pages/auth/ResetPass/ResetPass';
import SignIn from '../pages/auth/SignIn/SignIn';
import SignUp from '../pages/auth/SignUp/SignUp';
// import Players from '../pages/Players/Players';
import DraftConfiguration from '../pages/DraftConfiguration/DraftConfiguration';
// import DraftValueChart from '../pages/DraftValueChart/DraftValueChart';
import DraftPlayer from '../pages/DraftPlayer/DraftPlayer';
import TeamNeeds from '../pages/TeamNeeds/TeamNeeds';
// import DraftResult from '../pages/DraftResult/DraftResult';
import { selectUser } from '../app/features/user/userSlice';
import Spinner from '../components/Spinner/Spinner';
import NotFound from '../pages/NotFoundPage/NotFoundPage';
import Badges from '../pages/Profile/Badges/Badges';
import DraftEvents from '../pages/Profile/DraftEvents/DraftEvents';
import Logout from '../pages/Profile/Logout/Logout';
import Password from '../pages/Profile/Password/Password';
import Payment from '../pages/Profile/Payment/Payment';
import PersonalInfo from '../pages/Profile/PersonalInfo/PersonalInfo';
import Podcasts from '../pages/Profile/Podcasts/Podcasts';
import ProfileLayout from '../pages/Profile/ProfileLayout';
import TeamList from '../pages/TeamList/TeamList';
import PrivateRouter from './PrivateRouter';
// Path
import LiveDraft from '../pages/LiveDraft';
import MultiPlayerFind from '../pages/MultiPlayerFind/MultiPlayerFind';
import MultiPlayerTeam from '../pages/MultiPlayerTeam/MultiPlayerTeam';
import CreateEvents from '../pages/Profile/DraftEvents/CreateEvents';
import EditEvent from '../pages/Profile/DraftEvents/EditEvent';
import EventList from '../pages/Profile/DraftEvents/EventList';
import MyEvents from '../pages/Profile/DraftEvents/MyEvents';
import PayPalRedirect from '../pages/Profile/PayPalRedirect/PayPalRedirect';
import Subscription from '../pages/Profile/Subscription';
import {
	DRAFT_CONFIG,
	DRAFT_PLAYER,
	DRAFT_RESULT,
	DRAFT_TEAM_CONFIGURATION,
	DRAFT_VALUE_CHART,
	FORGOT_PASS,
	HOME,
	LIVE_DRAFT_ID,
	LIVE_RESULT,
	MULTI_PLAYER_FIND,
	MULTI_PLAYER_JOIN_TEAM_ID,
	NOT_FOUND,
	PLAYER_RANKING,
	PLAYERS,
	PLAYERS_POSITIONS_ID,
	PROFILE_BADGES,
	PROFILE_DRAFT_EVENTS,
	PROFILE_DRAFT_EVENTS_CREATE,
	PROFILE_DRAFT_EVENTS_MY,
	PROFILE_DRAFT_EVENTS_MY_EDIT,
	PROFILE_DRAFT_EVENTS_MY_VIEW,
	PROFILE_LOGOUT,
	PROFILE_MY_PLAYERS,
	PROFILE_PASSWORD,
	PROFILE_PAYMENT,
	PROFILE_PODCASTS,
	PROFILE_SUBSCRIPTION,
	PROFILE_SUBSCRIPTION_RETURN,
	RANKING_GRAPHICS,
	RESET_PASS,
	SELECT_DRAFT,
	SELECT_DRAFT_MODE_ID,
	SIGH_UP,
	SIGN_IN,
	TEAM_LIST,
	TEAM_NEEDS,
} from './route-path';
// import TokenService from "../service/token.service";
import { SocketProvider } from '../hook/SocketContext';
import DraftTeamConfiguration from '../pages/DraftTeamConfiguration/DraftTeamConfiguration';
import LiveResult from '../pages/LiveResult';
import PlayerRanking from '../pages/PlayerRanking/PlayerRanking';
import PlayersPositions from '../pages/PlayersPositions/PlayersPositions';
import ViewEvent from '../pages/Profile/DraftEvents/ViewEvent';
import MyPlayers from '../pages/Profile/MyPlayers/MyPlayers';
import SelectDraftMode from '../pages/SelectDraftMode/SelectDraftMode';
import RankingGraphics from "../pages/RankingGraphics/RankingGraphics";
import {getUserMe} from "../app/features/user/userActions";

// Pages Lazy
const Home = lazy(() => import('../pages/Home/Home'));
const SelectDraft = lazy(() => import('../pages/SelectDraft/SelectDraft'));
const Players = lazy(() => import('../pages/Players/Players'));
const DraftResult = lazy(() => import('../pages/DraftResult/DraftResult'));
const DraftValueChart = lazy(() => import('../pages/DraftValueChart/DraftValueChart'));

const Router = () => {
	const { teamSelect } = useSelector(selectDraftConfig);
	const { results } = useSelector(selectDraftResult);
	const { success, userToken } = useSelector(selectUser);


	//
	// useEffect(() => {
	// 	dispatch(getUserMe());
	// }, []);


	console.log(userToken, "userToken")

	return (
		<Routes>
			<Route path={HOME} element={<Layout />}>
				<Route
					index
					element={
						<Suspense fallback={<Spinner />}>
							<Home />
						</Suspense>
					}
				/>
				<Route
					path={SELECT_DRAFT}
					element={
						<ProtectRouter access={userToken} redirect={SIGN_IN}>
							<Suspense fallback={<Spinner />}>
								<SelectDraft />
							</Suspense>
						</ProtectRouter>
					}
				/>
				<Route
					path={DRAFT_CONFIG}
					element={
						<ProtectRouter access={userToken} redirect={SIGN_IN}>
							<Suspense fallback={<Spinner />}>
								<DraftConfiguration />
							</Suspense>
						</ProtectRouter>
					}
				/>
				<Route
					path={DRAFT_PLAYER}
					element={
						<ProtectRouter access={teamSelect} redirect={DRAFT_CONFIG}>
							<DraftPlayer />
						</ProtectRouter>
					}
				/>
				<Route
					path={DRAFT_RESULT}  //check
					element={
						<ProtectRouter access={results} redirect={'/draft-configuration'}>
							<Suspense fallback={<Spinner />}>
								<DraftResult />
							</Suspense>
						</ProtectRouter>
					}
				/>
				<Route
					path={PLAYERS}
					element={
						<ProtectRouter access={userToken} redirect={SIGN_IN}>
							<Suspense fallback={<Spinner />}>
								<Players />
							</Suspense>
						</ProtectRouter>
					}
				/>
				<Route
					path={DRAFT_VALUE_CHART}
					element={
						<ProtectRouter access={userToken} redirect={SIGN_IN}>
							<Suspense fallback={<Spinner />}>
								<DraftValueChart />
							</Suspense>
						</ProtectRouter>
					}
				/>
				<Route
					path={TEAM_NEEDS}
					element={
						<ProtectRouter access={userToken} redirect={SIGN_IN}>
							<TeamNeeds />
						</ProtectRouter>
					}
				/>
				<Route
					path={TEAM_LIST}
					element={
						<ProtectRouter access={userToken} redirect={SIGN_IN}>
							<TeamList />
						</ProtectRouter>
					}
				/>
				<Route
					path={MULTI_PLAYER_FIND}
					element={
						<ProtectRouter access={userToken} redirect={SIGN_IN}>
							<MultiPlayerFind />
						</ProtectRouter>
					}
				/>
				<Route
					path={PLAYER_RANKING}
					element={
						<ProtectRouter access={userToken} redirect={SIGN_IN}>
							<PlayerRanking />
						</ProtectRouter>
					}
				/>
				<Route
					path={RANKING_GRAPHICS}
					element={
						<ProtectRouter access={userToken} redirect={SIGN_IN}>
							<RankingGraphics />
						</ProtectRouter>
					}
				/>
				<Route
					path={MULTI_PLAYER_JOIN_TEAM_ID}
					element={
						<ProtectRouter access={userToken} redirect={SIGN_IN}>
							<MultiPlayerTeam />
						</ProtectRouter>
					}
				/>
				<Route
					path={DRAFT_TEAM_CONFIGURATION}
					element={
						<ProtectRouter access={userToken} redirect={SIGN_IN}>
							<DraftTeamConfiguration />
						</ProtectRouter>
					}
				/>
				<Route
					path={SELECT_DRAFT_MODE_ID}
					element={
						<ProtectRouter access={userToken} redirect={SIGN_IN}>
							<SelectDraftMode />
						</ProtectRouter>
					}
				/>
				<Route
					path={PLAYERS_POSITIONS_ID}
					element={
						<ProtectRouter access={userToken} redirect={SIGN_IN}>
							<PlayersPositions />
						</ProtectRouter>
					}
				/>
				<Route
					path={LIVE_DRAFT_ID}
					element={
						<SocketProvider>
							<LiveDraft />
						</SocketProvider>
					}
				/>
				<Route
					path={LIVE_RESULT}
					element={
						<ProtectRouter access={userToken} redirect={SIGN_IN}>
							<LiveResult />
						</ProtectRouter>
					}
				/>
				<Route
					path={'/profile'}
					element={
						<ProtectRouter access={success} redirect={'/'}>
							<ProfileLayout />
						</ProtectRouter>
					}
				>
					<Route
						index
						path={'/profile/info'}
						element={
							<ProtectRouter access={userToken} redirect={SIGN_IN}>
								<PersonalInfo />
							</ProtectRouter>
						}
					/>
					<Route
						path={PROFILE_BADGES}
						element={
							<ProtectRouter access={userToken} redirect={SIGN_IN}>
								<Badges />
							</ProtectRouter>
						}
					/>
					<Route
						path={PROFILE_MY_PLAYERS}
						element={
							<ProtectRouter access={userToken} redirect={SIGN_IN}>
								<MyPlayers />
							</ProtectRouter>
						}
					/>
					<Route
						path={`${PROFILE_DRAFT_EVENTS}`}
						element={
							<ProtectRouter access={userToken} redirect={SIGN_IN}>
								{true ? <DraftEvents /> : <PayPalRedirect />}
							</ProtectRouter>
						}
					>
						{/*<Route path={`${PROFILE_DRAFT_EVENTS}`} element={true ? <DraftEvents /> : <PayPalRedirect />}>*/}
						<Route
							index
							path={`${PROFILE_DRAFT_EVENTS_CREATE}`}
							element={
								<ProtectRouter access={userToken} redirect={SIGN_IN}>
									<CreateEvents />
								</ProtectRouter>
							}
						/>
						<Route
							path={PROFILE_DRAFT_EVENTS_MY}
							element={
								<ProtectRouter access={userToken} redirect={SIGN_IN}>
									<MyEvents />
								</ProtectRouter>
							}
						>
							<Route
								index
								element={
									<ProtectRouter access={userToken} redirect={SIGN_IN}>
										<EventList />
									</ProtectRouter>
								}
							/>
							<Route
								path={`${PROFILE_DRAFT_EVENTS_MY_EDIT}/:id`}
								element={
									<ProtectRouter access={userToken} redirect={SIGN_IN}>
										<EditEvent />
									</ProtectRouter>
								}
							/>
							<Route
								path={`${PROFILE_DRAFT_EVENTS_MY_VIEW}/:id`}
								element={
									<ProtectRouter access={userToken} redirect={SIGN_IN}>
										<ViewEvent />
									</ProtectRouter>
								}
							/>
						</Route>
					</Route>
					<Route
						path={PROFILE_PODCASTS}
						element={
							<ProtectRouter access={userToken} redirect={SIGN_IN}>
								<Podcasts />
							</ProtectRouter>
						}
					/>
					<Route
						path={PROFILE_PAYMENT}
						element={
							<ProtectRouter access={userToken} redirect={SIGN_IN}>
								<Payment />
							</ProtectRouter>
						}
					/>
					<Route
						path={PROFILE_SUBSCRIPTION}
						element={
							<ProtectRouter access={userToken} redirect={SIGN_IN}>
								<Subscription />
							</ProtectRouter>
						}
					/>
					<Route
						path={PROFILE_SUBSCRIPTION_RETURN}
						element={
							<ProtectRouter access={userToken} redirect={SIGN_IN}>
								<PayPalRedirect />
							</ProtectRouter>
						}
					/>
					<Route
						path={PROFILE_PASSWORD}
						element={
							<ProtectRouter access={userToken} redirect={SIGN_IN}>
								<Password />
							</ProtectRouter>
						}
					/>
					<Route path={PROFILE_LOGOUT} element={<Logout />} />
				</Route>
			</Route>
			<Route
				path={SIGH_UP}
				element={
					<PrivateRouter>
						<SignUp />
					</PrivateRouter>
				}
			/>
			<Route
				path={SIGN_IN}
				element={
					<PrivateRouter>
						<SignIn />
					</PrivateRouter>
				}
			/>
			<Route path={FORGOT_PASS} element={<ForgotPass />} />
			<Route path={RESET_PASS} element={<ResetPass />} />
			<Route path={NOT_FOUND} element={<NotFound />} />
		</Routes>
	);
};

export default Router;



// import React, { lazy, Suspense } from 'react';
// import { useSelector } from 'react-redux';
// import { Route, Routes } from 'react-router-dom';
// import { selectDraftConfig } from '../app/features/draftConfig/draftConfigSlice';
// import { selectDraftResult } from '../app/features/draftResult/draftResultSlice';
// import Layout from '../layout/Layout';
// import ProtectRouter from './ProtectRouter';
// // Pages
// // import Home from "../pages/Home/Home"
// // import SelectDraft from "../pages/SelectDraft/SelectDraft"
// import ForgotPass from '../pages/auth/ForgotPass/ForgotPass';
// import ResetPass from '../pages/auth/ResetPass/ResetPass';
// import SignIn from '../pages/auth/SignIn/SignIn';
// import SignUp from '../pages/auth/SignUp/SignUp';
// // import Players from '../pages/Players/Players';
// import DraftConfiguration from '../pages/DraftConfiguration/DraftConfiguration';
// // import DraftValueChart from '../pages/DraftValueChart/DraftValueChart';
// import DraftPlayer from '../pages/DraftPlayer/DraftPlayer';
// import TeamNeeds from '../pages/TeamNeeds/TeamNeeds';
// // import DraftResult from '../pages/DraftResult/DraftResult';
// import { selectUser } from '../app/features/user/userSlice';
// import Spinner from '../components/Spinner/Spinner';
// import NotFound from '../pages/NotFoundPage/NotFoundPage';
// import Badges from '../pages/Profile/Badges/Badges';
// import DraftEvents from '../pages/Profile/DraftEvents/DraftEvents';
// import Logout from '../pages/Profile/Logout/Logout';
// import Password from '../pages/Profile/Password/Password';
// import Payment from '../pages/Profile/Payment/Payment';
// import PersonalInfo from '../pages/Profile/PersonalInfo/PersonalInfo';
// import Podcasts from '../pages/Profile/Podcasts/Podcasts';
// import ProfileLayout from '../pages/Profile/ProfileLayout';
// import TeamList from '../pages/TeamList/TeamList';
// import PrivateRouter from './PrivateRouter';
// // Path
// import LiveDraft from '../pages/LiveDraft';
// import MultiPlayerFind from '../pages/MultiPlayerFind/MultiPlayerFind';
// import MultiPlayerTeam from '../pages/MultiPlayerTeam/MultiPlayerTeam';
// import CreateEvents from '../pages/Profile/DraftEvents/CreateEvents';
// import EditEvent from '../pages/Profile/DraftEvents/EditEvent';
// import EventList from '../pages/Profile/DraftEvents/EventList';
// import MyEvents from '../pages/Profile/DraftEvents/MyEvents';
// import PayPalRedirect from '../pages/Profile/PayPalRedirect/PayPalRedirect';
// import Subscription from '../pages/Profile/Subscription';
// import {
// 	DRAFT_CONFIG,
// 	DRAFT_PLAYER,
// 	DRAFT_RESULT,
// 	DRAFT_TEAM_CONFIGURATION,
// 	DRAFT_VALUE_CHART,
// 	FORGOT_PASS,
// 	HOME,
// 	LIVE_DRAFT_ID,
// 	LIVE_RESULT,
// 	MULTI_PLAYER_FIND,
// 	MULTI_PLAYER_JOIN_TEAM_ID,
// 	NOT_FOUND,
// 	PLAYER_RANKING,
// 	PLAYERS,
// 	PLAYERS_POSITIONS_ID,
// 	PROFILE_BADGES,
// 	PROFILE_DRAFT_EVENTS,
// 	PROFILE_DRAFT_EVENTS_CREATE,
// 	PROFILE_DRAFT_EVENTS_MY,
// 	PROFILE_DRAFT_EVENTS_MY_EDIT,
// 	PROFILE_DRAFT_EVENTS_MY_VIEW,
// 	PROFILE_LOGOUT,
// 	PROFILE_MY_PLAYERS,
// 	PROFILE_PASSWORD,
// 	PROFILE_PAYMENT,
// 	PROFILE_PODCASTS,
// 	PROFILE_SUBSCRIPTION,
// 	PROFILE_SUBSCRIPTION_RETURN,
// 	RANKING_GRAPHICS,
// 	RESET_PASS,
// 	SELECT_DRAFT,
// 	SELECT_DRAFT_MODE_ID,
// 	SIGH_UP,
// 	SIGN_IN,
// 	TEAM_LIST,
// 	TEAM_NEEDS,
// } from './route-path';
// // import TokenService from "../service/token.service";
// import { SocketProvider } from '../hook/SocketContext';
// import DraftTeamConfiguration from '../pages/DraftTeamConfiguration/DraftTeamConfiguration';
// import LiveResult from '../pages/LiveResult';
// import PlayerRanking from '../pages/PlayerRanking/PlayerRanking';
// import PlayersPositions from '../pages/PlayersPositions/PlayersPositions';
// import ViewEvent from '../pages/Profile/DraftEvents/ViewEvent';
// import MyPlayers from '../pages/Profile/MyPlayers/MyPlayers';
// import SelectDraftMode from '../pages/SelectDraftMode/SelectDraftMode';
// import RankingGraphics from "../pages/RankingGraphics/RankingGraphics";
// // Pages Lazy
// const Home = lazy(() => import('../pages/Home/Home'));
// const SelectDraft = lazy(() => import('../pages/SelectDraft/SelectDraft'));
// const Players = lazy(() => import('../pages/Players/Players'));
// const DraftResult = lazy(() => import('../pages/DraftResult/DraftResult'));
// const DraftValueChart = lazy(() => import('../pages/DraftValueChart/DraftValueChart'));
//
// const Router = () => {
// 	const { teamSelect } = useSelector(selectDraftConfig);
// 	const { results } = useSelector(selectDraftResult);
// 	const { success, userToken } = useSelector(selectUser);
//
// 	console.log(userToken, "userToken")
//
// 	return (
// 		<Routes>
// 			<Route path={HOME} element={<Layout />}>
// 				<Route
// 					index
// 					element={
// 						<Suspense fallback={<Spinner />}>
// 							<Home />
// 						</Suspense>
// 					}
// 				/>
// 				{/*<Route*/}
// 				{/*	path={SELECT_DRAFT}*/}
// 				{/*	element={*/}
// 				{/*		<Suspense fallback={<Spinner />}>*/}
// 				{/*			<SelectDraft />*/}
// 				{/*		</Suspense>*/}
// 				{/*	}*/}
// 				{/*/>*/}
// 				<Route
// 					path={SELECT_DRAFT}
// 					element={
// 						<ProtectRouter access={userToken} redirect={'/'}>
// 							<Suspense fallback={<Spinner />}>
// 								<SelectDraft />
// 							</Suspense>
// 						</ProtectRouter>
// 					}
// 				/>
// 				<Route path={DRAFT_CONFIG} element={<DraftConfiguration />} />
// 				<Route
// 					path={DRAFT_PLAYER}
// 					element={
// 						<ProtectRouter access={teamSelect} redirect={DRAFT_CONFIG}>
// 							<DraftPlayer />
// 						</ProtectRouter>
// 					}
// 				/>
// 				<Route
// 					path={DRAFT_RESULT}
// 					element={
// 						<ProtectRouter access={results} redirect={'/draft-configuration'}>
// 							<Suspense fallback={<Spinner />}>
// 								<DraftResult />
// 							</Suspense>
// 						</ProtectRouter>
// 					}
// 				/>
// 				<Route
// 					path={PLAYERS}
// 					element={
// 						<Suspense fallback={''}>
// 							<Players />
// 						</Suspense>
// 					}
// 				/>
// 				<Route
// 					path={DRAFT_VALUE_CHART}
// 					element={
// 						<Suspense fallback={<Spinner />}>
// 							<DraftValueChart />
// 						</Suspense>
// 					}
// 				/>
// 				<Route path={TEAM_NEEDS} element={<TeamNeeds />} />
// 				<Route path={TEAM_LIST} element={<TeamList />} />
// 				<Route
// 					path={MULTI_PLAYER_FIND}
// 					element={
// 						<ProtectRouter access={userToken} redirect={SIGN_IN}>
// 							<MultiPlayerFind />
// 						</ProtectRouter>
// 					}
// 				/>
// 				<Route path={PLAYER_RANKING} element={<PlayerRanking />} />
// 				<Route path={RANKING_GRAPHICS} element={<RankingGraphics />} />
// 				<Route path={MULTI_PLAYER_JOIN_TEAM_ID} element={<MultiPlayerTeam />} />
// 				<Route path={DRAFT_TEAM_CONFIGURATION} element={<DraftTeamConfiguration />} />
// 				<Route path={SELECT_DRAFT_MODE_ID} element={<SelectDraftMode />} />
// 				<Route path={PLAYERS_POSITIONS_ID} element={<PlayersPositions />} />
// 				<Route
// 					path={LIVE_DRAFT_ID}
// 					element={
// 						<SocketProvider>
// 							<LiveDraft />
// 						</SocketProvider>
// 					}
// 				/>
// 				<Route path={LIVE_RESULT} element={<LiveResult />} />
// 				<Route
// 					path={'/profile'}
// 					element={
// 						<ProtectRouter access={success} redirect={'/'}>
// 							<ProfileLayout />
// 						</ProtectRouter>
// 					}
// 				>
// 					<Route index path={'/profile/info'} element={<PersonalInfo />} />
// 					<Route path={PROFILE_BADGES} element={<Badges />} />
// 					<Route path={PROFILE_MY_PLAYERS} element={<MyPlayers />} />
// 					<Route path={`${PROFILE_DRAFT_EVENTS}`} element={true ? <DraftEvents /> : <PayPalRedirect />}>
// 						<Route
// 							index
// 							path={`${PROFILE_DRAFT_EVENTS_CREATE}`}
// 							element={
// 								<ProtectRouter access={userToken} redirect={SIGN_IN}>
// 									<CreateEvents />
// 								</ProtectRouter>
// 							}
// 						/>
// 						<Route path={PROFILE_DRAFT_EVENTS_MY} element={<MyEvents />}>
// 							<Route index element={<EventList />} />
// 							<Route path={`${PROFILE_DRAFT_EVENTS_MY_EDIT}/:id`} element={<EditEvent />} />
// 							<Route path={`${PROFILE_DRAFT_EVENTS_MY_VIEW}/:id`} element={<ViewEvent />} />
// 						</Route>
// 					</Route>
//
// 					<Route path={PROFILE_PODCASTS} element={<Podcasts />} />
// 					<Route path={PROFILE_PAYMENT} element={<Payment />} />
// 					<Route path={PROFILE_SUBSCRIPTION} element={<Subscription />} />
// 					<Route path={PROFILE_SUBSCRIPTION_RETURN} element={<PayPalRedirect />} />
//
// 					<Route path={PROFILE_PASSWORD} element={<Password />} />
// 					<Route path={PROFILE_LOGOUT} element={<Logout />} />
// 				</Route>
// 			</Route>
// 			<Route
// 				path={SIGH_UP}
// 				element={
// 					<PrivateRouter>
// 						<SignUp />
// 					</PrivateRouter>
// 				}
// 			/>
// 			<Route
// 				path={SIGN_IN}
// 				element={
// 					<PrivateRouter>
// 						<SignIn />
// 					</PrivateRouter>
// 				}
// 			/>
// 			<Route path={FORGOT_PASS} element={<ForgotPass />} />
// 			<Route path={RESET_PASS} element={<ResetPass />} />
// 			<Route path={NOT_FOUND} element={<NotFound />} />
// 		</Routes>
// 	);
// };
//
// export default Router;