import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_ENDPOINT, PLAYER_MAX } from '../../../config/config';

const initialState = {
	nflBotsData: {},
	loadingNFLBots: false,
	status: false,
	results: [],
	playerIds: [],
};

const sortPlayers = players => {
	const positionCounts = countPlayersByPositionNew(players);
	const sortedPositions = Object.keys(positionCounts).sort((a, b) => {
		return positionCounts[a].count - positionCounts[b].count;
	});

	return players.slice().sort((a, b) => {
		const posIndexA = sortedPositions.indexOf(a.position);
		const posIndexB = sortedPositions.indexOf(b.position);
		if (posIndexA !== posIndexB) {
			return posIndexA - posIndexB;
		}
		return a.ranking - b.ranking;
	});
};
const countPlayersByPositionNew = players => {
	return players.reduce((acc, player) => {
		const { position, ranking } = player;
		if (!acc[position]) {
			acc[position] = { count: 0, highestRank: Infinity };
		}
		acc[position].count += 1;
		if (ranking < acc[position].highestRank) {
			acc[position].highestRank = ranking;
		}
		return acc;
	}, {});
};

const countPlayersByPosition = players => {
	return players.reduce((acc, player) => {
		const { position, ranking } = player;
		if (!acc[position]) {
			acc[position] = { count: 0, highestRank: Infinity, highestRankPlayer: null };
		}
		acc[position].count += 1;
		if (ranking < acc[position].highestRank) {
			acc[position].highestRank = ranking;
			acc[position].highestRankPlayer = player;
		}
		return acc;
	}, {});
};

const findPositionWithFewestPlayers = positionCounts => {
	return Object.entries(positionCounts).reduce(
		(min, [position, data]) => {
			return data.count < min.count ? { position, ...data } : min;
		},
		{ position: null, count: Infinity }
	);
};

const selectHighestRankedPlayerFromLowestPosition = players => {
	const positionCounts = countPlayersByPosition(players);
	const positionWithFewestPlayers = findPositionWithFewestPlayers(positionCounts);
	return positionWithFewestPlayers.highestRankPlayer;
};

const countByPosition = players => {
	return players.reduce((acc, player) => {
		const { position } = player;
		if (acc[position]) {
			acc[position] += 1;
		} else {
			acc[position] = 1;
		}
		return acc;
	}, {});
};

const extractParts = str => {
	const startIndex = str.indexOf('(');
	const endIndex = str.indexOf(')');
	const mainPart = str.substring(0, startIndex).trim();
	const number = str.substring(startIndex + 1, endIndex).trim();
	return { mainPart, number };
};

export const getNflDraftFanaticsListBots = createAsyncThunk(
	'nflDraftFanaticsListBots/getNflDraftFanaticsListBots',
	async (name, { dispatch, getState, rejectWithValue }) => {
		const needed = extractParts(name);
		const botName = needed.mainPart;
		const quantity = needed.number;
		try {
			const { playerIds } = getState()?.nflDraftFanaticsListBots;
			const updatedPlayerIds = [...playerIds];
			const excludeIdsParam = updatedPlayerIds.map(id => `exclude_ids=${id}`).join('&');
			const res = await axios.get(
				`${API_ENDPOINT}players/?${excludeIdsParam}&limit=${PLAYER_MAX}&offset=${0}&search=&position=&school`
			);
			const resData = {
				...res.data,
				playerIds,
				quantityToChoose: quantity,
				// playersToChoose:[]
			};
			dispatch(setNflDraftFanaticsListBots({ bot_name: botName, data: resData }));
		} catch (error) {
			if (error.response && error.response.data.message) {
				return rejectWithValue(error.response.data.message);
			} else {
				return rejectWithValue(error.message);
			}
		}
	}
);

export const nflDraftFanaticsListBotsSlice = createSlice({
	name: 'nflDraftFanaticsListBots',
	initialState,
	reducers: {
		setNflDraftFanaticsListBots: (state, action) => {
			const { bot_name, data } = action.payload;
			state.results = data?.results || state.results;
			state.playerIds = data?.playerIds || state.playerIds;
			state.status = true; //check;
			state.nflBotsData[bot_name] = {
				...state.nflBotsData[bot_name],
				bot_name: bot_name || state.nflBotsData[bot_name]?.bot_name || '',
				quantity: data.quantityToChoose || null,
				playersToChoose: data?.results.slice(0, data.quantityToChoose * 12) || [],
				positionCounts: countByPosition(data?.results.slice(0, data.quantityToChoose * 12)) || null,
				playerToChoose: selectHighestRankedPlayerFromLowestPosition(data?.results.slice(0, data.quantityToChoose * 12)),
				sortedList: sortPlayers(data?.results.slice(0, data.quantityToChoose * 12)),
				remainingResult: data?.results.slice(data.quantityToChoose * 12) || [],
				// playersToChoose: data?.results.slice(0,data.quantityToChoose *2) || [],
				// positionCounts:countByPosition(data?.results.slice(0,data.quantityToChoose *2)) || null,
				// playerToChoose:selectHighestRankedPlayerFromLowestPosition(data?.results.slice(0,data.quantityToChoose *2)),
				// sortedList:sortPlayers(data?.results.slice(0,data.quantityToChoose *2)),
				// remainingResult:data?.results.slice(data.quantityToChoose *2) || []
			};
		},
		setExcludePlayerId: (state, action) => {
			// const arrayOfIds=action.payload;
			// state.playerIds = [...state.playerIds,...arrayOfIds]
			state.loadingNFLBots = true;
			const arrayOfIds = Array.isArray(action.payload) ? action.payload : [action.payload];
			const uniqueIds = new Set(state.playerIds);
			arrayOfIds.forEach(id => uniqueIds.add(id));
			state.playerIds = Array.from(uniqueIds);
			state.loadingNFLBots = false;
		},
	},
	extraReducers: {
		[getNflDraftFanaticsListBots.fulfilled]: (state, action) => {
			state.loadingNFLBots = false;
		},
		[getNflDraftFanaticsListBots.pending]: (state, action) => {
			state.loadingNFLBots = true;
		},
		[getNflDraftFanaticsListBots.rejected]: (state, action) => {
			state.loadingNFLBots = false;
		},
	},
});

export const selectNFLDraftFanaticsTeamListBots = state => state.nflDraftFanaticsListBots;
export const { setExcludePlayerId, setNflDraftFanaticsListBots } = nflDraftFanaticsListBotsSlice.actions;
export default nflDraftFanaticsListBotsSlice.reducer;
