import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_ENDPOINT } from '../../../config/config';
import axiosInstance from '../../../service/axiosInstance';

export const podcastersPost = createAsyncThunk(
	'podcasters/post',

	async (data, { rejectWithValue }) => {
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json',
				},
			};

			const res = await axios.post('http://127.0.0.1:8000/api/token/', data, config);
			return res.data;
		} catch (error) {
			// return custom error message from API if any
			if (error.response && error.response.data.message) {
				return rejectWithValue(error.response.data.message);
			} else {
				return rejectWithValue(error.message);
			}
		}
	}
);

export const getPodcasters = createAsyncThunk('users/podcasters/', async (_, { getState, rejectWithValue }) => {
	try {
		const res = await axiosInstance.get(`${API_ENDPOINT}users/podcasters/`);
		return res.data;
	} catch (error) {
		if (error.response && error.response.data.message) {
			return rejectWithValue(error.response.data.message);
		} else {
			return rejectWithValue(error.message);
		}
	}
});
export const subscribePodcaster = createAsyncThunk('/user/subscribe/', async (podcaster_id, { getState, rejectWithValue }) => {
	try {
		const res = await axiosInstance.post(`${API_ENDPOINT}user/subscribe/`, { podcaster_id });
		return res.data;
	} catch (error) {
		if (error.response && error.response.data.message) {
			return rejectWithValue(error.response.data.message);
		} else {
			return rejectWithValue(error.message);
		}
	}
});
