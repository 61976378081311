import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_ENDPOINT } from '../../../config/config';
import axiosInstance from '../../../service/axiosInstance';

export const getPlayersForRankings = createAsyncThunk(
	'ranking-players/getPlayersForRankings',
	async ({ setLimit, setPosition, setSearch }, { dispatch, getState, rejectWithValue }) => {
		try {
			const {
				ranking: { limit, positionRanked, positionChoose },
			} = getState();
			const playerLimit = setLimit ? setLimit : limit;
			const playersPosition = setPosition ? setPosition : positionChoose;
			const playersSearch = setSearch ? setSearch : '';
			const res = await axiosInstance.get(
				`${API_ENDPOINT}players/?limit=${playerLimit}&offset=${0}&search=${playersSearch}&position=${playersPosition}&school`
			);
			const resData = { ...res.data, limit: playerLimit, position: playersPosition, search: playersSearch };
			return resData;
		} catch (error) {
			if (error.response && error.response.data.message) {
				return rejectWithValue(error.response.data.message);
			} else {
				return rejectWithValue(error.message);
			}
		}
	}
);

export const getRanking = createAsyncThunk('ranking-data/getRanking', async (_, { rejectWithValue }) => {
	try {
		const res = await axiosInstance.get(`${API_ENDPOINT}ranking`);
		const resData = { ...res.data };
		return resData;
	} catch (error) {
		if (error.response && error.response.data.message) {
			return rejectWithValue(error.response.data.message);
		} else {
			return rejectWithValue(error.message);
		}
	}
});

export const getRankingPosition = createAsyncThunk(
	'ranking-position/getRankingPosition',
	async ({ id }, { rejectWithValue }) => {
		try {
			const res = await axiosInstance.get(`${API_ENDPOINT}ranking/${id}`);
			const resData = { ...res.data };
			return resData;
		} catch (error) {
			if (error.response && error.response.data.message) {
				return rejectWithValue(error.response.data.message);
			} else {
				return rejectWithValue(error.message);
			}
		}
	}
);

export const saveRankingPosition = createAsyncThunk(
	'ranking-position-save/saveRankingPosition',
	async (data, { rejectWithValue }) => {
		//debugger me;
		try {
			const res = await axiosInstance.post(`${API_ENDPOINT}ranking/position/`, {
				...data,
			});
			const resData = { ...res.data };
			return resData;
		} catch (error) {
			if (error.response && error.response.data.message) {
				return rejectWithValue(error.response.data.message);
			} else {
				return rejectWithValue(error.message);
			}
		}
	}
);

export const updateRankingPosition = createAsyncThunk(
	'ranking-position-update/updateRankingPosition',
	async ({ playerId, checkDBSave }, { getState, rejectWithValue }) => {
		try {
			const {
				ranking: { rankId, rankedPlayers, positionRanked },
			} = getState();
			let positionId = null;
			let players = [];
			if (playerId === undefined && checkDBSave) {
				// 2 save check
				players = checkDBSave.players;
				positionId = checkDBSave.id;
			} else if (playerId === undefined && checkDBSave === undefined) {
				// remove all players
				const existingPositionGroup = rankedPlayers.find(group => group.name === positionRanked);
				positionId = existingPositionGroup.id;
				players = [];
			} else {
				// remove one player
				const updatedGroup = rankedPlayers.find(group => group.players.some(player => player.id === playerId));
				const updatedPlayers = updatedGroup.players.filter(player => player.id !== playerId);
				positionId = updatedGroup.id;
				players = updatedPlayers;
			}
			const res = await axiosInstance.patch(`${API_ENDPOINT}ranking/${rankId}/position/${positionId}/`, {
				players,
			});
			const resData = { ...res.data };
			return resData;
		} catch (error) {
			if (error.response && error.response.data.message) {
				return rejectWithValue(error.response.data.message);
			} else {
				return rejectWithValue(error.message);
			}
		}
	}
);



export const updateRankingMode = createAsyncThunk(
	'ranking-mode-update/updateRankingMode',
	async (_, { getState, rejectWithValue }) => {
	try {
		const {
			ranking: { rankId, mode},
		} = getState();
		const newMode=!mode;
		const res = await axiosInstance.patch(`${API_ENDPOINT}ranking/${rankId}/`, {mode:newMode});
		console.log(res.data, "needed")
		return newMode;
	} catch (error) {
		if (error.response && error.response.data.message) {
			return rejectWithValue(error.response.data.message);
		} else {
			return rejectWithValue(error.message);
		}
	}
});