import React, { useEffect, useRef, useState, useMemo, useCallback  } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Title from '../../components/Title/Title';
import { API_ENDPOINT, PLAYER_MAX } from '../../config/config';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';
import {getDraftEventTeamData, selectDraftConfigurationData, updateDraftData, updateDraftEventTeamData,
} from '../../app/features/draftConfigurationDataSlice/draftConfigurationDataSlice';
import {draftTeamPlayer, getRefreshedPlayersDraftTeam, selectPlayersDraftTeam,} from '../../app/features/draftTeam/playersDraftTeamSlice';
import {draftBotTeamPlayer, getTeamListBots, selectTeamListBots,} from '../../app/features/teamListBots/teamListBotsSlice';
import DraftPlayersData from '../../components/DraftPlayersData/DraftPlayersData';
import {DraftBody, DraftConfigWrap, DraftContainer, DraftContainerInside, DraftHeading, DraftTeams,} from './SelectDraftMode.styles';
import { capitalizeWords } from '../../utils/utils';
import DroppableComponent from '../../components/DroppableComponent/DroppableComponent';
import {getNflDraftFanaticsListBots,setExcludePlayerId, selectNFLDraftFanaticsTeamListBots} from "../../app/features/nfldraftfanaticsSlice/nfldraftfanaticsSlice";


const SelectDraftMode = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location =useLocation();
	const quantities = useMemo(() => location.state?.quantities || [], [location.state]);
	const teamNames = useMemo(() => location.state?.teamNames || {}, [location.state]);
	const botApiName = location.state?.botApiName || [];
	const draftConfigurationData = useSelector(selectDraftConfigurationData);
	const { loading, isError } = useSelector(selectDraftConfigurationData);
	const loadingPlayersDraftTeam = useSelector(selectPlayersDraftTeam)?.loading;
	const playersDraftTeamSliceState =useSelector(selectPlayersDraftTeam)
	const { loadingBots, isErrorBots } = useSelector(selectTeamListBots);
	const selectTeamListBotsSlice =useSelector(selectTeamListBots)
	const {loadingNFLBots} = useSelector(selectNFLDraftFanaticsTeamListBots);
	const botsDataSlice = useSelector(selectTeamListBots).botsData;
	const nflBotsDataSlice =useSelector(selectNFLDraftFanaticsTeamListBots)?.nflBotsData;
	const stateNFL=useSelector(selectNFLDraftFanaticsTeamListBots);
	const data = draftConfigurationData?.data;
	const { id } = useParams();


	const [teamIndex, setTeamIndex] = useState(() => {
		const savedTeamIndex = sessionStorage.getItem('teamIndex');
		return savedTeamIndex !== null ? parseInt(savedTeamIndex, 10) : 0;
	});

	const [playerIndex, setPlayerIndex] = useState(() => {
		const savedPlayerIndex = sessionStorage.getItem('playerIndex');
		return savedPlayerIndex !== null ? parseInt(savedPlayerIndex, 10) : 0;
	});

	const [forward, setForward] = useState(() => {
		const savedForward = sessionStorage.getItem('forward');
		return savedForward !== null ? JSON.parse(savedForward) : true;
	});
	
	const [playersAdded, setPlayersAdded] = useState(() => {
		const savedPlayersAdded = sessionStorage.getItem('playersAdded');
		return savedPlayersAdded !== null ? parseInt(savedPlayersAdded, 10) : 0;
	});
	const [teamIndexQuantities, setTeamIndexQuantities] = useState(0);
	const [forwardQuantities, setForwardQuantities] = useState(true);
	const teamRefs = useRef([]);
	const playerRefs = useRef([]);


	useEffect(() => {
		sessionStorage.setItem('teamIndex', teamIndex);
	}, [teamIndex]);

	useEffect(() => {
		sessionStorage.setItem('forward', JSON.stringify(forward));
	}, [forward]);


	useEffect(() => {
		sessionStorage.setItem('playersAdded', playersAdded);
	}, [playersAdded]);

	useEffect(() => {
		sessionStorage.setItem('playerIndex', playerIndex);
	}, [playerIndex]);

	const moveItem = useCallback((transferedId, dropRefCurrent) => {
		const dataCopy = JSON.parse(JSON.stringify(data));
		for (const team of dataCopy) {
			const player = team.team_positions.find(p => p.playerId === transferedId);
			const targetPosition = team.team_positions.find(
				item =>
					dropRefCurrent.innerText.includes(item.position.name) &&
					dropRefCurrent.innerHTML.includes(item.position.map_name)
			);
			if (player && targetPosition) {
				const playerData = {
					player_Name: player.playerName,
					player_Id: player.playerId,
					player_Position: player.playerPosition,
					player_College: player.playerCollege,
				};
				if (targetPosition.position.short_name && targetPosition.position.short_name.includes(player.playerPosition)) {
					if (targetPosition.playerName) {
						const targetPositionPlayerData = {
							player_Name: targetPosition.playerName,
							player_Id: targetPosition.playerId,
							player_Position: targetPosition.playerPosition,
							player_College: targetPosition.playerCollege,
						};
						targetPosition.playerName = '';
						targetPosition.playerPosition = '';
						targetPosition.playerId = '';
						targetPosition.playerCollege = '';
						targetPosition.playerName = playerData.player_Name;
						targetPosition.playerPosition = playerData.player_Position;
						targetPosition.playerId = playerData.player_Id;
						targetPosition.playerCollege = playerData.player_College;
						player.playerName = '';
						player.playerPosition = '';
						player.playerId = '';
						player.playerCollege = '';
						player.playerName = targetPositionPlayerData.player_Name;
						player.playerPosition = targetPositionPlayerData.player_Position;
						player.playerId = targetPositionPlayerData.player_Id;
						player.playerCollege = targetPositionPlayerData.player_College;
					} else {
						targetPosition.playerName = playerData.player_Name;
						targetPosition.playerPosition = playerData.player_Position;
						targetPosition.playerId = playerData.player_Id;
						targetPosition.playerCollege = playerData.player_College;
						player.playerName = '';
						player.playerPosition = '';
						player.playerId = '';
						player.playerCollege = '';
					}
				} else {
					toast.error('The player can not be moved to this position!');
				}
			}
		}
		dispatch(
			updateDraftEventTeamData({
				event_name: draftConfigurationData?.event_name,
				data: dataCopy,
				id,
				finish: false,
			})
		);
	}, [data, dispatch, draftConfigurationData?.event_name]);

	useEffect(() => {
		if (data) {
			teamRefs.current = data.map(() => React.createRef());
			data.forEach(item => {
				if (item.name.includes('nfldraftfanatics')) {
					dispatch(getNflDraftFanaticsListBots(item.name))
				}
			});
		}
	}, [data]);


	const focus =(neededIndex)=>{
		setTimeout(() => {
			if (playerRefs){
				playerRefs?.current[neededIndex][teamIndex]?.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
		}, 1000);

		setTimeout(() => {
			if (teamRefs.current[teamIndex]) {
				if(forward && teamIndex+1<data.length){
					teamRefs.current[teamIndex+1].scrollIntoView({ behavior: 'smooth',  block: 'start'  });
				}
				else if(forward && teamIndex+1>=data.length){
					teamRefs.current[teamIndex-1].scrollIntoView({ behavior: 'smooth',  block: 'start'  });
				}
				else if(!forward && Number(teamIndex-1)<0){
					teamRefs.current[teamIndex+1].scrollIntoView({ behavior: 'smooth',  block: 'start'  });
				}
				else{
					teamRefs.current[teamIndex-1].scrollIntoView({ behavior: 'smooth',  block: 'start'  });
				}
			}
		}, 2000);
	}

	useEffect(() => {
		if (draftConfigurationData.event_name === '') {
			dispatch(getDraftEventTeamData(id)).then(response => {
				const playerIDs = response.payload.data.flatMap(team =>
					team.team_positions.filter(position => position.playerId !== undefined).map(position => position.playerId)
				);
				dispatch(getRefreshedPlayersDraftTeam(playerIDs));
			});
		}
	}, [dispatch, draftConfigurationData.event_name]);


	useEffect(() => {
		if (Array.isArray(botApiName)) {
			botApiName.forEach(bot => {
				if (bot) {
					dispatch(getTeamListBots(bot));
				}
			});
		} else {
			toast.error("ERROR: botApiName is not an array");
		}
	}, []);

	useEffect(() => { //for nfldramatics bot remainingresult new
		const addNFLBotPlayerIfNeeded = async () => {
			if (!loading && !isError && !loadingBots && !isErrorBots && !loadingNFLBots && !loadingPlayersDraftTeam) {
				if (data && nflBotsDataSlice && Object.keys(nflBotsDataSlice).length > 0) {
					const nflBotsDataSliceCopy = JSON.parse(JSON.stringify(nflBotsDataSlice));
					const dataCopy = JSON.parse(JSON.stringify(data));
					const teamName = dataCopy[teamIndex]?.name;
					if (teamName?.toLowerCase().includes('bot') && teamName?.toLowerCase().includes('nfldraftfanatics')) {
						const allTeamPositionIds = new Set();
						for (const teamItem of data) {
							for (const pos of teamItem.team_positions || []) {
								if (pos.playerId) {
									allTeamPositionIds.add(pos.playerId);
								}
							}
						}
						const addNFLBotPlayerNameToTeam = (dataCopy, team, playerName) => {
							let positionFound = false;
							for (let pos of team.team_positions) {
								const shortPositionsNeeded = pos.position.short_name.split(',').map(pos => pos.trim());
								if (!pos.playerName && shortPositionsNeeded.includes(playerName.position)) {
									pos.playerName = playerName.player;
									pos.playerPosition = playerName.position;
									pos.playerId = playerName.id;
									pos.playerCollege = playerName.school;
									const teamPlayerIndex= team.team_positions.findIndex(item =>item.playerId ===playerName.id);
									focus(teamPlayerIndex)
									dispatch(draftTeamPlayer(playerName.id));
									dispatch(setExcludePlayerId(playerName.id))
									getNflDraftFanaticsListBots(team.name)
									const finish = playersAdded + 1 === data.flatMap(t => t.team_positions).length;
									if (finish ) {
										dispatch(
											updateDraftEventTeamData({
												event_name: draftConfigurationData?.event_name,
												data: dataCopy,
												id,
												finish: finish,
											})
										);
										navigate(`/players-positions/${id}`);
										toast.success('All the players added successfully!');
									} else {
										dispatch(
											updateDraftEventTeamData({
												event_name: draftConfigurationData?.event_name,
												data: dataCopy,
												id,
											})
										);
									}
									positionFound = true;
									break;
								}
							}
							if (!positionFound) {
								return false;
							}
							return true;
						};
						const matchingKey = Object.keys(nflBotsDataSliceCopy).find(key => teamName.includes(nflBotsDataSliceCopy[key]?.bot_name));
						let playerAdded = false;
						for (let i = 0; i < nflBotsDataSliceCopy[matchingKey].sortedList.length; i++) {
							const playerFromSortedList = nflBotsDataSliceCopy[matchingKey].sortedList[i];
							const playerBotId = playerFromSortedList?.id;
							if (playerBotId && !allTeamPositionIds.has(playerBotId)) {
								playerAdded  = addNFLBotPlayerNameToTeam(dataCopy,  dataCopy[teamIndex],playerFromSortedList);
								if (playerAdded) {
									break;
								} else {
									nflBotsDataSliceCopy[matchingKey].sortedList.splice(i, 1);
									i--;
								}
							}
						}
						if (!playerAdded) {
							for (let i = 0; i < nflBotsDataSliceCopy[matchingKey].remainingResult.length; i++) {
								const playerFromRemainingList = nflBotsDataSliceCopy[matchingKey].remainingResult[i];
								const playerFromRemainingListBotId = playerFromRemainingList?.id;
								if (playerFromRemainingListBotId && !allTeamPositionIds.has(playerFromRemainingListBotId)) {
									playerAdded = addNFLBotPlayerNameToTeam(dataCopy,  dataCopy[teamIndex], playerFromRemainingList)
									if (playerAdded) {
										break;
									} else {
										nflBotsDataSliceCopy[matchingKey].remainingResult.splice(i, 1);
										i--;
									}
								}
							}
						}
						if(playerAdded){
							setPlayersAdded(playersAdded + 1);
							if (forward) {
								setTeamIndex(prevIndex => {
									const newIndex = prevIndex + 1;
									if (newIndex >= data.length) {
										setForward(false);
										return data.length - 1;
									}
									return newIndex;
								});
							} else {
								setTeamIndex(prevIndex => {
									const newIndex = prevIndex - 1;
									if (newIndex < 0) {
										setForward(true);
										return 0;
									}
									return newIndex;
								});
							}
						}
					}
				} else {
					console.log('Conditions not met or no bot players available.');
				}
			}
		};
		addNFLBotPlayerIfNeeded();
	}, [teamIndex, loading, isError, loadingBots, loadingNFLBots, loadingPlayersDraftTeam, isErrorBots, nflBotsDataSlice, data]);



	useEffect(() => { //for usual bots
		const addBotPlayerIfNeeded = async () => {
			if (!loading && !isError && !loadingBots && !isErrorBots && !loadingNFLBots && !loadingPlayersDraftTeam) {
				if (data && botsDataSlice && Object.keys(botsDataSlice).length > 0) {
					const botsDataSliceCopy = JSON.parse(JSON.stringify(botsDataSlice));
					if (data[teamIndex]?.name?.toLowerCase().includes('bot') && !data[teamIndex]?.name?.toLowerCase().includes('nfldraftfanatics')) { //check
						const allTeamPositionIds = new Set();
						for (const teamItem of data) {
							for (const pos of teamItem.team_positions || []) {
								if (pos.playerId) {
									allTeamPositionIds.add(pos.playerId);
								}
							}
						}
						const tryToAddBotPlayer = (player, match) => {
							const dataCopy = JSON.parse(JSON.stringify(data));
							const added = addBotPlayerNameToTeam(dataCopy, dataCopy[teamIndex], player, match);
							if (added) {
								return true;
							}
							return false;
						};
						let botPlayerToAdd = null;
						let playerAdded = false;
						const teamName = data[teamIndex]?.name;
						const matchingKey = Object.keys(botsDataSlice)?.find(key => teamName.toLowerCase().includes(botsDataSlice[key]?.bot_name.toLowerCase()));
						try {
							const needed = botApiName.find(key => key === matchingKey);
							let neededForRequest = needed === '49ers' ? 'ers' : needed;
							const existingBotData = botsDataSlice[neededForRequest].playerIds
							const excludeIdsParam = existingBotData.map(id => `exclude_ids=${id}`).join('&')
							const response = await
								axios.get(`${API_ENDPOINT}players/?${excludeIdsParam}&limit=${PLAYER_MAX}&offset=${0}&search=&position=&school&ordering=-${neededForRequest}`
								);
							if (response.status === 200) {
								const resDataResults=response.data.results;
								for (let i = 0; i < resDataResults.length; i++) {
									const player = resDataResults[i];
									const playerBotId = player?.id;
									if (playerBotId && !allTeamPositionIds.has(playerBotId)) {
										botPlayerToAdd = player;
										playerAdded = tryToAddBotPlayer(botPlayerToAdd, matchingKey);
										if (playerAdded) {
											break;
										} else {
											console.log(`Failed to add bot player with ID ${playerBotId}, trying next player.`);
											resDataResults.splice(i, 1);
											i--;
										}
									}
								}
							}
						} catch (error) {
							console.error('Error fetching players:', error);
						}
						if (!playerAdded) {
							console.log('No valid bot player could be added.');
						}
						if (playerAdded) {
							setPlayersAdded(playersAdded + 1);
							if (forward) {
								setTeamIndex(prevIndex => {
									const newIndex = prevIndex + 1;
									if (newIndex >= data.length) {
										setForward(false);
										return data.length - 1;
									}
									return newIndex;
								});
							} else {
								setTeamIndex(prevIndex => {
									const newIndex = prevIndex - 1;
									if (newIndex < 0) {
										setForward(true);
										return 0;
									}
									return newIndex;
								});
							}
						}
					}
				} else {
					console.log('Conditions not met or no bot players available.');
				}
			}
		};
		addBotPlayerIfNeeded();
	}, [teamIndex, loading, isError, loadingBots,loadingNFLBots, loadingPlayersDraftTeam, isErrorBots, botsDataSlice, data]);


	const addPlayer = playerData => {
		if (playerData && !loadingPlayersDraftTeam) {
			distributePlayerNamesDynamically([playerData]);
		}
	};

	const distributePlayerNamesDynamically = async playerNames => {
		const dataCopy = JSON.parse(JSON.stringify(data));
		playerNames.forEach(playerName => {
			if (!dataCopy[teamIndex].name.toLowerCase().includes('bot')) {
				if (addPlayerNameToTeam(dataCopy, dataCopy[teamIndex], playerName)) {
					setPlayersAdded(playersAdded + 1);
					if (forward) {
						setTeamIndex(prevIndex => {
							const newIndex = prevIndex + 1;
							if (newIndex >= dataCopy.length) {
								setForward(false);
								return dataCopy.length - 1;
							}
							return newIndex;
						});
					} else {
						setTeamIndex(prevIndex => {
							const newIndex = prevIndex - 1;
							if (newIndex < 0) {
								setForward(true);
								return 0;
							}
							return newIndex;
						});
					}
				}
			} else {
				toast.error('Bots turn');
			}
		});
	};

	const addPlayerNameToTeam = (dataCopy, team, playerName) => {
		const playerExistsInDataCopy = dataCopy.some(teamItem =>
			teamItem.team_positions.some(pos => pos.playerId === playerName.id)
		);
		if (playerExistsInDataCopy) {
			toast.error('This player has already been chosen!');
			return false;
		}
		for (let pos of team.team_positions) {
			const shortPositionsNeeded = pos.position.short_name.split(',').map(pos => pos.trim());
			if (!pos.playerName && shortPositionsNeeded.includes(playerName.position)) {
				pos.playerName = playerName.player;
				pos.playerPosition = playerName.position;
				pos.playerId = playerName.id;
				pos.playerCollege = playerName.college;
				const finish = playersAdded + 1 === data.flatMap(t => t.team_positions).length;
				if (finish) {
					dispatch(
						updateDraftEventTeamData({
							event_name: draftConfigurationData?.event_name,
							data: dataCopy,
							id,
							finish: finish,
						})
					);
					navigate(`/players-positions/${id}`);
					toast.success('All the players added successfully!');
				} else {
					dispatch(updateDraftEventTeamData({ event_name: draftConfigurationData?.event_name, data: dataCopy, id }));
				}
				const teamPlayerIndex= team.team_positions.findIndex(item =>item.playerId ===playerName.id)
				focus(teamPlayerIndex);
				setPlayerIndex(teamPlayerIndex)
				dispatch(draftTeamPlayer(playerName.id));
				dispatch(setExcludePlayerId(playerName.id))
				return true;
			}
		}
		toast.error('Please choose the right position!');
		return false;
	};

	const addBotPlayerNameToTeam = (dataCopy, team, playerName, mat) => {
		let positionFound = false;
		for (let pos of team.team_positions) {
			const shortPositionsNeeded = pos.position.short_name.split(',').map(pos => pos.trim());
				if (!pos.playerName && shortPositionsNeeded.includes(playerName.position)) {
				pos.playerName = playerName.player;
				pos.playerPosition = playerName.position;
				pos.playerId = playerName.id;
				pos.playerCollege = playerName.school;
				const teamPlayerIndex= team.team_positions.findIndex(item =>item.playerId ===playerName.id);
				focus(teamPlayerIndex)
				const needed = botApiName.find(key => key === mat);
				let neededForRequest = needed === '49ers' ? 'ers' : needed
				dispatch(draftBotTeamPlayer({ ordName: neededForRequest, setPlayerId: playerName.id }));
				dispatch(draftTeamPlayer(playerName.id));
				dispatch(setExcludePlayerId(playerName.id))
				const finish = playersAdded + 1 === data.flatMap(t => t.team_positions).length;
				if (finish) {
					dispatch(
						updateDraftEventTeamData({
							event_name: draftConfigurationData?.event_name,
							data: dataCopy,
							id,
							finish: finish,
						})
					);
					navigate(`/players-positions/${id}`);
					toast.success('All the players added successfully!');
				} else {
					dispatch(
						updateDraftEventTeamData({
							event_name: draftConfigurationData?.event_name,
							data: dataCopy,
							id,
						})
					);
				}
				positionFound = true;
				break;
			}
		}
        if (!positionFound) {
			return false;
		}
		return true;
	};



	const generatePositions = useCallback((quantities, teamNames) => {
		const teamCount = Object.values(teamNames).length;
		const positions = [];
		quantities.forEach(item => {
			for (let i = 0; i < item.value; i++) {
				for (let j = 0; j < teamCount; j++) {
					positions.push({ ...item });
				}
			}
		});
		return positions;
	}, [quantities, teamNames]);


	const positions =useMemo(() => generatePositions(quantities, teamNames), [quantities, teamNames]);
	const items =  useMemo(() => positions.map((item, index) => ({ //before opt
		position: item,
	})), [positions])


	const distributeItemsSnakeMode = items => {
		const teamArray = Object.values(teamNames).map(teamName => ({
			name: teamName,
			team_positions: [],
		}));
		let localTeamIndexQuantities = teamIndexQuantities;
		let localForwardQuantities = forwardQuantities;
		items.forEach(item => {
			teamArray[localTeamIndexQuantities].team_positions.push(item);
			if (localForwardQuantities) {
				localTeamIndexQuantities += 1;
				if (localTeamIndexQuantities >= teamArray.length) {
					localForwardQuantities = false;
					localTeamIndexQuantities = teamArray.length - 1;
				}
			} else {
				localTeamIndexQuantities -= 1;
				if (localTeamIndexQuantities < 0) {
					localForwardQuantities = true;
					localTeamIndexQuantities = 0;
				}
			}
		});
		setTeamIndexQuantities(localTeamIndexQuantities);
		setForwardQuantities(localForwardQuantities);
		return teamArray;
	};

	useEffect(() => {
		if (quantities?.length > 0) {
			const distributedData = distributeItemsSnakeMode(items);
			for (let i = 0; i < distributedData.length; i++) {
				let wrWideReceiverCounter = 0;
				let otOffensiveLineCounter = 0;
				let gOffensiveLineCounter = 0;
				let defensiveLineCounter = 0;
				let cornerbackCounter = 0;
				let safetyCounter = 0;
				let abc = distributedData[i].team_positions;
				for (let j = 0; j < abc.length; j++) {
					if (!abc[j].position.map_name && abc[j].position.name === 'Quarterback (QB)') {
						abc[j].position.map_name = 'QB';
					}
					if (!abc[j].position.map_name && abc[j].position.name === 'Running Back (RB)') {
						abc[j].position.map_name = 'RB';
					}
					if (!abc[j].position.map_name && abc[j].position.name === 'Tight end (TE)') {
						abc[j].position.map_name = 'TE';
					}
					if (!abc[j].position.map_name && abc[j].position.name === 'Wide receiver (WR)') {
						if (wrWideReceiverCounter === 0) {
							abc[j].position.map_name = 'WRX';
						} else if (wrWideReceiverCounter === 1) {
							abc[j].position.map_name = 'WRZ';
						}
						wrWideReceiverCounter++;
					}
					if (!abc[j].position.map_name && abc[j].position.name === 'Offensive line (OT)') {
						if (otOffensiveLineCounter === 0) {
							abc[j].position.map_name = 'LT';
						} else if (otOffensiveLineCounter === 1) {
							abc[j].position.map_name = 'RT';
						}
						otOffensiveLineCounter++;
					}
					if (!abc[j].position.map_name && abc[j].position.name === 'Offensive line (G)') {
						if (gOffensiveLineCounter === 0) {
							abc[j].position.map_name = 'LG';
						} else if (gOffensiveLineCounter === 1) {
							abc[j].position.map_name = 'RG';
						}
						gOffensiveLineCounter++;
					}
					if (!abc[j].position.map_name && abc[j].position.name === 'Offensive line (C)') {
						abc[j].position.map_name = 'C';
					}
					if (!abc[j].position.map_name && abc[j].position.name === 'Offensive Flex (RB/TE/WR)') {
						abc[j].position.map_name = 'WRS';
					}
					if (!abc[j].position.map_name && abc[j].position.name === 'Defensive line') {
						if (defensiveLineCounter === 0) {
							abc[j].position.map_name = 'DL1';
						} else if (defensiveLineCounter === 1) {
							abc[j].position.map_name = 'DL2';
						} else if (defensiveLineCounter === 2) {
							abc[j].position.map_name = 'EDGE';
						}
						defensiveLineCounter++;
					}
					if (!abc[j].position.map_name && abc[j].position.name === 'linebacker (LB)') {
						abc[j].position.map_name = 'LB';
					}
					if (!abc[j].position.map_name && abc[j].position.name === 'Cornerback (CB)') {
						if (cornerbackCounter === 0) {
							abc[j].position.map_name = 'CB1';
						} else if (cornerbackCounter === 1) {
							abc[j].position.map_name = 'CB2';
						}
						cornerbackCounter++;
					}
					if (!abc[j].position.map_name && abc[j].position.name === 'Safety (S)') {
						if (safetyCounter === 0) {
							abc[j].position.map_name = 'S1';
						} else if (safetyCounter === 1) {
							abc[j].position.map_name = 'S2';
						}
						safetyCounter++;
					}
					if (!abc[j].position.map_name && abc[j].position.name === 'Defensive flex (CB/S)') {
						abc[j].position.map_name = 'DFLEX1';
					}
					if (!abc[j].position.map_name && abc[j].position.name === 'Defensive flex (LB/CB/S)') {
						abc[j].position.map_name = 'DFLEX2';
					}
					if (!abc[j].position.map_name && abc[j].position.name === 'Defensive flex (LB/DL/EDGE)') {
						abc[j].position.map_name = 'DFLEX3';
					}
				}
				wrWideReceiverCounter = 0;
				otOffensiveLineCounter = 0;
				gOffensiveLineCounter = 0;
				defensiveLineCounter = 0;
				cornerbackCounter = 0;
				safetyCounter = 0;
			}
			if(draftConfigurationData?.data.length ===0){ //check
				dispatch(updateDraftData(distributedData));
			}
		}
	}, [quantities, draftConfigurationData.event_name]); //refreshi jamanak krkanki bayc lutsum a 0 akani xndiry
	// }, [quantities]); //check best

	const generateRows = useMemo(() => {
		if (!data) return [];
		const rows = [];
		const maxPositions = Math.max(...data.map(team => team.team_positions.length));
		for (let i = 0; i < maxPositions; i++) {
			const row = data.map(team => team.team_positions[i] || {});
			rows.push(row);
		}
		return rows;
	}, [data]);

	return (
		<DraftConfigWrap>
			<DraftContainer className="main-container line">
				<DraftContainerInside>
					<DraftHeading>
						<Title titleText="Draft Configuration" titleClassName="title" />
						<p>Customize your mock draft settings below (you can change the order of players)</p>
					</DraftHeading>
					<h2>{draftConfigurationData?.event_name}</h2>
					<h5>ID - {`${id}`}</h5>
					<DraftBody>
						<DraftTeams>
							<div className="draft-teams-inside">
								{data?.map((item, index) => (
									<div className="nameRow" key={index}
										 ref={(el) => (teamRefs.current[index] = el)}>
										 {/*ref={teamRefs?.current[index]} old*/}
										<div
											className={`
                        ${teamIndex === index ? 'activeName' : 'name'}
                         ${teamIndex !== data.length - 1 && forward && index === teamIndex + 1 ? 'yellowName' : 'name'}
                           ${
												teamIndex !== data.length - 1 && !forward && index === teamIndex - 1
													? 'yellowName'
													: 'name'
											}
                        ${teamIndex === data.length - 1 && index === teamIndex - 1 ? 'yellowName' : 'name'}
               ${teamIndex === 0 && index === teamIndex + 1 ? 'yellowName' : 'name'}
                        // ${teamIndex !== index && teamIndex !== index - 1 && index !== 0 ? 'name' : 'name'}
                    `}
										>
											{/*{capitalizeWords(item.name)}*/}
											{(item.name?.toLowerCase().includes('nfldraftfanatics') || !item.name?.toLowerCase().includes('bot')) ?
												capitalizeWords(item.name) : item.name}
										</div>
									</div>
								))}
							</div>
							<div className="team-members">
								{data?.length &&
								generateRows.map((row, rowIndex) => (
									<div className="row" key={rowIndex}>
										{row.map((item, index) => {
											if (!playerRefs.current[rowIndex]) {
												playerRefs.current[rowIndex] = [];
											}
											return (
											<div className="box"
												 key={index}
												 ref={(el) => (playerRefs.current[rowIndex][index] = el)}
											>
												<DndProvider backend={HTML5Backend}>
													<DroppableComponent item={item} moveItem={moveItem}>
													</DroppableComponent>
												</DndProvider>
											</div>
											);
										})}
									</div>
								))}
							</div>
						</DraftTeams>
						<DraftPlayersData onAddPlayer={addPlayer} />
					</DraftBody>
				</DraftContainerInside>
			</DraftContainer>
		</DraftConfigWrap>
	);
};

export default SelectDraftMode;


