import styled from 'styled-components';

export const PodcastsWrapper = styled.section`
	h3 {
		margin-bottom: 20px;
	}
    p {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
`;
