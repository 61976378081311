export const data = [
	{
		division: 'nfldraftfanatics site bot',
		divisionValue: 'nfldraftfanaticsSiteBot',
		divisionChecked: false,
		teams: [
			{ name: 'site bot nfldraftfanatics-1', value: 'nfldraftfanaticsSiteBot1', checked: false, selected_variable: 1 },
			{ name: 'site bot nfldraftfanatics-2', value: 'nfldraftfanaticsSiteBot2', checked: false, selected_variable: 1 },
			{ name: 'site bot nfldraftfanatics-3', value: 'nfldraftfanaticsSiteBot3', checked: false, selected_variable: 1 },
		],
	},
	{
		division: 'AFC East Bot',
		divisionValue: 'afcEastBot',
		divisionChecked: false,
		teams: [
			{ name: 'Buffalo Bills Bot', value: 'buffaloBillsBot', checked: false, api_name: 'Bills' },
			{ name: 'Miami Dolphins Bot', value: 'miamiDolphinsBot', checked: false, api_name: 'Dolphins' },
			{ name: 'NY Jets Bot', value: 'nyJetsBot', checked: false, api_name: 'Jets' },
			{ name: 'NE Patriots Bot', value: 'nyPatriotsBot', checked: false, api_name: 'Patriots' },
		],
	},
	{
		division: 'AFC North Bot',
		value: 'afcNorthBot',
		divisionChecked: false,
		teams: [
			{ name: 'Pittsburgh Steelers Bot', value: 'pittsburghSteelersBot', checked: false, api_name: 'Steelers' },
			{ name: 'Cleveland Browns Bot', value: 'clevelandBrownsBot', checked: false, api_name: 'Browns' },
			{ name: 'Cincinnati Bengals Bot', value: 'cincinnatiBengalsBot', checked: false, api_name: 'Bengals' },
			{ name: 'Baltimore Ravens Bot', value: 'baltimoreRavensBot', checked: false, api_name: 'Ravens' },
		],
	},
	{
		division: 'AFC South bot',
		value: 'afcSouthBot',
		divisionChecked: false,
		teams: [
			{ name: 'Tenessee Titans Bot', value: 'tenesseeTitansBot', checked: false, api_name: 'Titans' },
			{ name: 'Jacksonville Jaguars Bot', value: 'jacksonvilleJaguarsBot', checked: false, api_name: 'Jaguars' },
			{ name: 'Indianapolis Colts Bot', value: 'indianapolisColtsBot', checked: false, api_name: 'Colts' },
			{ name: 'Houston Texans Bot', value: 'houstonTexansBot', checked: false, api_name: 'Texans' },
		],
	},
	{
		division: 'AFC West Bot',
		value: 'afcWestBot',
		divisionChecked: false,
		teams: [
			{ name: 'Kansas City Chiefs Bot', value: 'kansasCityChiefsBot', checked: false, api_name: 'Chiefs' },
			{ name: 'Denver Broncos Bot', value: 'denverBroncosBot', checked: false, api_name: 'Broncos' },
			{ name: 'Las Vegas Raiders Bot', value: 'lasVegasRaidersBot', checked: false, api_name: 'Raiders' },
			{ name: 'Los Angeles Chargers Bot', value: 'losAngelesChargersBot', checked: false, api_name: 'Chargers' },
		],
	},
	{
		division: 'NFC East Bot',
		value: 'nfcEastBot',
		divisionChecked: false,
		teams: [
			{ name: 'Dallas Cowboys Bot', value: 'dallasCowboysBot', checked: false, api_name: 'Cowboys' },
			{ name: 'NY Giants Bot', value: 'nyGiantsBot', checked: false, api_name: 'Giants' },
			{ name: 'Philadelphia Eagles Bot', value: 'philadelphiaEaglesBot', checked: false, api_name: 'Eagles' },
			{ name: 'Washington Commanders Bot', value: 'washingtonCommandersBot', checked: false, api_name: 'Commanders' },
		],
	},
	{
		division: 'NFC North Bot',
		value: 'nfcNorthBot',
		divisionChecked: false,
		teams: [
			{ name: 'Detroit Lions Bot', value: 'detroitLionsBot', checked: false, api_name: 'Lions' },
			{ name: 'Chicago Bears Bot', value: 'chicagoBearsBot', checked: false, api_name: 'Bears' },
			{ name: 'Green Bay Packers Bot', value: 'greenBayPackersBot', checked: false, api_name: 'Packers' },
			{ name: 'Minnesota Vikings Bot', value: 'minnesotaVikingsBot', checked: false, api_name: 'Vikings' },
		],
	},
	{
		division: 'NFC South bot',
		value: 'nfcSouthBot',
		divisionChecked: false,
		teams: [
			{ name: 'Atlanta Falcons Bot', value: 'atlantaFalconsBot', checked: false, api_name: 'Falcons' },
			{ name: 'Carolina Panthers Bot', value: 'carolinaPanthersBot', checked: false, api_name: 'Panthers' },
			{ name: 'New Orleans Saints Bot', value: 'newOrleansSaintsBot', checked: false, api_name: 'Saints' },
			{ name: 'Tampa Bay Buccaneers Bot', value: 'tampaBayBuccaneersBot', checked: false, api_name: 'Buccaneers' },
		],
	},
	{
		division: 'NFC West Bot',
		value: 'nfcWestBot',
		divisionChecked: false,
		teams: [
			{ name: 'San Francisco 49ers Bot', value: 'sanFrancisco49ersBot', checked: false, api_name: '49ers' },
			{ name: 'Arizona Cardinals Bot', value: 'arizonaCardinalsBot', checked: false, api_name: 'Cardinals' },
			{ name: 'Seattle Seahawks Bot', value: 'seattleSeahawksBot', checked: false, api_name: 'Seahawks' },
			{ name: 'Los Angeles Rams Bot', value: 'losAngelesRamsBot', checked: false, api_name: 'Rams' },
		],
	},
];

export const qunatitiesData = [
	{
		name: 'Quarterback (QB)',
		short_name: 'QB', //position name
		maxValue: 1,
		value: 1,
		color: '#FF2A6D',
	},
	{
		name: 'Running Back (RB)',
		short_name: 'RB',
		maxValue: 1,
		value: 1,
		color: '#00CEB8',
	},
	{
		name: 'Tight end (TE)',
		short_name: 'TE',
		maxValue: 1,
		value: 1,
		color: '#FFAE58',
	},
	{
		name: 'Wide receiver (WR)',
		short_name: 'WR',
		maxValue: 2,
		value: 2,
		color: '#58A7FF',
	},
	{
		name: 'Offensive line (OT)',
		short_name: 'OT',
		maxValue: 2,
		value: 2,
		color: '#3A465B',
	},
	{
		name: 'Offensive line (G)',
		short_name: 'G',
		maxValue: 2,
		value: 2,
		color: '#3A465B',
	},
	{
		name: 'Offensive line (C)',
		short_name: 'C',
		maxValue: 1,
		value: 1,
		color: '#3A465B',
	},
	{
		name: 'Offensive Flex (RB/TE/WR)',
		short_name: 'RB,TE,WR',
		maxValue: 1,
		value: 1,
		color: '#BD66FF',
	},
	{
		name: 'Defensive line',
		short_name: 'DL,EDGE',
		maxValue: 3,
		value: 3,
		color: '#6F7D95',
	},
	{
		name: 'linebacker (LB)',
		short_name: 'LB',
		maxValue: 1,
		value: 1,
		color: '#CC5241',
	},
	{
		name: 'Cornerback (CB)',
		short_name: 'CB',
		maxValue: 2,
		value: 2,
		color: '#4942CC',
	},
	{
		name: 'Safety (S)',
		short_name: 'S',
		maxValue: 2,
		value: 2,
		color: '#336799',
	},
	{
		name: 'Defensive flex (CB/S)',
		short_name: 'CB,S',
		maxValue: 1,
		value: 1,
		color: '#AAAB11',
	},
	{
		name: 'Defensive flex (LB/CB/S)',
		short_name: 'LB,CB,S',
		maxValue: 1,
		value: 1,
		color: '#AAAB11',
	},
	{
		name: 'Defensive flex (LB/DL/EDGE)',
		short_name: 'LB,DL,EDGE',
		maxValue: 1,
		value: 1,
		color: '#66A901',
	},
];
