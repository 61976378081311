import { createSlice } from '@reduxjs/toolkit';
import { myPlayersGet, myPlayersPost, myPlayersUpdate, myPlayersDelete } from './myPlayersActions';

const initialState = {
	loading: false,
	error: null,
	message: '',
	myPlayers: [],
};

const myPlayersSlice = createSlice({
	name: 'myPlayers',
	initialState,
	reducers: {
		updateLocal(state, action) {
			state.myPlayers = action.payload;
		},
		resetPlayersState(state) {
			state.myPlayers = [];
		},
	},
	extraReducers: {
		[myPlayersPost.fulfilled]: (state, action) => {
			state.loading = false;
			state.myPlayers.push(...action.payload);
			state.message = 'Player added successfully';
		},
		[myPlayersPost.pending]: state => {
			state.loading = true;
		},
		[myPlayersPost.rejected]: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		[myPlayersGet.fulfilled]: (state, action) => {
			state.loading = false;
			state.myPlayers = action.payload;
		},
		[myPlayersGet.pending]: state => {
			state.loading = true;
		},
		[myPlayersGet.rejected]: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		[myPlayersUpdate.fulfilled]: (state, action) => {
			state.loading = false;
			const index = state.myPlayers.findIndex(player => player.id === action.payload.id);
			if (index !== -1) {
				state.myPlayers[index] = action.payload;
			}
		},
		[myPlayersUpdate.pending]: state => {
			state.loading = true;
		},
		[myPlayersUpdate.rejected]: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		[myPlayersDelete.fulfilled]: state => {
			state.loading = false;
		},
		[myPlayersDelete.pending]: state => {
			state.loading = true;
		},
		[myPlayersDelete.rejected]: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
	},
});

export const { updateLocal, resetPlayersState } = myPlayersSlice.actions;
export const selectMyPlayers = state => state.myPlayers;
export default myPlayersSlice.reducer;
