import styled from 'styled-components';

export const Wrapper = styled.section`
	&.ranking {
		margin-top: 80px;
	}
	.row {
		display: flex;
		justify-content: space-between;
		.column-one {
			max-width: 850px;
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			gap: 50px;
			padding-bottom: 100px;
		}
		.header {
			display: flex;
			align-items: center;
			gap: 24px;
			margin-bottom: 20px;
			.logo {
				max-width: 49px;
				width: 100%;
				height: auto;
				img {
					width: 100%;
					height: 100%;
				}
			}
			.info {
				span {
					color: #fff;
					font-size: 32px;
					font-style: normal;
					font-weight: 700;
					line-height: 48px;
				}
			}
		}
	}
	.share-draft {
		display: flex;
		gap: 24px;
		align-items: center;
		div {
			display: flex;
			align-items: center;
			gap: 8px;
		}
		p {
			color: #000;
			font-size: 20px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			text-transform: capitalize;
		}
	}
`;

export const RankingGraphicsWrapper = styled.div`
	width: 100%;
	height: 100%;
	min-height: 700px;
	position: relative;
	display: flex;
	align-items: stretch;
	box-sizing: border-box;
	color: rgb(255, 255, 255);
	background: padding-box rgb(255, 255, 255);
	border-width: 15px;
	border-style: solid;
	border-color: transparent;
	border-image: initial;
	&:before {
		content: '';
		position: absolute;
		inset: 0px;
		z-index: -1;
		margin: -15px;
		background: linear-gradient(
				90deg,
				rgb(9, 199, 63) 0%,
				rgb(22, 88, 116) 49.37%,
				rgb(32, 89, 151) 78.83%,
				rgb(55, 105, 160) 100%
			)
			rgb(231, 233, 235);
	}
`;

export const RankingGraphicsContainer = styled.div`
	width: 100%;
	background-image: url(${props => props.bg || ''});
	background-size: cover;
	background-repeat: no-repeat;
    display: flex;
`;
export const RankingGraphicsUser =styled.div`
    padding-top: 20px;
    padding-left: 20px;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
  .twitterInfo {
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
  }
  .podcasterInfo{
    display: flex;
    align-items: center;
    gap :5px;
    margin-top: 20px;
  }
  img {
    width: 64px;
    height: 64px;
    border-radius: 32px;
    border: 3px solid #fff;
  }
  p {
    color: white;
    font-size: 24px;
    font-style: italic;
    font-weight: 600;
    line-height: 32px;
  }
` ;


export const RankingGraphicsContent = styled.div`
    min-width: 300px;
	width: fit-content;
	height: 100%;
	background: rgba(0, 0, 0, 0.28);
	backdrop-filter: blur(19px);
	margin-left: auto;
	padding-left: 20px;
	padding-top: 20px;
	padding-bottom: 140px;
`;

export const RankingGraphicsPlayers = styled.div`
	width: 100%;
`;

export const RankingGraphicsPlayerItem = styled.div`
	width: 100%;
	display: flex;
	//align-items: center;
	//gap: 20px;
    gap: 10px;
	padding: 4px 0px;
	position: relative;
	.playerIndex {
		flex: 0 0 1;
		color: #fff;
		text-align: center;
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 32px;
        width: 8%;
		//max-width: 30px;
		//width: 100%;
		flex-shrink: 0;
		flex-grow: 0;
	}
	.playerInfo {
		display: flex;
		flex-direction: column;
		//width: fit-content;
		flex-shrink: 0;
		flex-grow: 0;
        width:65%;
		.playerRankNameWrapper {
			display: flex;
			//align-items: center;
			gap: 8px;
			width: 100%;
			color: ${props => props.$gradeColor};
			.playerRank {
				font-size: 24px;
				font-style: normal;
				font-weight: 700;
				line-height: 36px;
				text-transform: uppercase;
			}
			.playerName {
				font-size: 24px;
				font-style: normal;
				font-weight: 700;
				line-height: 36px;
				text-transform: uppercase;
			}
		}
		.playerSchool {
            padding-left: 30px;
			font-size: 16px;
			font-style: normal;
			font-weight: 700;
			line-height: 24px;
			text-transform: uppercase;
		}
	}
	.playerPosition {
      min-width: 46px;
      width: 15%;
		p {
			display: flex;
			padding: 4px 10px;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 10px;
			border-radius: 8px;
			border: 4px solid #fff;
			background: rgba(255, 255, 255, 0.2);
			font-size: 20px;
			font-style: normal;
			font-weight: 700;
			line-height: 36px; /* 180% */
			text-transform: uppercase;
			//margin-right: 50px;
        
		}
	}
	.hr {
		position: absolute;
		width: 5px;
		height: 44px;
		right: 0;
		background: ${props => props.$gradeColor};
	}
`;

export const RankingGraphicsPlayerTop = styled.div`
	position: absolute;
	bottom: 0px;
	left: 0px;
	max-width: 250px;
	width: 100%;
	img {
		width: 100%;
    height: auto;
	}
`;

export const RankingGrades = styled.div`
	display: flex;
	position: sticky;
	top: 150px;
	width: 465px;
	padding: 24px 40px;
	flex-direction: column;
	align-items: flex-start;
	gap: 40px;
	border-radius: 4px;
	background: #fff;
	margin-top: 135px;
	.title {
		color: #000;
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		line-height: 30px;
	}
`;

export const RankingsInfo = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
	p {
		color: #49454f;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 0.5px;
	}
	.color {
		width: 24px;
		height: 24px;
		background-color: ${props => props.$colorGrade};
	}
`;

export const ActionButtonsWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 26px;
	margin-top: 25px;
	margin-bottom: 40px;
`;

export const ActionButton = styled.div`
	display: flex;
	align-items: center;
	gap: 9px;
	cursor: pointer;
`;
