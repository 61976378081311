import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/img/logo.png';
import PlayersBackground from '../../assets/img/players-background.png';
import {
	DraftConfigPlayersPositions,
	PlayersBoardContainer,
	PlayersBoard,
	Wrapper,
	DraftResultShare,
	ActionWrap,
} from './PlayersPositions.styles';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Title from '../../components/Title/Title';
import * as htmlToImage from 'html-to-image';
import copyImg from '../../assets/img/copy.png';
import Button from '../../components/Buttons/Button';
import downlandImg from '../../assets/img/downlandImg.png';
import twitterBlue from '../../assets/img/twitter-blue.png';
import { capitalizeWords, dataURLtoBlob, loadImage } from '../../utils/utils';
import { copyBlobToClipboard } from 'copy-image-clipboard';
import { getDraftEventTeamData } from '../../app/features/draftConfigurationDataSlice/draftConfigurationDataSlice';
import { selectDraftConfigurationData } from '../../app/features/draftConfigurationDataSlice/draftConfigurationDataSlice';

const PlayersPositions = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const domElRefs = useRef([]);
	const playersData = useSelector(selectDraftConfigurationData).data;
	const { id } = useParams();
	const [copyShow, setCopyShow] = useState(null);
	const [imgBlob, setImgBlog] = useState(null);



	useEffect(() => {
        sessionStorage.removeItem('teamIndex');
        sessionStorage.removeItem('forward');
        sessionStorage.removeItem('playersAdded');
		sessionStorage.removeItem('playerIndex');

    }, []);

	useEffect(() => {
		if (playersData.length === 0) {
			dispatch(getDraftEventTeamData(id));
		}
	}, [dispatch, playersData.length]);

	const rows = 10;
	const columns = 6;
	const tableData = Array.from({ length: rows }, () => Array(columns).fill(''));

	const positionMap = [
		{ name: 'WRX', row: 1, col: 3 },
		{ name: 'CB1', row: 1, col: 4 },
		{ name: 'WRS', row: 2, col: 2 },
		{ name: 'DFLEX1', row: 2, col: 5 },
		{ name: 'LT', row: 3, col: 3 },
		{ name: 'EDGE', row: 3, col: 4 },
		{ name: 'LG', row: 4, col: 3 },
		{ name: 'LB', row: 4, col: 5 },
		{ name: 'RB', row: 5, col: 1 },
		{ name: 'QB', row: 5, col: 2 },
		{ name: 'C', row: 5, col: 3 },
		{ name: 'DL1', row: 5, col: 4 },
		{ name: 'S1', row: 5, col: 6 },
		{ name: 'RG', row: 6, col: 3 },
		{ name: 'DL2', row: 6, col: 4 },
		{ name: 'RT', row: 7, col: 3 },
		{ name: 'TE', row: 8, col: 3 },
		{ name: 'DFLEX3', row: 8, col: 4 },
		{ name: 'DFLEX2', row: 8, col: 5 },
		{ name: 'S2', row: 9, col: 6 },
		{ name: 'WRZ', row: 10, col: 2 },
		{ name: 'CB2', row: 10, col: 4 },
	];

	positionMap.forEach(({ name, row, col }) => {
		tableData[row - 1][col - 1] = name;
	});

	const renderPlayerByPosition = (teamPositions, position, group) => {
		const player = teamPositions.find(single => single.position.map_name === position);
		if (player) {
			const nameParts = player?.playerName?.split(' ') || [];
			let firstName = '';
			let lastName = '';
			if (nameParts.length) {
				if (nameParts[0]?.endsWith('.')) {
					firstName = `${nameParts[0]} ${nameParts[1]}`;
					lastName = nameParts.slice(2).join(' ');
				} else {
					firstName = nameParts[0];
					lastName = nameParts.slice(1).join(' ');
				}
			}

			return (
				<div key={player.id} className={`player-name ${getClassNameByPosition(position)}`}>
					<div className="player-name-line">{firstName}</div>
					<div className="player-name-line">{lastName}</div>
				</div>
			);
		}
		return null;
	};

	const getClassNameByPosition = position => {
		switch (position) {
			case 'WRS':
				return 'wrs-div';
			case 'RB':
				return 'rb-div';
			case 'WRZ':
				return 'wrz-div';
			case 'QB':
				return 'qb-div';
			case 'TE':
				return 'te-div';
			case 'WRX':
				return 'wrx-div';
			case 'LT':
				return 'lt-div';
			case 'RT':
				return 'rt-div';
			case 'LG':
				return 'lg-div';
			case 'RG':
				return 'rg-div';
			case 'C':
				return 'c-div';
			case 'DL1':
				return 'dl1-div';
			case 'DL2':
				return 'dl2-div';
			case 'EDGE':
				return 'edge-div';
			case 'LB':
				return 'lb-div';
			case 'CB1':
				return 'cb1-div';
			case 'CB2':
				return 'cb2-div';
			case 'S1':
				return 's1-div';
			case 'S2':
				return 's2-div';
			case 'DFLEX1':
				return 'dflex1-div';
			case 'DFLEX2':
				return 'dflex2-div';
			case 'DFLEX3':
				return 'dflex3-div';
			default:
				return '';
		}
	};

	const onButtonClick = useCallback(
		index => {
			if (domElRefs.current[index] === null) {
				return;
			}
			htmlToImage
				.toJpeg(domElRefs.current[index])
				.then(dataUrl => {
					const link = document.createElement('a');
					link.download = `draft-results.jpeg`;
					link.href = dataUrl;
					link.click();
					console.clear();
				})
				.catch(err => {
					console.error('Error generating image:', err);
				});
		},
		[domElRefs]
	);

	const onDownloadEntirePage = useCallback(() => {
		htmlToImage
			.toJpeg(document.getElementById('players-positions'))
			.then(dataUrl => {
				const link = document.createElement('a');
				link.download = 'entire-page.jpeg';
				link.href = dataUrl;
				link.click();
			})
			.catch(err => {
				console.error('Error copying entire page:', err);
			});
	}, []);

	const onCopyEntirePage = useCallback(() => {
		htmlToImage
			.toJpeg(document.getElementById('players-positions'))
			.then(dataUrl => {
				loadImage(dataUrl).then(png => {
					const imgBlob = dataURLtoBlob(png);
					setCopyShow('entire'); // Set to a special identifier
					setImgBlog(imgBlob);
				});
			})
			.catch(err => {
				console.error('Error copying entire page:', err);
			});
	}, []);

	useEffect(() => {
		if (imgBlob) {
			const timer = setTimeout(() => {
				copyBlobToClipboard(imgBlob)
					.then(res => {
						setCopyShow(null);
					})
					.catch(err => {
						console.error('Error copying image to clipboard:', err);
					});
			}, 1000);
			return () => clearTimeout(timer);
		}
	}, [imgBlob]);

	const onCopyImage = useCallback(
		index => {
			if (domElRefs.current[index] === null) {
				return;
			}
			htmlToImage
				.toJpeg(domElRefs.current[index])
				.then(dataUrl => {
					loadImage(dataUrl).then(png => {
						const imgBlob = dataURLtoBlob(png);
						setCopyShow(index);
						setImgBlog(imgBlob);
					});
				})
				.catch(err => {
					console.log(err);
				});
		},
		[domElRefs]
	);

	const renderPlayers = () => {
		return playersData?.map((item, index) => (
			<PlayersBoardContainer>
				<ActionWrap>
					<div className="downland-btn" onClick={() => onButtonClick(index)}>
						<img src={downlandImg} alt="download" />
						<p>Download results image</p>
					</div>
					<div className="downland-copy" onClick={() => onCopyImage(index)}>
						<img src={copyImg} alt="download" />
						<p>{copyShow === index ? 'COPIED!' : 'Copy Image'}</p>
					</div>
				</ActionWrap>
				<PlayersBoard backImg={PlayersBackground} key={index} ref={el => (domElRefs.current[index] = el)}>
					{/*<div className={"title"}>{item.name.charAt(0).toUpperCase() + item.name.slice(1)}</div>*/}
					<div className={'titleDiv'}>
						<div className={'logoDiv'}>
							<img src={Logo} alt={'logo'} />
						</div>
						<div className={'title'}>{capitalizeWords(item.name)}</div>
					</div>
					{
						<table>
							<thead>
								<tr>
									{Array.from({ length: columns }).map((_, colIndex) => (
										<th key={colIndex}></th>
									))}
								</tr>
							</thead>
							<tbody>
								{tableData.map((row, rowIndex) => (
									<tr key={rowIndex}>
										{row.map((cell, colIndex) => (
											<td key={colIndex}>{cell && renderPlayerByPosition(item.team_positions, cell)}</td>
										))}
									</tr>
								))}
							</tbody>
						</table>
					}
				</PlayersBoard>
			</PlayersBoardContainer>
		));
	};

	return (
		<Wrapper className="main-container">
			<Title titleText="Your Mock Draft Result  " titleClassName="draftResultTitle" />
			<DraftResultShare>
				<div className="share-draft-wrap">
					<div className="share-draft">
						<p>Share Your Mock Draft Result</p>
						<div>
							<img src={twitterBlue} onClick={() => window.open('https://twitter.com/share')} alt="twitter" />
						</div>
					</div>
				</div>
				<ActionWrap>
					<div className="downland-btn" onClick={onDownloadEntirePage}>
						<img src={downlandImg} alt="download" />
						<p>Download entire result</p>
					</div>
					<div className="downland-copy" onClick={onCopyEntirePage}>
						<img src={copyImg} alt="download" />
						<p>{copyShow === 'entire' ? 'COPIED!' : 'Copy entire result'}</p>
					</div>
				</ActionWrap>
				<Button
					btnText="Enter Draft"
					btnClassName="enter-draft-btn"
					onBtnClick={() => navigate('/draft-team-configuration')}
				/>
			</DraftResultShare>
			<DraftConfigPlayersPositions id="players-positions">{renderPlayers()}</DraftConfigPlayersPositions>
		</Wrapper>
	);
};

export default PlayersPositions;
