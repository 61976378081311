import styled from 'styled-components';

export const DropdownContainer = styled.div`
	position: relative;
	display: inline-block;
`;

export const DropdownContent = styled.div`
	display: ${props => (props.open ? 'block' : 'none')};
	position: absolute;
	background-color: #fff;
	border: 1px solid #ccc;
	max-height: fit-content;
	overflow-y: auto;
	width: max-content;
	z-index: 1;
	padding: 24px 40px;
	margin-top: 8px;
	border-radius: 4px;
	border: 1px solid #79747e;
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
	h4 {
		color: #000;
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		line-height: 30px;
		margin-bottom: 15px;
	}
`;

export const RadioLabel = styled.label`
	display: flex;
	align-items: center;
	padding: 10px;
	cursor: pointer;
	font-size: 16px;
	gap: 16px;
	&:hover {
		background-color: #f1f1f1;
	}
	div {
		width: fit-content;
		color: #000;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
	}
	&.disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
`;

export const RadioButton = styled.input`
	margin-right: 10px;
	accent-color: #004ea3;
	width: 24px;
	height: 24px;
	border-color: #49454f;
`;
