import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import Slider from '@mui/material/Slider';
import { data } from './DraftTeamDataUsed';
import { ReactSortable } from 'react-sortablejs';
import Title from '../../components/Title/Title';
import Arrow from '../../assets/icons/arrow.svg';
import { capitalizeWords } from '../../utils/utils';
import { qunatitiesData } from './DraftTeamDataUsed';
import NumberInput from '../../components/NumberInput/NumberInput';
import { setInitialDraftTeam} from '../../app/features/draftTeam/playersDraftTeamSlice';
import {
	DraftConfigWrap,
	DraftContainer,
	DraftHeading,
	DraftInputs,
	DraftSettings,
	DraftPositions,
	ColorDiv,
	DraftBots,
	DraftNamesContainer,
	DraftNames,
} from './DraftTeamConfiguration.styles';
import {
	postDraftEventTeamData,
	selectDraftConfigurationData,
	deleteDraftEventData,} from '../../app/features/draftConfigurationDataSlice/draftConfigurationDataSlice';
import {selectUser} from "../../app/features/user/userSlice";





const DraftConfiguration = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location =useLocation();
	const draftConfigurationData = useSelector(selectDraftConfigurationData);
	const [quantities, setQuantities] = useState(qunatitiesData);
	const [checkboxData, setCheckboxData] = useState(data);
	const [showTeamNames, setShowTeamNames] = useState(false);
	const [teamNames, setTeamNames] = useState([]);
	const [botNames, setBotNames] = useState({});
	const [botApiName, setBotApiName] = useState([]);
	const [inputdata, setInputData] = useState({
		event_name: '',
		count: 1,
	});
	const { userInfo } = useSelector(selectUser);

	useEffect(() => {
        sessionStorage.removeItem('teamIndex');
        sessionStorage.removeItem('forward');
        sessionStorage.removeItem('playersAdded');
		sessionStorage.removeItem('playerIndex');

    }, []);

	// useEffect(() => {
	// 	const initialTeamNames = Array.from({ length: inputdata.count }, (_, index) => ({
	// 		id: index + 1,
	// 		[`team_${index + 1}_name`]: '',
	// 	}));
	// 	setTeamNames(initialTeamNames);
	// }, [inputdata.count]);

	useEffect(() => {
		const initialTeamNames = Array.from({ length: inputdata.count }, (_, index) => ({
			id: uuidv4(),
		}));
		setTeamNames(initialTeamNames);
	}, [inputdata.count]);


//old
	useEffect(() => {
		if (inputdata.event_name === "" && draftConfigurationData?.id) {
			dispatch(deleteDraftEventData(draftConfigurationData?.id))
			navigate("/draft-team-configuration");
			// navigate("/select-draft-mode);
		}
	}, [inputdata.event_name, dispatch]);


	const handleVariableChange = (value, name) => {
		const checkboxDataCopy = JSON.parse(JSON.stringify(checkboxData));
		checkboxDataCopy.forEach(item => {
			item.teams.forEach(single => {
				if (single.value === name) {
					single.selected_variable = value;
				}
			});
		});
		setCheckboxData(checkboxDataCopy);
	};

	const handleQuantityChange = (positionName, newQuantity) => {
		setQuantities(prevQuantities =>
			prevQuantities.map(item => (item.name === positionName ? { ...item, value: newQuantity } : item))
		);
	};

	//for bots
	useEffect(() => {
		const checkboxDataCopy = JSON.parse(JSON.stringify(checkboxData));
		const checkedBotNames = {};
		const checkedBotApiNames = [];
		checkboxDataCopy.forEach((item, divisionIndex) => {
			if (item.teams) {
				item.teams.forEach((single, teamIndex) => {
					if (single.checked === true) {
						if (single.selected_variable) {
							checkedBotNames[`division_${divisionIndex}_team_${teamIndex}`] =
								single.name + ` (${single.selected_variable})`;
						} else {
							checkedBotNames[`division_${divisionIndex}_team_${teamIndex}`] = single.name;
						}
						checkedBotApiNames.push(single.api_name);
					}
				});
			}
		});
		setBotNames(checkedBotNames);
		setBotApiName(checkedBotApiNames);
	}, [checkboxData]);

	//for bots
	useEffect(() => {
		const checkboxDataCopy = JSON.parse(JSON.stringify(checkboxData));
		checkboxDataCopy.forEach(item => {
			if (item.divisionChecked) {
				item.divisionChecked = false;
			}
			if (item.teams) {
				item.teams.forEach(single => {
					if (single.checked) {
						single.checked = false;
					}
				});
			}
		});
		setCheckboxData(checkboxDataCopy);
	}, []);

	const renderData = quantities.map((item, index) => (
		<div className="row-position" key={index}>
			<NumberInput
				minQuantity={0}
				maxQuantity={item.maxValue}
				value={item.value}
				onQuantityChange={newQuantity => handleQuantityChange(item.name, newQuantity)}
			/>
			<ColorDiv color={item.color} />
			<div className="position-name">{item.name}</div>
		</div>
	));

	const handleInputChange = e => {
		const { name, value } = e.target;
		if (name === 'count') {
			const newCheckboxData = [...checkboxData];
			const currentTotalSelected = newCheckboxData?.flatMap(item => item.teams || []).filter(t => t.checked).length;
			let maxPlayers;
			if (userInfo.role === "Basic" || userInfo.role === "Free") {
				maxPlayers =2;
			} else if (userInfo.role === "Premium") {
				maxPlayers =20;
			} else {
				maxPlayers =2;
			}
			if(Number(value) === 0){
				toast.error('Total players should be 2 and more.');
				return;
			}
			if (Number(value) < 0) {
				toast.error('Negative numbers are not allowed.');
				return;
			}
			if (currentTotalSelected + Number(value) > maxPlayers) {
				toast.error(`Total number of players cannot exceed ${maxPlayers}. For drafting more you will need a Premium plan.`);
				return;
			}
			else {
				setInputData(prevState => ({
					...prevState,
					[name]: value,
				}));
			}
		} else {
			setInputData(prevState => ({
				...prevState,
				[name]: value,
			}));
		}
	};

	const handleTeamNameChange = (id, target) => {
		const { name, value } = target;
		setTeamNames(prevState => {
			return prevState.map(team => {
				if (team.id === id) {
					return { ...team, [name]: value };
				}
				return team;
			});
		});
	};

	const allTeamNamesFilled = () => {
		const teamsWithoutBot = teamNames.filter(team => !team.name);
		const allFilled = teamsWithoutBot.length === Number(inputdata.count) &&
			teamsWithoutBot.every(team => {
				const keyName = `team_${team.id}_name`;
				if (keyName) {
					return team[keyName]?.trim() !== '' && team[keyName]?.trim() !== undefined;
				}
			});
		return allFilled;
	};


	//old

	// const handleDivisionChange = index => {
	// 	const newCheckboxData = [...checkboxData];
	// 	const countOfChecked = newCheckboxData[index].teams.filter(item => item.checked).length;
	// 	let countWantedToAdd;
	// 	newCheckboxData[index].division.includes('nfldraftfanatics')
	// 		? (countWantedToAdd = 3 - countOfChecked)
	// 		: (countWantedToAdd = 4 - countOfChecked);
	// 	const currentTotalSelected = newCheckboxData?.flatMap(item => item.teams || []).filter(t => t.checked).length;
	// 	if (currentTotalSelected + countWantedToAdd > 20) {
	// 		toast.error('Total number of selected bots cannot exceed 20.');
	// 		return;
	// 	}
	// 	if (currentTotalSelected + Number(inputdata.count) + countWantedToAdd > 25) {
	// 		toast.error('Total number of players cannot exceed 25.');
	// 	} else {
	// 		const newDivisionChecked = !newCheckboxData[index].divisionChecked;
	// 		newCheckboxData[index].divisionChecked = newDivisionChecked;
	// 		if (newCheckboxData[index].teams) {
	// 			newCheckboxData[index].teams = newCheckboxData[index].teams.map(team => ({
	// 				...team,
	// 				checked: newDivisionChecked,
	// 			}));
	// 		}
	// 		setCheckboxData(newCheckboxData);
	// 	}
	// };

	const handleDivisionChange = index => {
		const newCheckboxData = [...checkboxData];
		const countOfChecked = newCheckboxData[index].teams.filter(item => item.checked).length;
		let countWantedToAdd;
		newCheckboxData[index].division.includes('nfldraftfanatics')
			? (countWantedToAdd = 3 - countOfChecked)
			: (countWantedToAdd = 4 - countOfChecked);
		const currentTotalSelected = newCheckboxData?.flatMap(item => item.teams || []).filter(t => t.checked).length;
		let maxSelectedBots;
		let maxPlayers;
        if (userInfo.role === "Basic" || userInfo.role === "Free") {
            maxSelectedBots = 1;
            maxPlayers =2;
        } else if (userInfo.role === "Premium") {
            maxSelectedBots = 19;
			maxPlayers =20;
        } else {
            maxSelectedBots =  1;
			maxPlayers =2;
        }
		if (currentTotalSelected + countWantedToAdd > maxSelectedBots) {
			toast.error(`Total number of selected bots cannot exceed ${maxSelectedBots}.`);
			return;
		}
		if (currentTotalSelected + Number(inputdata.count) + countWantedToAdd > maxPlayers) {
			toast.error(`Total number of players cannot exceed ${maxPlayers}. For drafting more you will need a Premium plan.`);
		} else {
			const newDivisionChecked = !newCheckboxData[index].divisionChecked;
			newCheckboxData[index].divisionChecked = newDivisionChecked;
			if (newCheckboxData[index].teams) {
				newCheckboxData[index].teams = newCheckboxData[index].teams.map(team => ({
					...team,
					checked: newDivisionChecked,
				}));
			}
			setCheckboxData(newCheckboxData);
		}
	};

	const transformTeamNames = transferred => {
		return transferred.reduce((acc, team) => {
			const teamNameKey = `team_${team.id}_name`;
			if (team.name) {
				acc[teamNameKey] = team.name;
			} else {
				acc[`${team.id}_name`] = team[teamNameKey];
			}
			return acc;
		}, {});
	};

	const handleNavigate = () => {
			const transformedTeamNames = transformTeamNames(teamNames);
			navigate(`/select-draft-mode/${draftConfigurationData.id}`, {
				state: {quantities: quantities, botApiName: botApiName, teamNames: transformedTeamNames},
			});
			//for going back
			dispatch(
				setInitialDraftTeam({
					loading: false,
					count: 0,
					next: null,
					previous: null,
					currentPage: 1,
					limit: 18,
					offset: 0,
					results: [],
					search: '',
					draftPlayerId: '',
					position: [''],
					/*  colleage:"",*/
					playerIds: [],
				})
			);
	};


	// useEffect(() => {
	// 	if (location.state) {
	// 		setQuantities(location.state.quantities);
	// 		setBotApiName(location.state.botApiName);
	// 		setTeamNames(location.state.teamNames);
	// 	}
	// }, [location.state]);

	//old

	// const handleTeamChange = (divisionIndex, teamIndex) => {
	// 	const newCheckboxData = [...checkboxData];
	// 	if (
	// 		newCheckboxData[divisionIndex] &&
	// 		newCheckboxData[divisionIndex].teams &&
	// 		newCheckboxData[divisionIndex].teams[teamIndex]
	// 	) {
	// 		const team = newCheckboxData[divisionIndex].teams[teamIndex];
	// 		const newTeamChecked = !team.checked;
	// 		team.checked = newTeamChecked;
	// 		const currentTotalSelected = newCheckboxData?.flatMap(item => item.teams || []).filter(t => t.checked).length;
	// 		if (newTeamChecked && currentTotalSelected > 20) {
	// 			toast.error('Total number of selected bots cannot exceed 20.');
	// 			team.checked = !newTeamChecked;
	// 			return;
	// 		}
	// 		if (newTeamChecked && currentTotalSelected + Number(inputdata.count) > 25) {
	// 			toast.error('Total number of players cannot exceed 25.');
	// 			team.checked = !newTeamChecked;
	// 			return;
	// 		} else {
	// 			team.checked = newTeamChecked;
	// 			const allTeamsChecked = newCheckboxData[divisionIndex].teams.every(team => team.checked);
	// 			newCheckboxData[divisionIndex].divisionChecked = allTeamsChecked;
	// 			setCheckboxData(newCheckboxData);
	// 		}
	// 	} else {
	// 		console.error('Invalid divisionIndex or teamIndex');
	// 	}
	// };




	const handleTeamChange = (divisionIndex, teamIndex) => {
		const newCheckboxData = [...checkboxData];
		if (
			newCheckboxData[divisionIndex] &&
			newCheckboxData[divisionIndex].teams &&
			newCheckboxData[divisionIndex].teams[teamIndex]
		) {
			const team = newCheckboxData[divisionIndex].teams[teamIndex];
			const newTeamChecked = !team.checked;
			team.checked = newTeamChecked;
			const currentTotalSelected = newCheckboxData?.flatMap(item => item.teams || []).filter(t => t.checked).length;
			let maxSelectedBots;
			let maxPlayers;
			if (userInfo.role === "Basic" || userInfo.role === "Free") {
				maxSelectedBots = 1;
				maxPlayers =2;
			} else if (userInfo.role === "Premium") {
				maxSelectedBots = 19;
				maxPlayers =20;
			} else {
				maxSelectedBots =  1;
				maxPlayers =2;
			}
			if (newTeamChecked && currentTotalSelected > maxSelectedBots) {
				toast.error(`Total number of selected bots cannot exceed ${maxSelectedBots}.`);
				team.checked = !newTeamChecked;
				return;
			}
			if (newTeamChecked && currentTotalSelected + Number(inputdata.count) > maxPlayers) {
				toast.error(`Total number of players cannot exceed ${maxPlayers}. For drafting more you will need a Premium plan.`);
				team.checked = !newTeamChecked;
				return;
			} else {
				team.checked = newTeamChecked;
				const allTeamsChecked = newCheckboxData[divisionIndex].teams.every(team => team.checked);
				newCheckboxData[divisionIndex].divisionChecked = allTeamsChecked;
				setCheckboxData(newCheckboxData);
			}
		} else {
			console.error('Invalid divisionIndex or teamIndex');
		}
	};


	const renderBots = checkboxData?.map((item, index) => (
		<div className="bot-section" key={index}>
			<div className="bot-part">
				<input
					type="checkbox"
					id={`division-${index}`}
					name={`division-${index}`}
					checked={item.divisionChecked}
					onChange={() => handleDivisionChange(index)}
				/>
				<label className="main-label" htmlFor={`division-${index}`}>
					{item.division}
				</label>
			</div>
			{item.teams?.map((team, idx) => (
				<div className="bot-part" key={idx}>
					<input
						type="checkbox"
						id={`team-${index}-${idx}`}
						name={`team-${index}-${idx}`}
						checked={team.checked}
						onChange={() => handleTeamChange(index, idx)}
					/>
					<label className="label" htmlFor={`team-${index}-${idx}`}>
						{team.name}
					</label>
					{team.name.includes('nfldraftfanatics') && (
						<div className={'slider-box'}>
							<Slider
								step={1}
								min={1}
								max={12}
								value={team.selected_variable}
								onChange={e => handleVariableChange(e.target.value, team.value)}
								aria-label="Default"
								valueLabelDisplay="auto"
							/>
						</div>
					)}
				</div>
			))}
		</div>
	));

	return (
		<DraftConfigWrap>
			<DraftContainer className="main-container line">
				<DraftHeading>
					<Title titleText="Draft Configuration" titleClassName="title" />
					<p>Customize Event Below</p>
				</DraftHeading>
				{!showTeamNames ? (
					<DraftSettings>
						<DraftInputs>
							<div className="draft-input-div">
								<label className="draft-label-name">Name your Event</label>
								<input placeholder={'Draft a team'} name={'event_name'} onChange={handleInputChange} />
							</div>
							<div className="draft-input-div">
								<div className="draft-label-title">Choose Number Of Players</div>
								<label className="draft-label">Number of people</label>
								<input name="count" onChange={handleInputChange} value={inputdata?.count} min={0} type={'number'} />
							</div>
						</DraftInputs>
						<DraftPositions>{renderData}</DraftPositions>
						<DraftBots>
							<div className={'bots'}>{renderBots}</div>
							<button
								onClick={() => {
									if(inputdata.event_name  === ""){
										toast.error('Event name cannot be blank')
										return;
									}
									// if(inputdata.count < 1){
									// 	toast.error('Number of total players must be 2 or more')
									// 	return;
									// }
									const newCheckboxData = [...checkboxData];
									const currentTotalSelected = newCheckboxData?.flatMap(item => item.teams || []).filter(t => t.checked).length;
									if(currentTotalSelected + inputdata.count < 2){
										toast.error('Number of total players must be 2 or more')
										return;
									}
									dispatch(
										setInitialDraftTeam({
											loading: false,
											count: 0,
											next: null,
											previous: null,
											currentPage: 1,
											limit: 18,
											offset: 0,
											results: [],
											search: '',
											draftPlayerId: '',
											position: [''],
											/*  colleage:"",*/
											playerIds: [],
										})
									);
									dispatch(postDraftEventTeamData(inputdata)).then(response => {
										if (response.meta.requestStatus === 'fulfilled') {
											setShowTeamNames(!showTeamNames);
											const newBotNamesArray = Object.entries(botNames).map(([key, value]) => ({
												id: uuidv4(),
												name: value,
											}));
											setTeamNames([...teamNames, ...newBotNamesArray]);
										}
									});
								}}
							>
								Enter Draft
								<img src={Arrow} alt={''} />
							</button>
						</DraftBots>
					</DraftSettings>
				) : (
					<DraftNamesContainer>
						<div className="title">You can change the order of players!</div>
						<DraftNames>
							<ReactSortable list={teamNames} setList={setTeamNames} style={{ display: 'flex', gap: '20px' }}>
								{teamNames?.map((team, index) => {
									if (team?.name) {
										return (
											<div className="botName" key={index}>
												{team.name.includes('nfldraftfanatics') ? capitalizeWords(team.name) : team.name}
											</div>
										);
									} else {
										return (
											<div className="name-input-div" key={team.id}>
												<label className="name-label">
													{/*{`Name the ${team.id} ${team.id === 1 ? 'team' : 'team'}`}*/}
													{`${
														index === 0
															? 'Name the 1st team'
															: index === 1
															? `Name the ${index + 1}nd team`
															: index === 2
															? `Name the ${index + 1}rd team`
															: `Name the ${index + 1}th team`
													}`}
												</label>
												<input
													name={`team_${team.id}_name`}
													onChange={e => handleTeamNameChange(team.id, e.target)}
													value={team[`team_${team.id}_name`]}
													type="text"
													placeholder="Team name"
												/>
											</div>
										);
									}
								})}
							</ReactSortable>
						</DraftNames>
						<button
							onClick={() => {
								if (allTeamNamesFilled()) {
									handleNavigate();
								}
							}}
							disabled={!allTeamNamesFilled()}
						>
							Enter Draft
							<img src={Arrow} alt={''} />
						</button>
					</DraftNamesContainer>
				)}
			</DraftContainer>
		</DraftConfigWrap>
	);
};

export default DraftConfiguration;

