import React, { lazy, Suspense } from "react";

const LazyImageCropper = lazy(() => import("./ImageCropper"));

const ImageCropper = (props) => (
  <Suspense fallback={null}>
    <LazyImageCropper {...props} />
  </Suspense>
);

export default ImageCropper;
