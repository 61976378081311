import { copyBlobToClipboard } from 'copy-image-clipboard';
import { toJpeg } from 'html-to-image';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectRanking, setCurrentPosition } from '../../app/features/ranking/rankingSlice';
import copyImg from '../../assets/img/copy.png';
import downlandImgIcon from '../../assets/img/downlandImg.png';
import logoBg from '../../assets/img/logoBig.png';
import rankingBg from '../../assets/img/ranking.png';
import twitterBlue from '../../assets/img/twitter-blue.png';
import ErrorFallBack from '../../components/ErrorFallBack/ErrorFallBack';
import Title from '../../components/Title/Title';
import { POSITIONS_COLOR, RANKIGS } from '../../utils/constants';
import { dataURLtoBlob, generateRangeArray, generateNewRangeArray, loadImage, rankingDivideDataGraphics } from '../../utils/utils';
import { DraftResultShare } from '../DraftResult/DraftResult.styles';
import Dropdown from '../PlayerRanking/component/Dropdown/Dropdown';
import { SelectPosition } from '../PlayerRanking/PlayerRanking';
import {
	ActionButton,
	ActionButtonsWrapper,
	RankingGrades,
	RankingGraphicsContainer,
	RankingGraphicsUser,
	RankingGraphicsContent,
	RankingGraphicsPlayerItem,
	RankingGraphicsPlayers,
	RankingGraphicsPlayerTop,
	RankingGraphicsWrapper,
	RankingsInfo,
	Wrapper,
	DraftReslutUserInfo
} from './RankingGraphics.styles';
import {selectUser} from "../../app/features/user/userSlice";
import {getUserMe} from "../../app/features/user/userActions";

const RankingGraphics = () => {
	const domEl = useRef(null);
	const [copyShow, setCopyShow] = useState(false);
	const [imgBlob, setImgBlog] = useState(null);
	const { rankedPlayers, positionRanked, mode } = useSelector(selectRanking);
	const {userInfo} = useSelector(selectUser)
	const navigate = useNavigate();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getUserMe());
	}, []);

	const positions = useMemo(() => {
		return rankedPlayers?.filter(ranking => ranking.players.length !== 0).map(ranking => ranking.name);
	}, [rankedPlayers]);

	const positionGroup = useMemo(() => {
		return rankedPlayers?.find(group => group.name === positionRanked)?.players;
	}, [rankedPlayers, positionRanked]);

	useEffect(() => {
		if (positionGroup === undefined) {
			navigate('/players-rankings', { replace: true });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// const onButtonClick = useCallback(dataSection => {
	// 	console.log(dataSection, "dataSection")
	// 	if (!domEl.current) return;
	//
	// 	const resultSections = Array.from(domEl.current.querySelectorAll(`[data-result-section="${dataSection}"]`));
	// 	if (resultSections.length === 0) return;
	// 	console.log(resultSections, 111)
	//
	// 	toJpeg(resultSections[0], { allowTaint: true, foreignObjectRendering: true })
	// 		.then(dataUrl => {
	// 			const link = document.createElement('a');
	// 			link.download = 'ranking-graphics';
	// 			link.href = dataUrl;
	// 			link.click();
	// 		})
	// 		.catch(console.error);
	// }, []);

//today

	const onButtonClick = useCallback(dataSection => {
		if (!domEl.current) return;
		const resultSections = Array.from(domEl.current.querySelectorAll(`[data-result-section="${dataSection}"]`));
		if (resultSections.length === 0) return;
		toJpeg(resultSections[0], {
			allowTaint: true,
			foreignObjectRendering: false
		})
			.then(dataUrl => {
				const link = document.createElement('a');
				link.download = 'ranking-graphics.png'; // Specify file type
				link.href = dataUrl;
				link.click();
			})
			.catch(console.error);
	}, []);


	const onCopyImage = useCallback(dataSection => {
		if (!domEl.current) return;
		const resultSections = Array.from(domEl.current.querySelectorAll(`[data-result-section="${dataSection}"]`));
		if (resultSections.length === 0) return;
		toJpeg(resultSections[0])
			.then(dataUrl => loadImage(dataUrl))
			.then(png => {
				const imgBlob = dataURLtoBlob(png);
				setImgBlog(imgBlob);
				setCopyShow(true);
			})
			.catch(console.error);
	}, []);

	useEffect(() => {
		if (imgBlob) {
			copyBlobToClipboard(imgBlob)
				.then(() => {
					setTimeout(() => setCopyShow(false), 2000);
				})
				.catch(console.error);
		}
	}, [imgBlob]);

	if (!positionGroup) return null;

	const subArrays = rankingDivideDataGraphics(positionGroup);
	let oneArray =[].concat(...subArrays);



	return (
		<ErrorBoundary FallbackComponent={ErrorFallBack}>
			<Helmet>
				<meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />
			</Helmet>
			<Wrapper className="ranking main-container">
				<Title titleText="Your Ranking Graphic Result  " titleClassName="draftResultTitle" />
				<DraftResultShare>
					<div className="share-draft-wrap">
						<div className="share-draft">
							<p>Share Your Graphic</p>
							<div>
								<img
									src={twitterBlue}
									onClick={() => window.open('https://twitter.com/share', '_blank', 'noopener noreferrer')}
									alt=""
								/>
							</div>
						</div>
					</div>
					<Dropdown
						button={<SelectPosition selectedOption={positionRanked} />}
						data={positions}
						title="Choose position"
						colors={POSITIONS_COLOR}
						defaultSelection={positionRanked}
						onSelect={value => dispatch(setCurrentPosition(value))}
					/>
				</DraftResultShare>
				<div className="row">
					<div className="column column-one">
						<div>
							{/*<div>*/}
							{/*	{subArrays.map((rank, idx) => {*/}
							{/*		return (*/}
							{/*			<ActionButtonsWrapper key={rank[0].id + idx}>*/}
							{/*				<ActionButton*/}
							{/*					className="downland-btn"*/}
							{/*					onClick={() => onButtonClick(`ranked${generateRangeArray(rank.length, idx, 15)}`)}*/}
							{/*				>*/}
							{/*					<img src={downlandImgIcon} alt="download" />*/}
							{/*					<p>Download results image</p>*/}
							{/*				</ActionButton>*/}
							{/*				<ActionButton*/}
							{/*					className="downland-btn"*/}
							{/*					onClick={() => onCopyImage(`ranked${generateRangeArray(rank.length, idx, 15)}`)}*/}
							{/*				>*/}
							{/*					<img src={copyImg} alt="download" />*/}
							{/*					<p>{copyShow ? 'COPIED!' : `Copy Image`}</p>*/}
							{/*				</ActionButton>*/}
							{/*			</ActionButtonsWrapper>*/}
							{/*		);*/}
							{/*	})}*/}
							{/*</div>*/}
							<div ref={domEl}>
								{subArrays.map((rank, idx) => {
									return (
										<div key={rank[0].id + idx}>
											<ActionButtonsWrapper
												// key={rank[0].id + idx}
											>
												<ActionButton
													className="downland-btn"
													// onClick={() => onButtonClick(`ranked${generateRangeArray(rank.length, idx, 15)}`)}
													onClick={() => onButtonClick(`ranked${generateNewRangeArray(rank.length, idx, 15)}`)}
												>
													<img src={downlandImgIcon} alt="download" />
													<p>Download results image</p>
												</ActionButton>
												<ActionButton
													className="downland-btn"
													onClick={() => onCopyImage(`ranked${generateNewRangeArray(rank.length, idx, 15)}`)}
												>
													<img src={copyImg} alt="download" />
													<p>{copyShow ? 'COPIED!' : `Copy Image`}</p>
												</ActionButton>
											</ActionButtonsWrapper>
											<RankingGraphicsWrapper
												// key={rank[0].id + idx}
												// data-result-section={`ranked${generateRangeArray(rank.length, idx, 15)}`}
												data-result-section={`ranked${generateNewRangeArray(rank.length, idx, 15)}`}
											>
												<RankingGraphicsContainer bg={rankingBg}>
													<RankingGraphicsUser>{userInfo?.first_name}
														<div className={"twitterInfo"}>{userInfo?.twitter_link}</div>
														{!userInfo?.podcaster && userInfo?.role === "Basic" && (
															<div className={"podcasterInfo"}>
																{userInfo?.subscribed_podcaster?.profile_picture &&
																<img
																	src={userInfo?.subscribed_podcaster?.profile_picture}
																	alt=""/>
																}
																{userInfo?.subscribed_podcaster?.first_name &&
																<p>#{`${userInfo?.subscribed_podcaster?.first_name} ${userInfo?.subscribed_podcaster?.last_name}`}
																</p>
																}
															</div>
														)}
													</RankingGraphicsUser>
													<RankingGraphicsContent>
														<div className="header">
															<div className="logo">
																<img src={logoBg} alt="logoBg" />
															</div>
															<div className="info">
															<span>
																{} {positionRanked}s
															</span>
															</div>
														</div>
														<RankingGraphicsPlayers>
															{rank?.map((item, index) => {
																const globalIndex = (idx === 0 ? 0 : subArrays.slice(0, idx).reduce((sum, subArray) => sum + subArray.length, 0)) + index + 1;
																const color = mode ? item?.grade?.color : '#fff';
																return (
																	<RankingGraphicsPlayerItem key={item.id} $gradeColor={color}>
																		{/*<div className="playerIndex">{index +1}</div>*/}
																		<div className="playerIndex">{globalIndex}</div>
																		<div className="playerInfo">
																			<div className="playerRankNameWrapper">
																				{/*<div className="playerRank">{item.ranking}*/}
																				{/*	<span>/</span></div>*/}
																				<div className="playerName">{item.player}</div>
																			</div>
																			<div className="playerSchoolWrapper">
																				<div className="playerSchool">{item.school}</div>
																			</div>
																		</div>
																		<div className="playerPosition">
																			<p>{item.position}</p>
																		</div>
																		{/*/!*<div className="hr"></div>*!/ //right line*/}
																	</RankingGraphicsPlayerItem>
																);
															})}
														</RankingGraphicsPlayers>
													</RankingGraphicsContent>

													<RankingGraphicsPlayerTop>
														{positionGroup[0]?.image && <img src={positionGroup[0]?.image} alt="topPlayerImg" />}
													</RankingGraphicsPlayerTop>
												</RankingGraphicsContainer>
											</RankingGraphicsWrapper>
										</div>
									);
								})}
							</div>
						</div>
					</div>
					{mode && (
						<div className="column column-two">
							<RankingGrades className="colorsGrades">
								<div className="title">Colors for round grades</div>
								{RANKIGS.map(item => {
									return (
										<RankingsInfo key={item.privilege} $colorGrade={item.color}>
											<p className="color"></p>
											<p className="label">{item.label}</p>
										</RankingsInfo>
									);
								})}
							</RankingGrades>
						</div>
					)}
				</div>
			</Wrapper>
		</ErrorBoundary>
	);
};

export default RankingGraphics;


// return (
// 	<ErrorBoundary FallbackComponent={ErrorFallBack}>
// 		<Helmet>
// 			<meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />
// 		</Helmet>
// 		<Wrapper className="ranking main-container">
// 			<Title titleText="Your Ranking Graphic Result  " titleClassName="draftResultTitle" />
// 			<DraftResultShare>
// 				<div className="share-draft-wrap">
// 					<div className="share-draft">
// 						<p>Share Your Graphic</p>
// 						<div>
// 							<img
// 								src={twitterBlue}
// 								onClick={() => window.open('https://twitter.com/share', '_blank', 'noopener noreferrer')}
// 								alt=""
// 							/>
// 						</div>
// 					</div>
// 				</div>
// 				<Dropdown
// 					button={<SelectPosition selectedOption={positionRanked} />}
// 					data={positions}
// 					title="Choose position"
// 					colors={POSITIONS_COLOR}
// 					defaultSelection={positionRanked}
// 					onSelect={value => dispatch(setCurrentPosition(value))}
// 				/>
// 			</DraftResultShare>
// 			<div className="row">
// 				<div className="column column-one">
// 					<div>
// 						<div>
// 							{subArrays.map((rank, idx) => {
// 								return (
// 									<ActionButtonsWrapper key={rank[0].id + idx}>
// 										<ActionButton
// 											className="downland-btn"
// 											onClick={() => onButtonClick(`ranked${generateRangeArray(rank.length, idx, 15)}`)}
// 										>
// 											<img src={downlandImgIcon} alt="download" />
// 											<p>Download results image</p>
// 										</ActionButton>
// 										<ActionButton
// 											className="downland-btn"
// 											onClick={() => onCopyImage(`ranked${generateRangeArray(rank.length, idx, 15)}`)}
// 										>
// 											<img src={copyImg} alt="download" />
// 											<p>{copyShow ? 'COPIED!' : `Copy Image`}</p>
// 										</ActionButton>
// 									</ActionButtonsWrapper>
// 								);
// 							})}
// 						</div>
// 						<div ref={domEl}>
// 							{subArrays.map((rank, idx) => {
// 								return (
// 									<RankingGraphicsWrapper
// 										key={rank[0].id + idx}
// 										data-result-section={`ranked${generateRangeArray(rank.length, idx, 15)}`}
// 									>
// 										<RankingGraphicsContainer bg={rankingBg}>
// 											<RankingGraphicsContent>
// 												<div className="header">
// 													<div className="logo">
// 														<img src={logoBg} alt="logoBg" />
// 													</div>
// 													<div className="info">
// 															<span>
// 																{} {positionRanked}s
// 															</span>
// 													</div>
// 												</div>
// 												<RankingGraphicsPlayers>
// 													{rank?.map((item, index) => {
// 														const color = mode ? item?.grade?.color : '#fff';
// 														return (
// 															<RankingGraphicsPlayerItem key={item.id} $gradeColor={color}>
// 																<div className="playerIndex">{index + 1}</div>
// 																<div className="playerInfo">
// 																	<div className="playerRankNameWrapper">
// 																		<div className="playerRank">{item.ranking}
// 																			<span>/</span></div>
// 																		<div className="playerName">{item.player}</div>
// 																	</div>
// 																	<div className="playerSchoolWrapper">
// 																		<div className="playerSchool">{item.school}</div>
// 																	</div>
// 																</div>
// 																<div className="playerPosition">
// 																	<p>{item.position}</p>
// 																</div>
// 																{/*/!*<div className="hr"></div>*!/ //right line*/}
// 															</RankingGraphicsPlayerItem>
// 														);
// 													})}
// 												</RankingGraphicsPlayers>
// 											</RankingGraphicsContent>
//
// 											<RankingGraphicsPlayerTop>
// 												{positionGroup[0]?.image && <img src={positionGroup[0]?.image} alt="topPlayerImg" />}
// 											</RankingGraphicsPlayerTop>
// 										</RankingGraphicsContainer>
// 									</RankingGraphicsWrapper>
// 								);
// 							})}
// 						</div>
// 					</div>
// 				</div>
//
// 				{mode && (
// 					<div className="column column-two">
// 						<RankingGrades className="colorsGrades">
// 							<div className="title">Colors for round grades</div>
// 							{RANKIGS.map(item => {
// 								return (
// 									<RankingsInfo key={item.privilege} $colorGrade={item.color}>
// 										<p className="color"></p>
// 										<p className="label">{item.label}</p>
// 									</RankingsInfo>
// 								);
// 							})}
// 						</RankingGrades>
// 					</div>
// 				)}
// 			</div>
// 		</Wrapper>
// 	</ErrorBoundary>
// );
// };
