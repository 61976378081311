import React from 'react';
import AddPLayer from '../../../components/AddPLayer/AddPLayer';
import { ProfileTitle } from '../Profile.styles';
import { FlexSpace, MyPlayersWrap } from './MyPlayers.styles';
import PlayerList from './components/PlayerList';

const MyPlayers = () => {
	return (
		<MyPlayersWrap>
			<FlexSpace>
				<ProfileTitle>My Players</ProfileTitle>
				<AddPLayer />
			</FlexSpace>
			<PlayerList />
		</MyPlayersWrap>
	);
};

export default MyPlayers;
