import styled from 'styled-components';

export const DraftConfigWrap = styled.section`
	margin-top: 50px;
	margin-bottom: 120px;
	width: 100%;
`;

export const DraftContainer = styled.div``;
export const DraftContainerInside = styled.div`
  border-radius: 10px;
  background: #FFF;
  display: flex;
  flex-direction: column;
  padding: 40px 24px;
  h2{
    color: #3E464F;
    font-family: "Saira Semi Condensed", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 16px;
  }
  h5{
    color: #9BA1A7;=
    font-family: "Saira Semi Condensed", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 24px;
  }
`;

export const DraftHeading = styled.div`
	margin-bottom: 31px;
	display: flex;
	flex-direction: column;
	.title {
		color: #000;
		font-family: 'Saira Semi Condensed', sans-serif;
		font-size: 40px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		text-transform: capitalize;
		margin-bottom: 0;
	}
	p {
		color: #3e464f;
		font-size: 20px;
		font-family: 'Saira Semi Condensed', sans-serif;
		font-weight: 400;
	}
`;

export const DraftBody = styled.div`
	display: flex;
	padding-top: 25px;
	border-top: 1px solid #d4d4d4;
	position: relative;
`;
export const ScrollbarContainer = styled.div`
	overflow-x: auto;
	height: 20px;
	position: absolute;
	top: 0;
	width: 60%;
	z-index: 1;
	display: ${props => (props.quantitity > 2 ? 'block' : 'none')};
`;
export const DraftTeams = styled.div`
	overflow-x: auto;
	margin-top: 20px;
	width: 60%;
	display: flex;
	flex-direction: column;
	border-right: 1px solid #e8ebef;
	height: max-content;
	.draft-teams-inside {
		display: flex;
		justify-content: space-between;
		gap: 24px;
	}
	.left {
		width: 50%;
	}
	.nameRow {
		display: flex;
		gap: 8px;
		min-width: 290px;
		max-width: 391px;
		width: 100%;
	}
	.name {
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		padding: 8px 12px;
		border-radius: 4px;
		border: 1px solid #989ea4;
		background: #fff;
	}
	.activeName {
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		background: #b4eecd;
		padding: 8px 12px;
		border-radius: 4px;
		border: 1px solid #989ea4;
		min-width: 120px;
	}
	.yellowName {
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		background: yellow;
		padding: 8px 12px;
		border-radius: 4px;
		border: 1px solid #989ea4;
		min-width: 120px;
	}
	.right {
		width: 50%;
	}
	.team-members {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 2px;
		margin-top: 16px;
	}
	.row {
		display: flex;
		gap: 24px;
	}
	.box {
		border-radius: 4px;
		border: 1px solid #989ea4;
		background: #fff;
		padding: 8px;
		min-width: 290px;
		max-width: 391px;
		width: 100%;
	}
	.box-up {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
	}
	.position {
		color: #9ba1a7;
		font-family: 'Saira Semi Condensed', sans-serif;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		//white-space: nowrap;
	}

	.number {
		color: #9ba1a7;
		font-family: 'Saira Semi Condensed', sans-serif;
		text-align: right;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
	.arrow-style {
		width: 40px;
		height: 40px;
	}
`;
