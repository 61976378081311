import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { getSetting } from '../../../app/features/draftConfig/drafConfigAction';
import { selectDraftConfig, setResetRound } from '../../../app/features/draftConfig/draftConfigSlice';
import {getUserMe, userUpdate} from '../../../app/features/user/userActions';
import { selectUser } from '../../../app/features/user/userSlice';
import twitterBlue from '../../../assets/img/twitter-blue.png';
import userNullProfile from '../../../assets/img/userNullProfile.jpg';
import ImageCropper from '../../../components/ImageCropper';
import { Error } from '../../auth/Auth.styles';
import { AvatarWrap, InputWrap, ProfileTitle } from '../Profile.styles';

const schema = yup.object().shape({
	first_name: yup.string().max(40).min(3, 'First Name must be at least 3 characters'),
	last_name: yup.string().max(40).min(3, 'Last Name must be at least 3 characters'),
	twitter_link: yup
		.string()
		.required('Required Twitter handle')
		.matches(/^@?(\w){1,15}$/, 'Not valid twitter handle'),
	email: yup.string().email('Email should have correct format'),
	allow_simulator: yup.bool(),
});
const PersonalInfo = () => {
	const [isModalOpen, setModalOpen] = useState(false);
	const [selectedImage, setSelectedImage] = useState(userNullProfile);
	const [playersAvatarBlob, setPlayersAvatarBlob] = useState(null);
	const [playersAvatarFile, setPlayersAvatarFile] = useState(null);
	const { loading, userInfo } = useSelector(selectUser);
	const dispatch = useDispatch();
	const { allowSimulator: allowSimulatorAdmin } = useSelector(selectDraftConfig);

	const updateAvatar = (blob, file) => {
		setPlayersAvatarFile(file);
		setPlayersAvatarBlob(blob);
	};

	const handleOpenModal = () => {
		setModalOpen(true);
	};

	const resetImage = () => {
		setPlayersAvatarFile(null);
		setPlayersAvatarBlob(null);
	};

	const {
		register,
		handleSubmit,
		watch,
		reset,
		setValue,
		formState: { errors },
	} = useForm({
		mode: 'onBlur',

		//  onSubmit
		resolver: yupResolver(schema),
	});

	let allowSimulator = watch('allow_simulator');

	useEffect(() => {
		dispatch(getUserMe());
	}, []);

	useEffect(() => {
		dispatch(getSetting());
		return () => dispatch(setResetRound());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const onSubmit = data => {
		const formData = new FormData();
		for (let key in data) {
			formData.append(key, data[key]);
		}
		if (playersAvatarFile) formData.append('profile_picture', playersAvatarFile, `${playersAvatarFile.name}`);
		dispatch(userUpdate(formData));
		reset();
	};
	useEffect(() => {
		if (userInfo) {
			setValue('first_name', userInfo?.first_name);
			setValue('last_name', userInfo?.last_name);
			setValue('twitter_link', userInfo?.twitter_link);
			setValue('allow_simulator', userInfo?.allow_simulator);
			if (userInfo?.profile_picture?.original && userInfo?.podcaster) {
				setSelectedImage(userInfo?.profile_picture?.original);
			} else setSelectedImage(userNullProfile);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userInfo]);


	return (
		<>
			<ProfileTitle>Personal info</ProfileTitle>
			<form onSubmit={handleSubmit(onSubmit)}>
				{userInfo?.podcaster && (
					<InputWrap className="profile_picture">
						<AvatarWrap>
							<img src={playersAvatarBlob !== null ? playersAvatarBlob : selectedImage} alt="Selected" />
							{playersAvatarBlob !== null && (
								<span className="removeImage" onClick={resetImage}>
									x
								</span>
							)}
						</AvatarWrap>

						<button type="button" onClick={handleOpenModal}>
							{loading ? <CircularProgress size={17} color={'inherit'} /> : <>Upload Image</>}
						</button>

						<Error message={errors.first_name?.message}>
							{errors.name?.message ? errors.first_name?.message : <br />}
						</Error>
					</InputWrap>
				)}

				<InputWrap>
					<input {...register('first_name')} placeholder={`${userInfo?.first_name}`} />

					<Error message={errors.first_name?.message}>
						{errors.name?.message ? errors.first_name?.message : <br />}
					</Error>
				</InputWrap>
				<InputWrap>
					<input {...register('last_name')} placeholder={`${userInfo?.last_name}`} />

					<Error message={errors.last_name?.message}>
						{errors.surname?.message ? errors.surname?.message : <br />}
					</Error>
				</InputWrap>
				<InputWrap className="twitter-wrap">
					<img src={twitterBlue} alt="twitter" />
					<input {...register('twitter_link')} placeholder={`I am ${userInfo?.twitter_link}`} />
					<Error message={errors.twitter_link?.message}>
						{errors.twitter_link?.message ? errors.twitter_link?.message : <br />}
					</Error>
				</InputWrap>

				<InputWrap>
					<input placeholder={`${userInfo?.username}`} disabled />
				</InputWrap>
				{allowSimulatorAdmin && (
					<InputWrap>
						<label>
							Allow trading simulator to simulator
							<input type="checkbox" checked={allowSimulator} {...register('allow_simulator')} />
						</label>
					</InputWrap>
				)}

				<InputWrap>
					<button type="submit">
						{loading ? <CircularProgress size={17} color={'inherit'} /> : <>Save Changes</>}
					</button>
				</InputWrap>
			</form>
			<Dialog
				fullWidth={true}
				maxWidth={'md'}
				open={isModalOpen}
				onClose={() => setModalOpen(false)}
				aria-labelledby="edit-apartment"
				className="container"
			>
				<DialogTitle id="edit-apartment">Update Image</DialogTitle>
				<DialogContent sx={{ height: '50vh' }}>
					<ImageCropper updateAvatar={updateAvatar} closeModal={() => setModalOpen(false)} />
				</DialogContent>
			</Dialog>
		</>
	);
};

export default PersonalInfo;
