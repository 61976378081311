import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { selectUser } from '../../app/features/user/userSlice';
import { ProfileList, ProfileLists, ProfileNav, ProfileWrapper } from './Profile.styles';
import { links } from './dataProfileNav';

const ProfileLayout = () => {
	let { pathname } = useLocation();
	const { userInfo } = useSelector(selectUser);
	return (
		<ProfileWrapper>
			<ProfileNav>
				<ProfileLists>
					{links.map(item => {
						if (userInfo?.podcaster && item.text.includes('Podcasters')) return null;
						return (
							<ProfileList
								key={item.id}
								// className={pathname === item.url ? "active" : ""}
							>
								<NavLink
									to={item.url}
									// className={pathname === item.url ? "active" : ""}
								>
									<>{item.icon}</>
									<span>{item.text}</span>
								</NavLink>
							</ProfileList>
						);
					})}
				</ProfileLists>
			</ProfileNav>
			<article>
				<Outlet />
			</article>
		</ProfileWrapper>
	);
};

export default ProfileLayout;
