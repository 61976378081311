import React, { useCallback, useEffect } from 'react';
// Styles
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getColleges, getPositions, selectGroup, setResetGroup } from '../../app/features/group/groupSlice';
import { resPlayersDraft } from '../../app/features/playersDraft/playersDraftSlice';
import useModal from '../../hooks/useModal';
import {
	AddPlayerBox,
	AddPLayerForm,
	AddPLayerModal,
	AddPLayerModalWrapper,
	AddPlayerTitle,
	AddPLayerWrapper,
	ButtonsWrapper,
	InputWrap,
	RemoveButton,
	SelectWrap,
	SelectWrapContainer,
	StyledButton,
} from './AddPLayer.styles';
import { addPlayerSchema } from './AddPlayer.schema';

import { CircularProgress } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { FaTrash } from 'react-icons/fa';
import { myPlayersPost } from '../../app/features/myPlayers/myPlayersActions';
import { selectMyPlayers } from '../../app/features/myPlayers/myPlayersSlice';
import ModalCustom from '../ModalCustom/ModalCustom';

export const CustomSelect = React.memo(({ label, value, dataValue, onChange, customWidth = '100%' }) => {
	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: '100%',
				maxWidth: customWidth,
			},
		},
	};

	return (
		<FormControl sx={{ m: 1, minWidth: customWidth, margin: 0 }}>
			<InputLabel>{label}</InputLabel>
			<Select value={value} label={label} onChange={e => onChange(e.target.value)} MenuProps={MenuProps}>
				<MenuItem value="">
					<em>{label}</em>
				</MenuItem>
				{dataValue.map((item, index) => (
					<MenuItem key={index} value={item}>
						{item}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
});

const AddPLayer = () => {
	const { isOpen, openModal, closeModal } = useModal();
	const groups = useSelector(selectGroup);
	const { loading } = useSelector(selectMyPlayers);
	const dispatch = useDispatch();

	const {
		control,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({
		defaultValues: {
			players: [{ player: '', position: '', school: '' }],
		},
		resolver: yupResolver(addPlayerSchema),
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'players',
	});

	const handleOpenModal = useCallback(() => {
		openModal();
	}, [openModal]);

	const handleCloseModal = useCallback(() => {
		closeModal();
		reset();
	}, [closeModal, reset]);

	const onSubmit = useCallback(
		data => {
			const { players } = data;
			dispatch(myPlayersPost(players));
			handleCloseModal();
		},
		[handleCloseModal, dispatch]
	);

	useEffect(() => {
		if (isOpen) {
			dispatch(getPositions());
			dispatch(getColleges());
		}
		return () => {
			dispatch(setResetGroup());
			dispatch(resPlayersDraft());
		};
	}, [isOpen, dispatch]);

	const filteredPositions = groups.positions.slice(1);
	const filteredColleges = groups.colleges.slice(1);

	return (
		<AddPLayerWrapper>
			<StyledButton type="button" onClick={handleOpenModal}>
				Add Player
			</StyledButton>
			{isOpen && (
				<ModalCustom isOpen={isOpen}>
					<AddPLayerModalWrapper>
						<AddPLayerModal>
							<AddPlayerTitle>Add Player</AddPlayerTitle>
							{loading ? (
								<CircularProgress size={17} color={'inherit'} />
							) : (
								<AddPLayerForm>
									<form onSubmit={handleSubmit(onSubmit)}>
										{fields.map((field, index) => (
											<AddPlayerBox key={field.id}>
												{index > 0 && (
													<RemoveButton onClick={() => remove(index)}>
														<FaTrash />
													</RemoveButton>
												)}
												<InputWrap>
													{errors.players?.[index]?.player && <p>{errors.players[index].player.message}</p>}
													<Controller
														name={`players[${index}].player`}
														control={control}
														render={({ field }) => <input {...field} type="text" placeholder="Player name" />}
													/>
												</InputWrap>
												<SelectWrapContainer>
													<SelectWrap>
														{errors.players?.[index]?.position && <p>{errors.players[index].position.message}</p>}
														<Controller
															name={`players[${index}].position`}
															control={control}
															render={({ field }) => (
																<CustomSelect
																	label="Position"
																	value={field.value}
																	dataValue={filteredPositions}
																	customWidth="345px"
																	onChange={field.onChange}
																/>
															)}
														/>
													</SelectWrap>
													<SelectWrap>
														{errors.players?.[index]?.school && <p>{errors.players[index].school.message}</p>}
														<Controller
															name={`players[${index}].school`}
															control={control}
															render={({ field }) => (
																<CustomSelect
																	label="College"
																	value={field.value}
																	dataValue={filteredColleges}
																	customWidth="345px"
																	onChange={field.onChange}
																/>
															)}
														/>
													</SelectWrap>
												</SelectWrapContainer>
											</AddPlayerBox>
										))}
										<ButtonsWrapper>
											<StyledButton type="button" onClick={() => append({ player: '', position: '', school: '' })}>
												Add Player
											</StyledButton>
											<StyledButton type="submit" className="svBtn">
												Save
											</StyledButton>
										</ButtonsWrapper>
									</form>
								</AddPLayerForm>
							)}

							<button type="button" onClick={handleCloseModal} className="closeModal">
								X
							</button>
						</AddPLayerModal>
					</AddPLayerModalWrapper>
				</ModalCustom>
			)}
		</AddPLayerWrapper>
	);
};

export default AddPLayer;
