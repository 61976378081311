export const toggleArrObj = (arr, item, getValue = item => item) => {
	if (arr.some(i => getValue(i) === getValue(item))) return arr.filter(i => getValue(i) !== getValue(item));
	else return [...arr, item];
};

export const searchInfo = (arr, item, getValue = item => item) => {
	return arr.filter(i => getValue(i) === getValue(item));
};

export const percentPick = ({ teamValue, playerValue, percent = 20, round }) => {
	return +(((teamValue - playerValue) * percent * round) / 100).toFixed(2);
};

export const dataURLtoBlob = dataurl => {
	var arr = dataurl.split(','),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new Blob([u8arr], { type: mime });
};

let sumNumber = num => {
	let sum = 0;
	for (let i = 1; i <= num; i++) {
		sum += i;
	}
	return sum;
};

export const upUsersCals = (players, pricentValuePlayer, key) => {
	const point = sumNumber(players.length);
	// const point = sumNumber(players.length -1);
	const midPoint = pricentValuePlayer / point;

	return players.map((player, idx) => {
		const pricentValue = +(midPoint * (players.length - idx - 1)).toFixed(2);
		if (idx === players.length - 1) {
			return {
				...player,
				value: +(player[key] + pricentValuePlayer),
				pricentValue: pricentValuePlayer,
			};
		}
		return {
			...player,
			value: +(player[key] - pricentValue) <= 0 ? 0.00001 : -pricentValue,
			pricentValue: +(player[key] - pricentValue) <= 0 ? 0.00001 : -pricentValue,
		};
	});
};

// Round Calc Create New Data
export const roundTeam = (roundCount, teams) => {
	const newData = [];
	let indexCount = 1;
	for (let i = 1; i <= roundCount; ++i) {
		for (let j = 0; j < teams.length; ++j) {
			newData.push({
				id: Math.random(),
				index: indexCount,
				round_index: `${i} Round`,
				round: teams[j],
			});
			indexCount++;
		}
	}
	return newData;
};

export const getFilterTwoData = (arr, secondArr, key, deepKey = '', bitwiseOperator = 'XOR') => {
	if (bitwiseOperator === 'AND') {
		if (deepKey) {
			return arr.filter(item => secondArr.includes(item[key][deepKey]));
		} else return arr.filter(item => secondArr.includes(item[key]));
	} else if (bitwiseOperator === 'XOR') {
		if (deepKey) {
			return arr.filter(item => !secondArr.includes(item[key][deepKey]));
		} else return arr.filter(item => !secondArr.includes(item[key]));
	}
};

export const objectSet = (arr, key) => {
	const mySet = [];
	const setObject = [];
	arr.forEach(item => {
		if (!mySet.includes(item[key])) {
			mySet.push(item[key]);
			setObject.push(item);
		}
	});
	return setObject;
};

// Get Random Team
export const getRandom = (arr, n) => {
	var result = new Array(n),
		len = arr.length,
		taken = new Array(len);
	if (n > len) throw new RangeError('getRandom: more elements taken than available');
	while (n--) {
		var x = Math.floor(Math.random() * len);
		result[n] = arr[x in taken ? taken[x] : x];
		taken[x] = --len in taken ? taken[len] : len;
	}
	return result;
};

export const makeRepeated = (arr, repeats) =>
	Array.from({ length: repeats }, (v, idx) => {
		const arrIteration = arr.map(item => {
			return { ...item, iteration: idx + 1 };
		});
		return arrIteration;
	}).flat();

export const iterationRound = ({ fanaticChallenge, tradeValueData, round }) => {
	let newTradeValueIteration = [];
	let roundStart = [1];
	const iterationSection = [];
	let fanaticSlices = {
		1: [],
		2: [],
		3: [],
	};
	let fanaticSlicesRound = [
		{
			round: 1,
			start: null,
			end: null,
			challenge: false,
			iteration: 5,
			chose: true,
			roundRange: [],
		},
		{
			round: 2,
			start: null,
			end: null,
			challenge: false,
			iteration: 10,
			chose: false,
			roundRange: [],
		},
		{
			round: 3,
			start: null,
			end: null,
			challenge: false,
			iteration: 15,
			chose: false,
			roundRange: [],
		},
	];
	const iterationRound = makeRepeated(tradeValueData, fanaticChallenge[0].iteration);

	newTradeValueIteration.push(...iterationRound);
	for (let i = 1; i <= fanaticChallenge[0].iteration; i++) {
		iterationSection.push(tradeValueData.length * i);
	}

	for (let i = 0; i < tradeValueData.length; i++) {
		if (+tradeValueData[i].round_index_number === 1 && fanaticSlicesRound[0].start === null) {
			fanaticSlicesRound[0].start = i;
			roundStart.push(i + 1);
		}
		if (+tradeValueData[i].round_index_number === 2 && fanaticSlicesRound[1].start === null) {
			fanaticSlicesRound[0].end = i;
			fanaticSlicesRound[1].start = i;
			roundStart.push(i + 1);
		}
		if (+tradeValueData[i].round_index_number === 3 && fanaticSlicesRound[2].start === null) {
			fanaticSlicesRound[2].start = i;
			fanaticSlicesRound[1].end = i;
			roundStart.push(i + 1);
		}
		if (tradeValueData.length - 1 === i) {
			fanaticSlicesRound[round - 1].end = i;
		}
	}

	for (let i = 0; i < fanaticSlicesRound.length; i++) {
		for (let j = 1; j <= fanaticChallenge[0].iteration; j++) {
			if (fanaticSlicesRound[i].roundRange.length === 0) {
				fanaticSlicesRound[i].roundRange.push({
					start: fanaticSlicesRound[i].start + 1 * j,
					end: fanaticSlicesRound[i].end * j,
				});
			} else {
				fanaticSlicesRound[i].roundRange.push({
					start: fanaticSlicesRound[i].roundRange[j - 2].end,
					end: fanaticSlicesRound[i].end * j,
				});
			}
		}
	}

	const cutTradeValue = [...newTradeValueIteration];
	const newTradeValue = cutTradeValue.map((item, idx) => {
		const newItem = structuredClone(item);
		fanaticSlices[`${item.round_index_number}`].push(idx + 1);
		newItem['index_position'] = idx + 1;
		return newItem;
	});
	const sliceRound = fanaticSlicesRound;
	return {
		count: newTradeValue.length,
		newTradeValue,
		roundStart,
		iterationSection,
		fanaticSlicesRound: { sliceRound, iterationSection },
	};
};

export const iterationFanaticMode = ({ fanaticModeValue, tradeValueData }) => {
	const iterationRound = makeRepeated(tradeValueData, fanaticModeValue);
	console.log('🚀 ~ iterationFanaticMode ~ fanaticModeValue:', fanaticModeValue);
	const iterationSection = [];
	for (let i = 1; i <= fanaticModeValue; i++) {
		iterationSection.push(tradeValueData.length * i);
	}
	const newTradeValue = iterationRound.map((item, idx) => {
		const newItem = structuredClone(item);
		newItem['index_position'] = idx + 1;
		return newItem;
	});
	return { newTradeValue, iterationSection };
};

export const objectDeleteValue = ({ objectData, deleteKey }) => {
	const objectDelete = Object.keys(objectData)
		.filter(key => !deleteKey.includes(key))
		.reduce((obj, key) => {
			obj[key] = objectData[key];
			return obj;
		}, {});
	return objectDelete;
};

// sort array
export const sortArray = ({ arr, key }) => {
	return arr.sort((a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0));
};

export const filteredArray = ({ arr, arr2, key }) => {
	const filterArr = arr.filter(function (array_el) {
		return (
			arr2.filter(function (anotherOne_el) {
				return anotherOne_el[key] === array_el[key];
			}).length === 0
		);
	});
	return filterArr;
};

export const loadImage = src => {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.src = src;
		img.onload = () => {
			const canvas = document.createElement('canvas');

			// Set the width and height of the canvas to be the same as the image
			canvas.width = img.width;
			canvas.height = img.height;

			// Draw the image onto the canvas
			const ctx = canvas.getContext('2d');
			ctx.drawImage(img, 0, 0);

			// Get the PNG data URL from the canvas
			const pngDataUrl = canvas.toDataURL('image/png');

			// Use the PNG data URL as needed
			resolve(pngDataUrl);
		};
		img.onerror = e => {
			reject(e);
		};
		img.src = src;
	});
};

export const generateID = function (count = 8) {
	return (Date.now().toString(36) + Math.random().toString(36).substr(2)).toUpperCase().slice(count);
};

export const formatDate = value => {
	const date = new Date(value);
	const year = date.getUTCFullYear();
	const month = String(date.getUTCMonth() + 1).padStart(2, '0');
	const day = String(date.getUTCDate()).padStart(2, '0');
	const hours = String(date.getUTCHours()).padStart(2, '0');
	const minutes = String(date.getUTCMinutes()).padStart(2, '0');
	return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const getCurrentDate = () => {
	let myDate = new Date();

	// Create an array of month names
	let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

	// Get the components
	let newDayOfWeek = myDate.toLocaleString('en-US', { weekday: 'short' });
	let newMonth = monthNames[myDate.getMonth()];
	let newDay = myDate.getDate();
	let newYear = myDate.getFullYear();
	let newHours = ('0' + myDate.getHours()).slice(-2);
	let newMinutes = ('0' + myDate.getMinutes()).slice(-2);
	return newDayOfWeek + ' ' + newMonth + ' ' + newDay + ' ' + newYear + ' ' + newHours + ':' + newMinutes;
};

export const dateFormat = date => {
	const originalDateString = date;
	const originalDate = new Date(originalDateString);

	// Format the date as YYYY-MM-DD HH:mm:ss
	const formattedDate = originalDate.toISOString().replace(/T/, ' ').replace(/\..+/, '');

	return formattedDate;
};
export const dateFormatNew = date => {
	// Format the date as YYYY-MM-DD HH:mm:ss
	const formattedDate = date.toISOString().replace(/T/, ' ').replace(/\..+/, '');

	return formattedDate;
};
export const getCurrentNewDate = () => {
	const now = new Date();

	const year = now.getFullYear();
	const month = String(now.getMonth() + 1).padStart(2, '0');
	const day = String(now.getDate()).padStart(2, '0');
	const hours = String(now.getHours()).padStart(2, '0');
	const minutes = String(now.getMinutes()).padStart(2, '0');
	const seconds = String(now.getSeconds()).padStart(2, '0');

	const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
	return formattedDate;
};
export const timeDiffCalc = (dateNow, newDate) => {
	let date1 = new Date(dateNow);
	let date2 = new Date(newDate);

	let diffInMilliseconds = date2 - date1;
	let diffMinute = diffInMilliseconds / (1000 * 60);
	if (diffMinute >= 0 && diffMinute <= 10) {
		return { diffMinute, message: 'access' };
	}
	if (diffMinute < 10) {
		return {
			diffMinute: null,
			message: `You logged earlier, please come back at ${dateNow}`,
		};
	}
	if (diffMinute > 0) {
		return { diffMinute: null, message: `Your event time is over, event started ${dateNow}` };
	}
};

export const splitArrayIntoChunks = (array, chunkSize) => {
	const result = [];
	for (let i = 0; i < array.length; i += chunkSize) {
		result.push(array.slice(i, i + chunkSize));
	}
	return result;
};


export const generateRangeArray = (length, index, stepCount = 5) => {
	const rangeArray = [];
	const step = stepCount;

	for (let i = 0; i < length / 2; i += step) {
		const start = i + 1;
		const end = i + step;
		rangeArray.push(`${start}-${end}`);
	}
	return rangeArray[index];
};

export const generateNewRangeArray = (length, index, stepCount = 5) => {
	const rangeArray = [];
	const start = index;
	const end =length;
	rangeArray.push(`${start}-${end}`)
	return  rangeArray[0]
};

//before
// export const rankingDivideDataGraphics = data => {
// 	const maxFirstSubsetLength = 15;// Max length for the first subset
// 	const totalLength = data.length;
//
// 	const remainderLength = totalLength - maxFirstSubsetLength;
// 	const result = [];
// 	const firstSubset = data.slice(0, Math.min(maxFirstSubsetLength, totalLength));
// 	result.push(firstSubset);
//
// 	if (remainderLength > 0) {
// 		const remainder = data.slice(maxFirstSubsetLength);
// 		if (remainderLength >= 30 && remainderLength <= 45) {
// 			const chunkSize = Math.ceil(remainder.length / 3);
// 			for (let i = 0; i < 3; i++) {
// 				result.push(remainder.slice(i * chunkSize, (i + 1) * chunkSize));
// 			}
// 		} else if (remainderLength >= 15 && remainderLength < 30) {
// 			const chunkSize = Math.ceil(remainder.length / 2);
// 			for (let i = 0; i < 2; i++) {
// 				result.push(remainder.slice(i * chunkSize, (i + 1) * chunkSize));
// 			}
// 		}
// 	//added
// 		else if (remainderLength >= 1 && remainderLength < 15) {
// 			const chunkSize = Math.ceil(remainder.length);
// 			for (let i = 0; i < 1; i++) {
// 				result.push(remainder.slice(i * chunkSize, (i + 1) * chunkSize));
// 			}
// 		}
// 	}
// 	return result;
// };



export const rankingDivideDataGraphics = data => {
	const maxFirstSubsetLength = 15;// Max length for the first subset
	const totalLength = data.length;
	const remainderLength = totalLength - maxFirstSubsetLength;
	const result = [];
	const firstSubset = data.slice(0, Math.min(maxFirstSubsetLength, totalLength));
	result.push(firstSubset);
	if (remainderLength > 0) {
		const remainder = data.slice(maxFirstSubsetLength);
		if (remainderLength >= 30 && remainderLength <= 45) {
			const chunkSize = Math.ceil(remainder.length / 3);
			for (let i = 0; i < 3; i++) {
				result.push(remainder.slice(i * chunkSize, (i + 1) * chunkSize));
			}
		} else if (remainderLength >= 15 && remainderLength < 30) {
			const chunkSize = Math.ceil(remainder.length / 2);
			for (let i = 0; i < 2; i++) {
				result.push(remainder.slice(i * chunkSize, (i + 1) * chunkSize));
			}
		}
		else if (remainderLength >= 6 && remainderLength < 15) {
			const chunkSize = Math.ceil(remainder.length);
			for (let i = 0; i < 1; i++) {
				result.push(remainder.slice(i * chunkSize, (i + 1) * chunkSize));
			}
		}
		else if (remainderLength >= 1 && remainderLength <= 5) {
			remainder.unshift(...firstSubset.splice(firstSubset.length - 5));
			result.push(remainder)
		}
		//added before
		// else if (remainderLength >= 1 && remainderLength < 15) {
		// 	const chunkSize = Math.ceil(remainder.length);
		// 	console.log(chunkSize, "chunkSize")
		// 	for (let i = 0; i < 1; i++) {
		// 		result.push(remainder.slice(i * chunkSize, (i + 1) * chunkSize));
		// 	}
		// }
	}
	return result;
};

export function getRankingYear() {
	const currentDate = new Date();
	const currentYear = currentDate.getFullYear();
	const currentMonth = currentDate.getMonth();
	if (currentMonth >= 4 && currentMonth <= 11) {
		return currentYear + 1;
	} else {
		return currentYear;
	}
}

// export const  timeDiffCalc = (dateNow, newDate) => {
//   let date1 = new Date(dateNow);
//   let date2 = new Date(newDate);

//   let dif = date2 - date1;
//   dif = Math.round(dif / 1000 / 60);
//   return dif
// }
// timeDiffCalc("2023-12-11 12:15:00", "2023-12-11 12:30:00");

export const isPositionUnique = (teamUniqPositionFanaticChallenge, round, iteration, position, name) => {
	const usedPositions = new Set();
	const iterationKey = `iteration-${iteration}`;

	for (let r = 1; r <= round; r++) {
		const roundChallenges = teamUniqPositionFanaticChallenge[name]?.find(ch => ch.round === r);
		if (roundChallenges && roundChallenges[iterationKey]) {
			roundChallenges[iterationKey].forEach(pos => usedPositions.add(pos));
		}
	}

	return !usedPositions.has(position);
};

// Function to filter the general player list
// export const filterPlayers = (
// 	teamDraftPlayerChoose,
// 	infoDraft,
// 	teamId,
// 	round,
// 	iteration,
// 	pick,
// 	generalPlayerList,
// 	fanaticPlayerBefore = [],
// 	myTeam,
// 	teamSelectIndex // Accept teamSelectIndex as a parameter
// ) => {
// 	debugger

// 	let countPlayer = teamSelectIndex <= 5 ? 1 : 2

// 	// Find the team by teamId
// 	const team = teamDraftPlayerChoose.find(team => team.id === teamId)
// 	if (!team) {
// 		return generalPlayerList // Return the original list if team not found
// 	}

// 	// Create a count of each player ID in the choosePlayers array
// 	const chosenPlayerCount = team.choosePlayers.reduce((acc, player) => {
// 		acc[player.id] = (acc[player.id] || 0) + 1
// 		return acc
// 	}, {})

// 	// Identify players chosen in the specified round and iteration
// 	const roundInfo = infoDraft.find(r => r.round === round)
// 	const iterationInfo = roundInfo ? roundInfo.iterations.find(it => it.iteration === iteration) : null
// 	const chosenInIteration = new Set(iterationInfo ? iterationInfo.playersIds.map(player => player.playerId) : [])

// 	// Identify players chosen at least once by the team
// 	const chosenOnce = new Set(team.choosePlayers.map(player => player.id))

// 	// Create a set of player IDs from fanaticPlayerBefore
// 	const fanaticPlayerBeforeIds = new Set(fanaticPlayerBefore.map(fp => fp.player.id))

// 	// Filter the general player list
// 	return generalPlayerList.filter(player => {
// 		const count = chosenPlayerCount[player.id] || 0
// 		const selectedInIteration = chosenInIteration.has(player.id)
// 		const isFanaticBefore = fanaticPlayerBeforeIds.has(player.id)

// 		// Apply specific filtering when teamSelectIndex <= 5 and team.pick === 1
// 		if (teamSelectIndex <= 5 && pick === 1) {
// 			// In this case, remove only players selected in pick 1
// 			return !chosenOnce.has(player.id)
// 		}

// 		// Exception for pick 1: remove any player that is in the team's choosePlayers array
// 		if (pick === 1) {
// 			return !chosenOnce.has(player.id)
// 		}

// 		// Keep players chosen 2 or more times if myTeam is true
// 		if (myTeam && count >= 2) {
// 			return true
// 		}

// 		// Otherwise, keep players who are selected only once by the team,
// 		// not selected in the current iteration by any other team,
// 		// and not present in fanaticPlayerBefore
// 		return count < countPlayer && !selectedInIteration && !isFanaticBefore
// 	})
// }
// Function to filter the general player list
export const filterPlayers = (
	teamDraftPlayerChoose,
	infoDraft,
	teamId,
	round,
	iteration,
	pick,
	generalPlayerList,
	fanaticPlayerBefore = [],
	myTeam,
	teamSelectIndex, // Accept teamSelectIndex as a parameter
	fanaticChallenge
) => {
	// Determine the player count threshold based on teamSelectIndex
	let countPlayer = teamSelectIndex <= 5 ? 1 : 2;

	// Find the team by teamId
	const team = teamDraftPlayerChoose.find(team => team.id === teamId);
	if (!team) {
		return generalPlayerList; // Return the original list if team not found
	}

	// Create a count of each player ID in the choosePlayers array
	const chosenPlayerCount = team.choosePlayers.reduce((acc, player) => {
		acc[player.id] = (acc[player.id] || 0) + 1;
		return acc;
	}, {});

	// Identify players chosen in the specified round and iteration
	const roundInfo = infoDraft.find(r => r.round === round);
	const iterationInfo = roundInfo ? roundInfo.iterations.find(it => it.iteration === iteration) : null;
	const chosenInIteration = new Set(iterationInfo ? iterationInfo.playersIds.map(player => player.playerId) : []);

	// Identify players chosen at least once by the team
	const chosenOnce = new Set(team.choosePlayers.map(player => player.id));

	// Create a set of player IDs from fanaticPlayerBefore
	const fanaticPlayerBeforeIds = new Set(fanaticPlayerBefore.map(fp => fp.player.id));

	// Filter the general player list
	return generalPlayerList.filter(player => {
		const count = chosenPlayerCount[player.id] || 0;
		const selectedInIteration = chosenInIteration.has(player.id);
		const isFanaticBefore = fanaticPlayerBeforeIds.has(player.id);
		// debugger; me
		// Apply specific filtering when teamSelectIndex <= 5 and fanaticChallenge mode is 1
		if (teamSelectIndex <= 5 && fanaticChallenge.length !== 0 && fanaticChallenge[0].mode === 1) {
			// Remove any players that have been chosen by the team
			return !chosenOnce.has(player.id);
		}

		// If fanaticChallenge mode is '2', allow myTeam to select players chosen by the user in the previous round
		if (fanaticChallenge.length !== 0 && fanaticChallenge[0].mode === 2 && myTeam && round === 1) {
			return !isFanaticBefore;
		}

		// Exception for pick 1: remove any player that is in the team's choosePlayers array
		if (pick === 1) {
			return !chosenOnce.has(player.id);
		}

		// Keep players chosen 2 or more times if myTeam is true
		if (myTeam && count >= 2) {
			return true;
		}

		// When teamSelectIndex > 5, remove players chosen more than twice
		// Otherwise, keep players who are selected less than countPlayer by the team,
		// not selected in the current iteration by any other team,
		// and not present in fanaticPlayerBefore
		return count < countPlayer && !selectedInIteration && !isFanaticBefore;
	});
};
export function findTeamIndex(data, team) {
	const teamId = team[0].id; // Store the team id to avoid repeated access

	for (let i = 0; i < data.length; i++) {
		const currentTeam = data[i]; // Store the current data item to avoid repeated access

		if (currentTeam.id === teamId) {
			return i; // Return the index of the matching team
		}
	}

	return -1; // Return -1 if no match is found
}

export function capitalizeWords(str) {
	return str
		.split(' ')
		.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
		.join(' ');
}
