import { configureStore } from '@reduxjs/toolkit';
import draftConfigReducer from './features/draftConfig/draftConfigSlice';
import draftConfigurationDataReducer from './features/draftConfigurationDataSlice/draftConfigurationDataSlice';
import podcasterReducer from './features/podcasters/podcastersSlice';
import draftEventsReducer from './features/draftEvents/draftEventsSlice';
import draftResultReducer from './features/draftResult/draftResultSlice';
import playersDraftTeamReducer from './features/draftTeam/playersDraftTeamSlice';
import draftValueReducer from './features/draftValue/draftValueSlice';
import groupReducer from './features/group/groupSlice';
import liveDraftReducer from './features/liveDraft/liveDraftSlice';
import liveResultReducer from './features/liveResult/liveResultSlice';
import liveTradesReducer from './features/liveTrades/liveTradesSlice';
import myPlayersReducer from './features/myPlayers/myPlayersSlice';
import nflDraftFanaticsListBotsReducer from './features/nfldraftfanaticsSlice/nfldraftfanaticsSlice';
import planReducer from './features/plan/planSlice';
import playersReducer from './features/players/playersSlice';
import playersDraftReducer from './features/playersDraft/playersDraftSlice';
import rankingReducer from './features/ranking/rankingSlice';
import simulatorToSimulatorReducer from './features/simulatorToSimulator/simulatorToSimulatorSlice';
import teamListReducer from './features/teamList/teamListSlice';
import teamListBotsReducer from './features/teamListBots/teamListBotsSlice';
import teamNeedsReducer from './features/teamNeeds/teamNeedsSlice';
import tradesReducer from './features/trades/tradesSlice';
import userReducer from './features/user/userSlice';
import draftCountReducer from './features/draftCount/draftCountSlice'



export const store = configureStore({
	reducer: {
		user: userReducer,
		players: playersReducer,
		playersDraftTeam: playersDraftTeamReducer,
		draftConfigurationData: draftConfigurationDataReducer,
		group: groupReducer,
		draftConfig: draftConfigReducer,
		teamNeeds: teamNeedsReducer,
		playersDraft: playersDraftReducer,
		teamList: teamListReducer,
		teamListBots: teamListBotsReducer,
		draftResult: draftResultReducer,
		draftValue: draftValueReducer,
		trades: tradesReducer,
		simulatorToSimulator: simulatorToSimulatorReducer,
		draftEvents: draftEventsReducer,
		liveDraft: liveDraftReducer,
		plan: planReducer,
		liveResult: liveResultReducer,
		liveTrades: liveTradesReducer,
		myPlayers: myPlayersReducer,
		nflDraftFanaticsListBots: nflDraftFanaticsListBotsReducer,
		ranking: rankingReducer,
		podcasters:podcasterReducer,
		draftCount:draftCountReducer
	},
});
