import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_ENDPOINT } from '../../../config/config';
import axiosInstance from '../../../service/axiosInstance';

export const getDraftTotalCount = createAsyncThunk('total-count/getDraftTotalCount', async (_, { rejectWithValue }) => {
    try {
        const res = await axiosInstance.get(`${API_ENDPOINT}draft/total-count/`);
        const resData = { ...res.data };
        return resData;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue("Something went wrong!");
        }
    }
});



export const postDraftCountForTeam=createAsyncThunk(
    'draft-count-update/postDraftCountForTeam',
            async ({ teamId, draftedCount },{ getState, rejectWithValue }) => {
                // const {
                //     ranking: { rankId, rankedPlayers, positionRanked },
                // } = getState();
        try{
            const data={
                team_id:teamId,
                count:+draftedCount
            }
            const res = await axiosInstance.post(`${API_ENDPOINT}draft/update-count/`, data);
            const resData = { ...res.data };
            return resData;
        }catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue("Something went wrong!");
            }
        }
    });


