import React from 'react';
import { createRoot } from "react-dom/client";
import App from './App';
import { store } from './app/store'; 
import { Provider } from 'react-redux';
import {BrowserRouter} from "react-router-dom"
import { GlobalStyle } from './themes/GlobalStyle';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = createRoot(document.getElementById("root"));

root.render(
	// <React.StrictMode>
	<Provider store={store}>
		<BrowserRouter>
			<App />
			<GlobalStyle />
			<ToastContainer
				autoClose={1000}
				newestOnTop={true}
				closeOnClick={true}
				draggable={true}
				pauseOnHover={false}
				style={{ paddingTop: '80px' }}
			/>
		</BrowserRouter>
	</Provider>
	// </React.StrictMode>
);
