import styled from 'styled-components';

export const MyPlayersWrap = styled.div`
	width: 100%;
	max-width: calc(100% - 50px);
`;

export const FlexSpace = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
	flex-wrap: wrap;
`;

export const TableWrapper = styled.div`
	.main-container {
		overflow: hidden;
	}

	.main-container,
	.main-container * {
		box-sizing: border-box;
	}

	input,
	select,
	textarea,
	button {
		outline: 0;
	}

	.main-container {
		position: relative;
		width: 1000px;
		height: 81px;
		margin: 0 auto;
		background: #ffffff;
		overflow: hidden;
		border-radius: 6px;
	}
	.button {
		position: absolute;
		width: 70px;
		height: 81px;
		top: 0;
		left: 860px;
		cursor: pointer;
		background: rgba(231, 76, 60, 0.2);
		border: none;
		z-index: 6;
		overflow: hidden;
	}
	.frame-1 {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-wrap: nowrap;
		gap: 8px;
		position: relative;
		width: 42px;
		margin: 12px 0 0 14px;
		z-index: 7;
	}
	.delete-icon {
		flex-shrink: 0;
		position: relative;
		width: 24px;
		height: 24px;
		background: url(../assets/images/a864ee76-5003-4b64-9302-93a654b5fcc4.png) no-repeat center;
		background-size: cover;
		z-index: 8;
		overflow: hidden;
	}
	.delete-span {
		align-self: stretch;
		flex-shrink: 0;
		flex-basis: auto;
		position: relative;
		min-width: 0;
		height: 25px;
		color: #e74c3c;
		font-family: Saira SemiCondensed, var(--default-font-family);
		font-size: 16px;
		font-weight: 400;
		line-height: 25px;
		text-align: left;
		white-space: nowrap;
		z-index: 9;
	}
	.frame-2 {
		position: absolute;
		width: 70px;
		height: 81px;
		top: 0;
		left: 930px;
		background: rgba(2, 33, 66, 0.2);
		z-index: 10;
		overflow: hidden;
	}
	.frame-3 {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-wrap: nowrap;
		gap: 8px;
		position: relative;
		width: 24px;
		margin: 12px 0 0 23px;
		z-index: 11;
	}
	.bi-solid-edit {
		flex-shrink: 0;
		position: relative;
		width: 24px;
		height: 24px;
		background: url(../assets/images/af6130e4-6f58-4e47-8cf4-469a239d5579.png) no-repeat center;
		background-size: cover;
		z-index: 12;
		overflow: hidden;
	}
	.edi {
		align-self: stretch;
		flex-shrink: 0;
		flex-basis: auto;
		position: relative;
		min-width: 0;
		height: 25px;
		color: #022142;
		font-family: Saira SemiCondensed, var(--default-font-family);
		font-size: 16px;
		font-weight: 400;
		line-height: 25px;
		text-align: left;
		white-space: nowrap;
		z-index: 13;
	}
	.position {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		position: absolute;
		height: 25px;
		top: 2px;
		left: 517px;
		color: #46484a;
		font-family: Saira SemiCondensed, var(--default-font-family);
		font-size: 16px;
		font-weight: 400;
		line-height: 25px;
		text-align: left;
		white-space: nowrap;
		z-index: 1;
	}
	.college {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		position: absolute;
		height: 25px;
		top: 2px;
		left: 671px;
		color: #46484a;
		font-family: Saira SemiCondensed, var(--default-font-family);
		font-size: 16px;
		font-weight: 400;
		line-height: 25px;
		text-align: left;
		white-space: nowrap;
		z-index: 2;
	}
	.name {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		position: absolute;
		height: 25px;
		top: 2px;
		left: 24px;
		color: #46484a;
		font-family: Saira SemiCondensed, var(--default-font-family);
		font-size: 16px;
		font-weight: 400;
		line-height: 25px;
		text-align: left;
		white-space: nowrap;
	}
	.carson-beck {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		position: absolute;
		height: 44px;
		top: 35px;
		left: 24px;
		color: #022142;
		font-family: Saira SemiCondensed, var(--default-font-family);
		font-size: 28px;
		font-weight: 400;
		line-height: 44px;
		text-align: left;
		white-space: nowrap;
		z-index: 3;
	}
	.georgia {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		position: absolute;
		height: 44px;
		top: 35px;
		left: 671px;
		color: #022142;
		font-family: Saira SemiCondensed, var(--default-font-family);
		font-size: 28px;
		font-weight: 400;
		line-height: 44px;
		text-align: left;
		white-space: nowrap;
		z-index: 5;
	}
	.qb {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		position: absolute;
		height: 44px;
		top: 35px;
		left: 517px;
		color: #022142;
		font-family: Saira SemiCondensed, var(--default-font-family);
		font-size: 28px;
		font-weight: 400;
		line-height: 44px;
		text-align: left;
		white-space: nowrap;
		z-index: 4;
	}
`;