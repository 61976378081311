// export const TEAM_NEEDS = [
//   {
//     name: "Cardinals",
//     color: "#D50A0A",
//     loc: "ARI",
//   },
//   {
//     name: "Falcons",
//     color: "#B60404",
//     loc: "ATL",
//   },
//   {
//     name: "Bills",
//     color: "#446BB5",
//     loc: "BUF",
//   },
//   {
//     name: 'Ravens',
//     color: '#5586E1',
//     loc:'BAL'
//   },
//   {
//     name: 'Panthers',
//     color: '#015098',
//     loc:'CAR'
//   },
//   {
//     name: 'Bears',
//     color: '#2C2AA0',
//     loc:'CHI'
//   },
//   {
//     name: 'Bengals',
//     color: '#DE4300',
//     loc:'CIN'
//   },
//   {
//     name: 'Browns',
//     color: '#782907',
//     loc:'CLE'
//   },
//   {
//     name: 'Cowboys',
//     color: '#263A57',
//     loc:'DAL'
//   },
//   {
//     name: 'Broncos',
//     color: '#D5470A',
//     loc:'DEN'
//   },
//   {
//     name: 'Lions',
//     color: '#0456B6',
//     loc:'DET'
//   },
//   {
//     name: 'Packers',
//     color: '#1B371E',
//     loc:'GB'
//   },
//   {
//     name: 'Texans',
//     color: '#100A54',
//     loc:'HOU'
//   },
//   {
//     name: 'Colts',
//     color: '#015098',
//     loc:'IND'
//   },
//   {
//     name: 'Jaguars',
//     color: '#006779',
//     loc:'JAC'
//   },
//   {
//     name: 'Chiefs',
//     color: '#B60F28',
//     loc:'Chiefs'
//   },
//   {
//     name: 'Raiders',
//     color: '#484644',
//     loc:'LV'
//   },
//   {
//     name: 'Chargers',
//     color: '#0670AC',
//     loc:'LAC'
//   },
//   {
//     name: 'Rams',
//     color: '#0A8CD5',
//     loc:'LAR'
//   },
//   {
//     name: 'Dolphins',
//     color: '#168D96',
//     loc:'MIA'
//   },
//   {
//     name: 'Vikings',
//     color: '#5D2A9E',
//     loc:'MIN'
//   },
//   {
//     name: 'Patriots',
//     color: '#1F4367',
//     loc:'NE'
//   },
//   {
//     name: 'Saints',
//     color: '#CB962B',
//     loc:'NO'
//   },
//   {
//     name: 'Giants',
//     color: '#6361CE',
//     loc:'NYG'
//   },
//   {
//     name: 'Jets',
//     color: '#2D775F',
//     loc:'NYJ'
//   },
//   {
//     name: 'Eagles',
//     color: '#00000D',
//     loc:'PHI'
//   },
//   {
//     name: 'Steelers',
//     color: '#263A57',
//     loc:'PIT'
//   },
//   {
//     name: '49ers',
//     color: '#D50A0A',
//     loc:'SF'
//   },
//   {
//     name: 'Seahawks',
//     color: '#0A1F89',
//     loc:'SEA'
//   },
//   {
//     name: 'Buccaneers',
//     color: '#B93E2D',
//     loc:'TB'
//   },
//   {
//     name: 'Titans',
//     color: '#446BB5',
//     loc:'TEN'
//   },
//   {
//     name: 'Commanders',
//     color: '#631A1A',
//     loc:'WAS'
//   },
//   {
//     name: 'Bucaneers',
//     color: '#a31d2f',
//     loc:'BUC'
//   },
// ];
export const TEAM_NEEDS = [
	{
		name: 'Cardinals',
		color: '#D50A0A',
		loc: 'ARI',
		gradient: 'linear-gradient(127deg, #B0063A 0%, #FFC20E 100%);',
	},
	{
		name: 'Falcons',
		color: '#B60404',
		loc: 'ATL',
		gradient: 'linear-gradient(127deg, #C9243F 0%, #000 100%);',
	},
	{
		name: 'Bills',
		color: '#446BB5',
		loc: 'BUF',
		gradient: 'linear-gradient(127deg, #DA2128 0%, #005596 100%);',
	},
	{
		name: 'Ravens',
		color: '#5586E1',
		loc: 'BAL',
		gradient: 'linear-gradient(127deg, #D2AA41 0%, #2C2E8C 100%);',
	},
	{
		name: 'Panthers',
		color: '#015098',
		loc: 'CAR',
		gradient: 'linear-gradient(127deg, #0099D7 0%, #000 100%);',
	},
	{
		name: 'Bears',
		color: '#2C2AA0',
		loc: 'CHI',
		gradient: 'linear-gradient(127deg, #F26522 0%, #00123F 100%);',
	},
	{
		name: 'Bengals',
		color: '#DE4300',
		loc: 'CIN',
		gradient: 'linear-gradient(127deg, #F04E23 0%, #000 100%);',
	},
	{
		name: 'Browns',
		color: '#782907',
		loc: 'CLE',
		gradient: 'linear-gradient(127deg, #FF3C00 0%, #311D00 100%);',
	},
	{
		name: 'Cowboys',
		color: '#263A57',
		loc: 'DAL',
		gradient: 'linear-gradient(127deg, #00295B 0%, #FFF 100%);',
	},
	{
		name: 'Broncos',
		color: '#D5470A',
		loc: 'DEN',
		gradient: 'linear-gradient(127deg, #F05523 0%, #001F52 100%);',
	},
	{
		name: 'Lions',
		color: '#0456B6',
		loc: 'DET',
		gradient: 'linear-gradient(127deg, #0076B6 0%, #B0B7BB 100%);',
	},
	{
		name: 'Packers',
		color: '#1B371E',
		loc: 'GB',
		gradient: 'linear-gradient(127deg, #203731 0%, #FFB612 100%);',
	},
	{
		name: 'Texans',
		color: '#100A54',
		loc: 'HOU',
		gradient: 'linear-gradient(127deg, #00123F 0%, #C9243F 100%);',
	},
	{
		name: 'Colts',
		color: '#015098',
		loc: 'IND',
		gradient: 'linear-gradient(127deg, #00427E 0%, #FFF 100%);',
	},
	{
		name: 'Jaguars',
		color: '#006779',
		loc: 'JAC',
		gradient: 'linear-gradient(127deg, #006577 0%, #D6A22A 100%);',
	},
	{
		name: 'Chiefs',
		color: '#B60F28',
		loc: 'Chiefs',
		gradient: 'background: linear-gradient(127deg, #C9243F 0%, #FFF 100%);',
	},
	{
		name: 'Raiders',
		color: '#484644',
		loc: 'LV',
		gradient: 'linear-gradient(127deg, #A8A8AA 0%, #040707 100%);',
	},
	{
		name: 'Chargers',
		color: '#0670AC',
		loc: 'LAC',
		gradient: 'linear-gradient(127deg, #1281C4 0%, #FFC313 100%);',
	},
	{
		name: 'Rams',
		color: '#0A8CD5',
		loc: 'LAR',
		gradient: 'linear-gradient(127deg, #003593 0%, #FFD100 100%);',
	},
	{
		name: 'Dolphins',
		color: '#168D96',
		loc: 'MIA',
		gradient: 'linear-gradient(127deg, #008E97 0%, #FC4C02 100%);',
	},
	{
		name: 'Vikings',
		color: '#5D2A9E',
		loc: 'MIN',
		gradient: 'linear-gradient(127deg, #4E2683 0%, #FFC423 100%);',
	},
	{
		name: 'Patriots',
		color: '#1F4367',
		loc: 'NE',
		gradient: 'linear-gradient(127deg, #DA2128 0%, #00295B 100%);',
	},
	{
		name: 'Saints',
		color: '#CB962B',
		loc: 'NO',
		gradient: 'linear-gradient(127deg, #C8AA76 0%, #000 100%);',
	},
	{
		name: 'Giants',
		color: '#6361CE',
		loc: 'NYG',
		gradient: 'linear-gradient(127deg, #003D7E 0%, #7B7B7B 47.5%, #003D7E 100%);',
	},
	{
		name: 'Jets',
		color: '#2D775F',
		loc: 'NYJ',
		gradient: 'linear-gradient(127deg, #115740 0%, #FFF 100%);',
	},
	{
		name: 'Eagles',
		color: '#00000D',
		loc: 'PHI',
		gradient: 'linear-gradient(127deg, #004C55 0%, #000 100%);',
	},
	{
		name: 'Steelers',
		color: '#263A57',
		loc: 'PIT',
		gradient: 'linear-gradient(127deg, #DA2128 0%, #FFC20E 50%, #004F93 100%);',
	},
	{
		name: '49ers',
		color: '#D50A0A',
		loc: 'SF',
		gradient: 'linear-gradient(127deg, #B8083E 0%, #C8AA76 100%);',
	},
	{
		name: 'Seahawks',
		color: '#0A1F89',
		loc: 'SEA',
		gradient: 'linear-gradient(127deg, #002A5C 0%, #7AC142 100%);',
	},
	{
		name: 'Buccaneers',
		color: '#B93E2D',
		loc: 'TB',
		gradient: 'linear-gradient(127deg, #A31D2F 0%, #DC4726 50%, #FFF 100%);',
	},
	{
		name: 'Titans',
		color: '#446BB5',
		loc: 'TEN',
		gradient: 'linear-gradient(127deg, #DA2128 0%, #4295D1 63.84%, #00295B 100%);',
	},
	{
		name: 'Commanders',
		color: '#631A1A',
		loc: 'WAS',
		gradient: 'linear-gradient(127deg, #5A1313 0%, #FFB612 100%, #35689F 100.01%);',
	},
	{
		name: 'Buccaneers',
		color: '#a31d2f',
		loc: 'BUC',
		gradient: 'linear-gradient(127deg, #A31D2F 0%, #DC4726 50%, #FFF 100%);',
	},
];

export const POSITIONS_COLOR = {
	All: '',
	QB: '#E7E9EB',
	WR: '#F9F2D9',
	TE: '#E7D1DB',
	RB: '#EAF0E7',
	OT: '#F1F1E6',
	CB: '#D9BDE3',
	S: '#FAEBA7',
	DL: '#B4EECD',
	EDGE: '#EDE0EB',
	LB: '#DCE6EE',
	C: '#F2E2DE',
	G: '#ACE8DC',
	P: '#F8E8D9',
	K: '#FAEBA7',
};

export const RANKIGS = [
	{ color: '#92C1DB', value: 'round1', label: 'Round 1', privilege: 1 },
	{ color: '#CCECA6', value: 'round1-2', label: 'Round 1-2', privilege: 2 },
	{ color: '#A58CDE', value: 'round2', label: 'Round 2', privilege: 3 },
	{ color: '#8190B9', value: 'round2-3', label: 'Round 2-3', privilege: 4 },
	{ color: '#BC979F', value: 'round3', label: 'Round 3', privilege: 5 },
	{ color: '#E8A5D8', value: 'round4-5', label: 'Round 4-5', privilege: 6 },
	{ color: '#89CEA0', value: 'round6-7', label: 'Round 6-7', privilege: 7 },
	{ color: '#D89186', value: 'udfa', label: 'UDFA', privilege: 99 },
];

export const THIS_YEAR = new Date().getFullYear();

export const ASPECT_RATIO = 1;
export const MIN_DIMENSION = 80;
